import { findLastIndex, isRegExp, isString, repeat } from 'lodash';

import { defaultFormatChars } from './constants';
import { NO_SELECTION, TextSelection, TextState } from './types';

export default class MaskUtils {
    #maskOptions: MaskState;

    constructor(mask: string|(string|RegExp)[], maskPlaceholder?: string) {
        this.#maskOptions = parseMask(mask, maskPlaceholder);
    }

    isCharacterAllowedAtPosition(character: string, position:number) {
        const { maskPlaceholder } = this.#maskOptions;

        if (this.isCharacterFillingPosition(character, position)) {
            return true;
        }

        if (!maskPlaceholder) {
            return false;
        }

        return maskPlaceholder[position] === character;
    };

    isCharacterFillingPosition(character: string, position: number) {
        const { mask } = this.#maskOptions;

        if (!character || position >= mask.length) {
            return false;
        }

        if (!this.isPositionEditable(position)) {
            return mask[position] === character;
        }

        const charRule = mask[position];

        const result = new RegExp(charRule).test(character);

        return result;
    };

    isPositionEditable(position: number): boolean {
        const { mask, permanents } = this.#maskOptions;

        return position < mask.length && permanents.indexOf(position) === -1;
    };

    isValueEmpty(value: string): boolean {
        const result = value
            .split('')
            .every(
                (character: string, position: number) =>
                    !this.isPositionEditable(position) ||
                    !this.isCharacterFillingPosition(character, position),
            );

        return result;
    };

    isValueFilled(value: string) {
        const result = this.getFilledLength(value) === this.#maskOptions.lastEditablePosition + 1;

        return result;
    }

    getDefaultSelectionForValue(value: string):TextSelection {
        if (!value) {
            return NO_SELECTION;
        }

        const filledLength = this.getFilledLength(value);
        const cursorPosition = this.getRightEditablePosition(filledLength);

        if (cursorPosition === undefined) {
            return NO_SELECTION;
        }

        return {
            start: cursorPosition,
            end: cursorPosition,
        };
    };

    getFilledLength (value: string):number {
        const characters = value.split('');
        const lastFilledIndex = findLastIndex(
            characters,
            (character, position) =>
                this.isPositionEditable(position) &&
                this.isCharacterFillingPosition(character, position),
        );

        return lastFilledIndex + 1;
    };

    getStringFillingLengthAtPosition (string: string, position: number) {
        const characters = string.split('');
        const insertedValue = characters.reduce(
            (value, character) =>
                this.insertCharacterAtPosition(value, character, value.length),
            repeat(' ', position),
        );

        return insertedValue.length - position;
    };

    getLeftEditablePosition(position: number): number {
        for (let i = position; i >= 0; i--) {
            if (this.isPositionEditable(i)) {
                return i;
            }
        }

        throw new Error('Impossible');
    };

    getRightEditablePosition(position: number):number {
        const { mask } = this.#maskOptions;
        for (let i = position; i < mask.length; i++) {
            if (this.isPositionEditable(i)) {
                return i;
            }
        }

        throw new Error('Impossible');
    };

    formatValue(value: string): string {
        const { maskPlaceholder, mask } = this.#maskOptions;

        if (!maskPlaceholder) {
            value = this.insertStringAtPosition('', value, 0);

            while (value.length < mask.length && !this.isPositionEditable(value.length)) {
                value += mask[value.length];
            }

            return value;
        }

        const result = this.insertStringAtPosition(maskPlaceholder, value, 0);

        return result;
    };

    clearRange(value: string, start: number, len: number): string {
        if (!len) {
            return value;
        }

        const end = start + len;
        const { maskPlaceholder, mask } = this.#maskOptions;

        const clearedValue = value
            .split('')
            .map((character, i) => {
                const isEditable = this.isPositionEditable(i);

                if (!maskPlaceholder && i >= end && !isEditable) {
                    return '';
                }
                if (i < start || i >= end) {
                    return character;
                }
                if (!isEditable) {
                    return mask[i];
                }
                if (maskPlaceholder) {
                    return maskPlaceholder[i];
                }

                return '';
            })
            .join('');

        const result = this.formatValue(clearedValue);

        return result;
    };

    insertCharacterAtPosition(value:string, character:string, position:number):string {
        const { mask, maskPlaceholder } = this.#maskOptions;
        if (position >= mask.length) {
            return value;
        }

        const isAllowed = this.isCharacterAllowedAtPosition(character, position);
        const isEditable = this.isPositionEditable(position);

        const nextEditablePosition = this.getRightEditablePosition(position);
        const isNextPlaceholder =
            maskPlaceholder && nextEditablePosition
                ? character === maskPlaceholder[nextEditablePosition]
                : null;
        const valueBefore = value.slice(0, position);

        if (isAllowed || !isEditable) {
            const insertedCharacter = isAllowed ? character : mask[position];
            value = valueBefore + insertedCharacter;
        }

        if (!isAllowed && !isEditable && !isNextPlaceholder) {
            value = this.insertCharacterAtPosition(value, character, position + 1);
        }

        return value;
    }

    insertStringAtPosition(value:string, string:string, position:number):string {
        const { mask, maskPlaceholder } = this.#maskOptions;
        if (!string || position >= mask.length) {
            return value;
        }

        const characters = string.split('');
        const isFixedLength = this.isValueFilled(value) || !!maskPlaceholder;
        const valueAfter = value.slice(position);

        value = characters.reduce(
            (value, character) =>
                this.insertCharacterAtPosition(value, character, value.length),
            value.slice(0, position),
        );

        if (isFixedLength) {
            value += valueAfter.slice(value.length - position);
        } else if (this.isValueFilled(value)) {
            value += mask.slice(value.length).join('');
        } else {
            const editableCharactersAfter = valueAfter
                .split('')
                .filter((character, i) => this.isPositionEditable(position + i));
            value = editableCharactersAfter.reduce((value, character) => {
                const nextEditablePosition = this.getRightEditablePosition(
                    value.length,
                );
                if (nextEditablePosition === null) {
                    return value;
                }

                if (!this.isPositionEditable(value.length)) {
                    value += mask.slice(value.length, nextEditablePosition).join('');
                }

                return this.insertCharacterAtPosition(value, character, value.length);
            }, value);
        }

        return value;
    };

    processChange(currentState: TextState, previousState: TextState):TextState {
        const { mask, prefix, lastEditablePosition } = this.#maskOptions;
        const { value, selection } = currentState;
        const previousValue = previousState.value || '';
        const previousSelection = previousState.selection;
        let newValue = value || '';
        let enteredString = '';
        let formattedEnteredStringLength = 0;
        let removedLength = 0;

        if (!selection || !previousSelection) {
            return currentState;
        }

        let cursorPosition = Math.min(previousSelection.start, selection.start);

        if (selection.end > previousSelection.start) {
            enteredString = newValue.slice(previousSelection.start, selection.end);
            formattedEnteredStringLength = this.getStringFillingLengthAtPosition(
                enteredString,
                cursorPosition,
            );
            if (!formattedEnteredStringLength) {
                removedLength = 0;
            } else {
                removedLength = previousSelection.end - previousSelection.start;
            }
        } else if (newValue.length < previousValue.length) {
            removedLength = previousValue.length - newValue.length;
        }

        newValue = previousValue;

        if (removedLength) {
            if (removedLength === 1 && previousSelection.end! === previousSelection.start) {
                const deleteFromRight = previousSelection.start === selection.start;
                cursorPosition = deleteFromRight
                    ? this.getRightEditablePosition(selection.start)
                    : this.getLeftEditablePosition(selection.start);
            }
            newValue = this.clearRange(newValue, cursorPosition, removedLength);
        }

        newValue = this.insertStringAtPosition(
            newValue,
            enteredString,
            cursorPosition,
        );

        cursorPosition += formattedEnteredStringLength;
        if (cursorPosition >= mask.length) {
            cursorPosition = mask.length;
        } else if (
            cursorPosition < prefix.length &&
            !formattedEnteredStringLength
        ) {
            cursorPosition = prefix.length;
        } else if (
            cursorPosition >= prefix.length &&
            cursorPosition < lastEditablePosition &&
            formattedEnteredStringLength
        ) {
            cursorPosition = this.getRightEditablePosition(cursorPosition);
        }

        newValue = this.formatValue(newValue);

        const result: TextState = {
            value: newValue,
            //enteredString,
            selection: {
                start: cursorPosition,
                end: cursorPosition,
            },
        };

        return result;
    };
}

interface MaskState {
    maskPlaceholder?: string;
    mask: (string|RegExp)[];
    prefix: string;
    lastEditablePosition: number;
    permanents: number[];
}

function parseMask(mask:string|(string|RegExp)[], maskPlaceholder?:string): MaskState {
    const permanents:number[] = [];

    if (isString(mask)) {
        let isPermanent = false;
        let parsedMaskString = '';
        mask.split('').forEach((character) => {
            if (!isPermanent && character === '\\') {
                isPermanent = true;
            } else {
                if (isPermanent || !defaultFormatChars[character]) {
                    permanents.push(parsedMaskString.length);
                }
                parsedMaskString += character;
                isPermanent = false;
            }
        });

        mask = parsedMaskString.split('').map((character, index) => {
            if (permanents.indexOf(index) === -1) {
                return defaultFormatChars[character];
            }

            return character;
        });
    } else {
        mask.forEach((character, index) => {
            if (isString(character)) {
                permanents.push(index);
            }
        });
    }

    if (maskPlaceholder) {
        let r:string[];

        if (maskPlaceholder.length === 1) {
            r = mask.map((character, index) => {
                if (permanents.indexOf(index) !== -1) {
                    if (isRegExp(character)) {
                        return '*';
                    }

                    return character;
                }

                return maskPlaceholder!;
            });
        } else {
            r = maskPlaceholder.split('');
        }

        permanents.forEach((position) => {
            if (!isString(mask[position])) {
                return;
            }
            r[position] = mask[position] as string;
        });

        maskPlaceholder = r.join('');
    }

    const prefix = permanents
        .filter((position, index) => position === index)
        .map((position) => mask[position])
        .join('');

    let lastEditablePosition = mask.length - 1;
    while (permanents.indexOf(lastEditablePosition) !== -1) {
        lastEditablePosition--;
    }

    const result: MaskState = {
        maskPlaceholder,
        prefix,
        mask,
        lastEditablePosition,
        permanents,
    };

    return result;
}
