import React, {
    FocusEventHandler,
    MutableRefObject,
    RefCallback,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import ReactAce, { IAceEditorProps } from 'react-ace';
import { IAceEditor, IAnnotation, IMarker } from 'react-ace/lib/types';
import { Ace } from 'ace-builds';
import useResizeObserver from '@react-hook/resize-observer';
import { isFunction } from 'lodash';
import 'ace-builds/src-noconflict/theme-tomorrow';

import { ARG_BYPASS_DND_DISABLER_CLASSNAME } from '../arg-dnd/disable-dnd-container';
import { ArgInputExpressionCompleter } from './arg-input-expression-completer';
import { useSetTimeout } from '../arg-hooks/use-set-timeout';
import { ArgChangeReason } from '../types';
import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';

import './arg-input-expression-editor.less';

const DEFAULT_THEME = 'tomorrow';

const EMPTY_DOM_RECT: DOMRect = {
    x: 0,
    y: 0,
    height: 0,
    width: 0,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    toJSON: () => {
    },
};

export type ArgAceLanguage = object;

export interface ArgAceEditorInputError {
    offset?: number;
    line?: number;
    column?: number;
    message?: string;
}

export interface ArgAceEditorInputProps {
    className?: ClassValue;
    language?: string | ArgAceLanguage;
    aceProps?: IAceEditorProps;
    maxLines?: number;
    placeholder?: string;
    value: string | null;
    onBlur?: FocusEventHandler;
    onFocus?: FocusEventHandler;
    onChange?: (value: string | null, reason: ArgChangeReason) => void;
    onUnmount?: (value: string) => void;
    onContextMenu?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    focus?: boolean;
    inputRef?: MutableRefObject<IAceEditor | null>;
    cursorStart?: Ace.Point;
    errors?: ArgAceEditorInputError[];
    height?: number;
    changeDebounce?: number;
    completers?: ArgInputExpressionCompleter | ArgInputExpressionCompleter[];
}

export function ArgAceEditorInput(props: ArgAceEditorInputProps) {
    const {
        className,
        language,
        maxLines,
        aceProps,
        placeholder,
        focus,
        value,
        onChange,
        onBlur,
        onFocus,
        onUnmount,
        inputRef,
        cursorStart,
        errors,
        onContextMenu,
        completers,
    } = props;

    const classNames = useClassNames('arg-input-expression-editor');

    const aceReactRef = useRef<HTMLDivElement>(null);

    const timerCallback = useSetTimeout(200);

    const [size, setSize] = useState<DOMRect>(EMPTY_DOM_RECT);

    useResizeObserver(aceReactRef, (entry: ResizeObserverEntry) => {
        setSize(entry.target.getBoundingClientRect());
    });

    const handleContextMenu = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.preventDefault();
        event.stopPropagation();

        onContextMenu?.(event);
    }, [onContextMenu]);

    const handleOnBlur = useCallback<Exclude<IAceEditorProps['onBlur'], undefined>>((event, editor) => {
        onBlur?.({
            ...event,
            target: {
                ...event.target,
                value: editor?.getValue() || '',
            },
        });
    }, [onBlur]);

    const initializedEditorRef = useRef<IAceEditor | undefined>();

    const handleEditorRef = useCallback((editorRef: ReactAce | null) => {
        const focusRef = editorRef?.editor || null;

        if (isFunction(inputRef)) {
            (inputRef as RefCallback<IAceEditor | null>)(focusRef);
        } else if (inputRef) {
            (inputRef as MutableRefObject<IAceEditor | null>).current = focusRef;
        }

        if (!editorRef) {
            const value = initializedEditorRef.current?.getValue() || '';
            onUnmount?.(value);

            return;
        }

        initializedEditorRef.current = editorRef.editor;

        if (cursorStart) {
            editorRef.editor.moveCursorTo(cursorStart.row, cursorStart.column);
        }

        if (completers) {
            addCompleter(editorRef, Array.isArray(completers) ? completers : [completers]);
        }
    }, [completers, cursorStart, inputRef, onUnmount]);

    const markersAndAnnotationsFromErrors = useCallback((errors: ArgAceEditorInputError[] | undefined, value: string | null) => {
        const annotations: IAnnotation[] = [];
        const markers: IMarker[] = [];

        for (const error of errors ?? []) {
            const { message, column, line, offset } = error;
            annotations.push({
                type: 'error',
                text: message ?? '',
                column: column ?? 0,
                row: (line ?? 1) - 1,
            });
            if (line !== undefined) {
                markers.push({
                    startRow: line - 1,
                    startCol: 0,
                    endRow: line - 1,
                    endCol: -1,
                    className: 'arg-input-expression-editor-error-line',
                    type: 'fullLine',
                });
            }
            if (column !== undefined && line !== undefined && offset !== undefined) {
                const errorLength = value ? value.slice(offset).search(/\n|\s|$/) - 1 : 0;
                markers.push({
                    startRow: line - 1,
                    startCol: column - 1,
                    endRow: line - 1,
                    endCol: column + errorLength,
                    className: 'arg-input-expression-editor-error-text',
                    type: 'text',
                });
            }
        }

        return { markers, annotations };
    }, []);

    useEffect(() => {
        if (!focus) {
            return;
        }

        timerCallback(() => {
            inputRef?.current?.focus();
        });
    }, []);

    const handleOnChange = useCallback((value: string) => onChange?.(value, 'debounce'), [onChange]);

    const { markers, annotations } = markersAndAnnotationsFromErrors(errors, value);
    const editorCls = { '&-editor-focused': focus };

    return <div
        ref={aceReactRef}
        className={classNames('&', className)}
        data-testid='input-expression-editor'
        onContextMenu={handleContextMenu}
    >
        <ReactAce
            theme={DEFAULT_THEME}
            {...aceProps}
            maxLines={maxLines}
            mode={language}
            focus={focus}
            ref={handleEditorRef}
            value={value || ''}
            placeholder={placeholder}
            onBlur={handleOnBlur}
            onChange={handleOnChange}
            onFocus={onFocus}
            height={`${size.height}px`}
            className={classNames('&-editor', editorCls, ARG_BYPASS_DND_DISABLER_CLASSNAME)}
            width={`${size.width}px`}
            annotations={annotations}
            markers={markers}
            style={{ width: `${size.width}px !important`, height: `${size.height}px !important` }}
        />
    </div>;
}

function addCompleter(
    aceEditor: ReactAce,
    completers?: ArgInputExpressionCompleter[],
) {
    const editor = aceEditor.editor;

    if (!editor) {
        console.error('Can not install completer, editor is null !');

        return;
    }

    editor.completers = editor.completers?.filter(
        (c) => (!(c as any as ArgInputExpressionCompleter).completerId),
    ) ?? [];

    completers?.forEach((completer) => {
        const aceCompleter: Ace.Completer = {
            identifierRegexps: completer.identifierRegexps,
            getCompletions(editor: Ace.Editor, session: Ace.EditSession, position: Ace.Point, prefix: string, callback: Ace.CompleterCallback) {
                completer.getCompletions(
                    editor,
                    session,
                    position,
                    prefix,
                ).then((result) => {
                    callback(undefined, result);
                }, (error) => {
                    callback(error, []);
                });
            },
        };
        (aceCompleter as any).completerId = completer.completerId;

        editor.completers.push(aceCompleter);
    });
}
