import { defineMessages } from 'react-intl';
import React, { lazy, Suspense } from 'react';
import { RouteProps } from 'react-router';
import { Navigate, Route } from 'react-router-dom';

import { ArgonosModulesRegistry } from '../components/application/argonos-modules-registry';
import { Environment } from '../utils/environment';
import { EXPLORATION_PERMISSIONS_REQUIRED, ExplorationPermissions } from './model/permissions';
import { ArgonosModuleRouterProps } from '../components/containers/authenticated-apps-router';
import { LoadingSuspense } from '../components/containers/loading-suspense';
import { ArgonosModule } from '../components/application/modules';
import { getDataExplorationApi } from '../utils/connectors/api-url';
import { EXPLORATION_ROOT_URL } from '../components/containers/routes-urls';

const LazyExplorationRouter = lazy(() => import('./exploration-router'));

const FORCE_SUSPENSE_LOADING = false;


const messages = defineMessages({
    dataExplorationApplication: {
        id: 'common.applications.DataExploration',
        defaultMessage: 'Data Exploration',
    },
    loadingExploration: {
        id: 'common.applications.LoadingDataExploration',
        defaultMessage: 'Loading Data Exploration {threeDotsLoading}',
    },
    loginTitle: {
        id: 'exploration.login.Title',
        defaultMessage: 'Data Exploration Module',
    },
    loginMessage: {
        id: 'exploration.login.Explanation',
        defaultMessage: 'Log into the platform in order to get insight on your data and create value out of it, through relevant searches and appealing visualizations.',
    },
});


function ExplorationApp(props: ArgonosModuleRouterProps) {
    const { className } = props;

    if (FORCE_SUSPENSE_LOADING) {
        return <LoadingSuspense message={messages.loadingExploration} />;
    }

    return (
        <Suspense fallback={<LoadingSuspense message={messages.loadingExploration} />}>
            <LazyExplorationRouter
                className={className}
            />
        </Suspense>
    );
}

const EXPLORATION_MODULE: ArgonosModule<ExplorationPermissions> = {
    id: 'chapsVision.DataExploration',
    subType: 'DataExploration',

    enabled: Environment.isDataExplorationEnabled(),

    routeURL: EXPLORATION_ROOT_URL,
    loginOrder: 8,
    loginTitle: messages.loginTitle,
    loginMessage: messages.loginMessage,


    requiredPermissions: EXPLORATION_PERMISSIONS_REQUIRED,
    apiURL: getDataExplorationApi(),
    testURL: '/argonos-folders/pieces',

    iconURL: Environment.dataExplorationIcon,
    name: messages.dataExplorationApplication,
    color: '#5DA6F0',
    documentationURL: Environment.docServerUrlExploration,

    getRoutes(): React.ReactElement<RouteProps>[] {
        return [
            <Route
                key='exploration'
                path={EXPLORATION_ROOT_URL}
                element={<Navigate to='./folders' replace={true} />}
            />,
            <Route
                key='exploration/*'
                path={`${EXPLORATION_ROOT_URL}/*`}
                element={(
                    <ExplorationApp argonosModule={EXPLORATION_MODULE} />
                )}
            />,
        ];
    },

    hasRoles: true,
    hasUserRoles: true,
    hasGroupRoles: true,
    hasUserPermissions: true,
    scope: 'data_exploration',
    hasEnvironments: true,
};

ArgonosModulesRegistry.getInstance().register(EXPLORATION_MODULE);
