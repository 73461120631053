import { createContext, ReactNode, useContext, useMemo } from 'react';
import { MessageDescriptor } from 'react-intl';

import { ProgressMonitor, useMemoAsync } from '../basic';
import { Environment } from '../../utils/environment';
import { SettingsConnector } from '../../settings/connectors/settings-connector';
import { isResponse404 } from '../basic/utils/response-error';

export type ArgonosApplicationId = string;

export interface ArgonosApplicationBranding {
    applicationId: ArgonosApplicationId;

    brandingName?: string | MessageDescriptor;
    brandingLogoURL?: string;
    brandingIconURL?: string;
    documentTitle?: string;
}

const ArgonosApplicationBrandingContext = createContext<ArgonosApplicationBranding | undefined>(undefined);

const DISABLE_APPLICATION_DESCRIPTOR = localStorage.ARG_NO_BRANDING;

interface ArgonosApplicationBrandingProviderProps {
    children: ReactNode;
}

export function ArgonosApplicationBrandingProvider(props: ArgonosApplicationBrandingProviderProps) {
    const {
        children,
    } = props;

    const [argonosApplicationBranding] = useMemoAsync<ArgonosApplicationBranding>(async (progressMonitor: ProgressMonitor) => {
        try {
            const result = await getApplicationArgonosBranding(progressMonitor);

            if (result) {
                return result;
            }
        } catch (error) {
            console.error(error);
        }

        const result: ArgonosApplicationBranding = {
            applicationId: Environment.appId || 'default-argonos',
            brandingName: Environment.appName,
        };

        return result;
    }, []);

    return <ArgonosApplicationBrandingContext.Provider value={argonosApplicationBranding}>
        {children}
    </ArgonosApplicationBrandingContext.Provider>;
}

async function getApplicationArgonosBranding(
    progressMonitor: ProgressMonitor,
): Promise<ArgonosApplicationBranding | undefined> {
    if (DISABLE_APPLICATION_DESCRIPTOR) {
        return undefined;
    }

    const applicationId = Environment.appId;

    let descriptor;
    try {
        descriptor = await SettingsConnector.getInstance().getAppSettingsJSON(applicationId, progressMonitor);
    } catch (x) {
        if (progressMonitor.isCancelled) {
            throw x;
        }

        if (isResponse404(x)) {
            return undefined;
        }
        console.error('Can not get application branding', x);

        throw x;
    }

    const ret: ArgonosApplicationBranding = {
        ...descriptor,
        applicationId,
        brandingLogoURL: descriptor?.hasLogo && SettingsConnector.getInstance().computeAppSettingsURL(`${applicationId}-logo`),
        brandingIconURL: descriptor?.hasIcon && SettingsConnector.getInstance().computeAppSettingsURL(`${applicationId}-icon`),
    };

    return ret;
}

export function useApplicationBranding(): ArgonosApplicationBranding {
    const applicationBranding = useContext(ArgonosApplicationBrandingContext);

    const applicationId = Environment.appId;

    const mergedBranding = useMemo<ArgonosApplicationBranding>(() => {
        const result: ArgonosApplicationBranding = {
            applicationId,
            brandingName: applicationBranding?.brandingName || Environment.appName,
            brandingLogoURL: applicationBranding?.brandingLogoURL,
            brandingIconURL: applicationBranding?.brandingIconURL,
        };

        return result;
    }, [applicationBranding, applicationId]);

    return mergedBranding;
}
