import { ReactNode, useMemo, useState } from 'react';
import { defineMessages, MessageDescriptor } from 'react-intl';

import { ArgIcon, ArgImage, ArgMessageRenderer, ClassValue, useClassNames } from 'src/components/basic';
import { EmptyPane } from '../../../components/common/panes/empty-pane';
import { Extension } from '../../models/extension';

import './extension-information-panel.less';

const CLASSNAME = 'settings-extension-information-panel';
const messages = defineMessages({
    description: {
        id: 'settings.extension-information-panel.description',
        defaultMessage: 'Description',
    },
    version: {
        id: 'settings.extension-information-panel.version',
        defaultMessage: 'Version',
    },
    author: {
        id: 'settings.extension-information-panel.author',
        defaultMessage: 'Author',
    },
    noExtensionSelected: {
        id: 'settings.extension-information-panel.noExtensionSelected',
        defaultMessage: 'No extension selected',
    },
});

interface PropertyInformation {
    label: MessageDescriptor;
    content: ReactNode;
    key: string;
}

export interface ExtensionInformationPanelProps {
    selectedExtensions: Extension[];
    className?: ClassValue;
}

export function ExtensionInformationPanel(props: ExtensionInformationPanelProps) {
    const {
        selectedExtensions,
        className,
    } = props;
    const classNames = useClassNames(CLASSNAME);

    const [selectedExtensionIndex, setSelectedExtensionIndex] = useState(0);

    const extension: Extension | undefined = selectedExtensions[selectedExtensionIndex];

    const propertiesData = useMemo<PropertyInformation[]>(() => {
        if (!extension?.metadata) {
            return [];
        }

        return [
            {
                key: 'description',
                label: messages.description,
                content: extension.metadata.description,
            },
            {
                key: 'version',
                label: messages.version,
                content: extension.metadata.version,
            },
            {
                key: 'author',
                label: messages.author,
                content: extension.metadata.author,
            },
        ];
    }, [extension]);

    if (!extension) {
        return (
            <div className={classNames('&', className)}>
                <div className={classNames('&-no-extension-pane')}>
                    <EmptyPane
                        key='loading'
                        message={messages.noExtensionSelected}
                        backgroundAnimation='wave'
                    />
                </div>
            </div>
        );
    }

    return (
        <div className={classNames('&', className)}>
            <div className={classNames('&-header')}>
                <div className={classNames('&-header-title-container')}>
                    <ArgImage className={classNames('&-image')} src={extension.metadata.icon} />
                    <h3 className={classNames('&-header-title')}>
                        {extension.name}
                    </h3>
                </div>
                {selectedExtensions.length > 1 && (
                    <div className={classNames('&-toggle')}>
                        <div
                            onClick={() => {
                                setSelectedExtensionIndex((currentSelectedExtensionIndex) => {
                                    return Math.max(currentSelectedExtensionIndex - 1, 0);
                                });
                            }}
                            className={classNames(
                                selectedExtensionIndex === 0
                                    ? '&-toggle-disabled'
                                    : '&-toggle-enabled',
                            )}
                        >
                            <ArgIcon name='icon-previous' />
                        </div>
                        {selectedExtensionIndex + 1}
                        {'/'}
                        {selectedExtensions.length}
                        <div
                            onClick={() => {
                                setSelectedExtensionIndex((currentSelectedExtensionIndex) => {
                                    return Math.min(currentSelectedExtensionIndex + 1, selectedExtensions.length - 1);
                                });
                            }}
                            className={classNames(
                                selectedExtensionIndex === selectedExtensions.length - 1
                                    ? '&-toggle-disabled'
                                    : '&-toggle-enabled',
                            )}
                        >
                            <ArgIcon name='icon-next' />
                        </div>
                    </div>
                )}
            </div>
            <div className={classNames('&-scrollable-body')}>
                {propertiesData.map((property) => (
                    <div key={property.key} className={classNames('&-property-row')}>
                        <div className={classNames('&-property-row-title')}>
                            <ArgMessageRenderer message={property.label} />
                        </div>
                        <div className={classNames('&-property-row-content')}>
                            {property.content}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
