import React, { ReactNode, useContext } from 'react';
import { defineMessages } from 'react-intl';
import { chain, union } from 'lodash';

import { UserPermissions } from '../model/user';
import { ProgressMonitor, useArgNotifications, useClassNames, useMemoAsync } from 'src/components/basic';
import { ErrorPane } from 'src/components/common/panes/error-pane';
import { LoadingPane } from 'src/components/common/panes/loading-pane';
import { mapUserPermission, UsersConnector } from '../utils/connectors/users-connector';
import { ArgonosModulesRegistry } from 'src/components/application/argonos-modules-registry';
import { ArgonosModule } from 'src/components/application/modules';

import './user-permissions-provider.less';

const messages = defineMessages({
    loadingPermissionsError: {
        id: 'common.user-permissions.LoadingPermissionsError',
        defaultMessage: 'Failed to load permissions',
    },
    loadingPermissions: {
        id: 'common.user-permissions.LoadingPermissions',
        defaultMessage: 'Loading permissions {threeDotsLoading}',
    },
    loadingModulePermissions: {
        id: 'common.user-permissions.LoadingModulePermissions',
        defaultMessage: 'Failed to load module user permissions',
    },
});

export interface UseGetMePermissionsReturnType {
    permissions: UserPermissions;
}

export const UserPermissionsContext = React.createContext<UseGetMePermissionsReturnType>({ permissions: {} });

export function useGetMyPermissions(): UseGetMePermissionsReturnType {
    const ret = useContext(UserPermissionsContext)!;

    return ret;
}

export function usePermissions() {
    const notifications = useArgNotifications();

    const [userPermissions, userPermissionsProgressMonitor, errorUserPermissions] = useMemoAsync(async (progressMonitor) => {
        try {
            const modules = ArgonosModulesRegistry.getInstance().listEnabled()
                .filter(({ hasUserPermissions, scope }) => !!(hasUserPermissions && scope)).value();

            const [fulfilledResultPermissions, rejectedResultModuleNames] = await fetchUserPermissionsForAllModules(progressMonitor, modules);

            if (rejectedResultModuleNames.length > 0) {
                if (progressMonitor.isCancelled) {
                    return;
                }

                rejectedResultModuleNames.forEach((rejectedResultModuleName) => {
                    notifications.snackError({ message: rejectedResultModuleName, description: messages.loadingModulePermissions, duration: 2000 });
                });
            }

            const permissions = mapUserPermission(union(...fulfilledResultPermissions));

            return { permissions };
        } catch (error) {
            if (progressMonitor.isCancelled) {
                return;
            }

            notifications.snackError({ message: messages.loadingPermissionsError }, error as Error);

            return {
                permissions: {},
            };
        }
    }, [notifications], messages.loadingPermissions);

    return {
        userPermissions,
        userPermissionsProgressMonitor,
        errorUserPermissions,
    };
}

interface UserPermissionsProviderProps {
    children?: ReactNode;
}

export function UserPermissionsProvider(props: UserPermissionsProviderProps) {
    const { children } = props;
    const classNames = useClassNames('common-user-permission-provider');
    const {
        userPermissions,
        userPermissionsProgressMonitor,
        errorUserPermissions,
    } = usePermissions();

    if (errorUserPermissions) {
        return (
            <div className={classNames('&', 'error')}>
                <ErrorPane
                    error={errorUserPermissions}
                    className='fill'
                />
            </div>
        );
    }

    if (!userPermissions || userPermissionsProgressMonitor?.isRunning) {
        return (
            <div className={classNames('&', 'loading')}>
                <LoadingPane
                    progressMonitor={userPermissionsProgressMonitor}
                    className='fill'
                />
            </div>
        );
    }

    return (
        <UserPermissionsContext.Provider value={userPermissions}>
            {children}
        </UserPermissionsContext.Provider>
    );
}

type RejectedResultModuleNames = ArgonosModule['name'][];
type FulfilledResultPermissions = string[][];

// Fetch user permissions for all modules
async function fetchUserPermissionsForAllModules(progressMonitor: ProgressMonitor, modules: ArgonosModule[]): Promise<[FulfilledResultPermissions, RejectedResultModuleNames]> {
    const promises = chain(modules)
        .filter((module: ArgonosModule) => (module.enabled !== false))
        .map((module: ArgonosModule) => {
            const result = UsersConnector.getInstance().getMyUserPermissions(module, progressMonitor);

            return result;
        })
        .value();

    const results = await Promise.allSettled(promises);

    const fulfilledResultPermissions: FulfilledResultPermissions = [];
    const rejectedResultModuleNames: RejectedResultModuleNames = [];

    results.forEach((result, idx) => {
        if (result.status === 'fulfilled') {
            fulfilledResultPermissions.push(result.value);

            return;
        }

        rejectedResultModuleNames.push(modules[idx].name);
    });

    return [fulfilledResultPermissions, rejectedResultModuleNames];
}
