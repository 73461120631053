import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { defineMessages, FormattedDate, FormattedMessage, FormattedTime, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { Chart } from 'regraph';
import { isNumber } from 'lodash';

import {
    ArgButton,
    ArgToolbarLayout,
    GLOBAL_PM,
    ProgressMonitor,
    SubProgressMonitor,
    useArgModalContext,
    useArgNotifications,
    useCallbackAsync,
    useClassNames,
    useEffectAsync,
    useToolContext,
    useToolItem,
} from 'src/components/basic';
import { LoadingPane } from 'src/components/common/panes/loading-pane';
import { settings } from 'src/exploration/constants/panel-information-graph-settings';
import { useBlobImagesProvider } from 'src/hooks/use-blob-images-provider';
import { useImageAlignmentProvider } from 'src/utils/image-alignment-provider';
import { getGraphItemsForOntology } from '../../utils/regraph';
import { ObjectPropertiesPanel } from '../components/object-properties-panel/object-properties-panel';
import { DesignPropertiesPanel } from '../components/design-properties-panel/design-properties-panel';
import { EdgePropertiesPanel } from '../components/edge-properties-panel/edge-properties-panel';
import { CreateEdgeModal } from '../components/create-edge-modal/create-edge-modal';
import { PublishOntologyModal } from '../components/publish-ontology-modal/publish-ontology-modal';
import { ObjectMenu } from '../components/object-menu/object-menu';
import { RelationMenu } from '../components/edge-menu/edge-menu';
import { CreateObjectModal } from '../components/create-object-modal/create-object-modal';
import { UniverseLibraryPanel } from '../components/universe-items-panel/universe-library-panel';
import { FullOntology, FullOntologyLinkType, FullOntologyObjectType } from '../types';
import { exportOntology } from '../../utils/import-export';
import { EmptyPane } from 'src/components/common/panes/empty-pane';
import {
    RententionPolicyExpireAtProperty,
    RententionPolicyTimeToLive,
    RententionPolicyTimeToLiveBasedOnDelay,
    RententionPolicyTimeToLiveBasedOnProperty,
    RetentionPolicyActionEffect,
    RetentionPolicyActionEffectOnObject,
    RetentionPolicyActionEffectOnProperty,
    RetentionPolicyActionTargetKind,
    RetentionPolicyVertexEdge,
} from '../../retention/types';
import { computeFormCustomisationUrl } from 'src/settings/utils/compute-url';
import { ExplorationPermissions } from '../../../../exploration/model/permissions';
import { useHasPermission } from '../../../../contexts/user-permission';
import ontologiesConnector from '../../../connectors/ontologies-connector';
import {
    UniverseTableKebabMenu,
} from 'src/settings/universes/management/components/universe-table-kebab-menu/universe-table-kebab-menu';
import { useGraphPanning } from 'src/exploration/features/exploration/use-graph-panning';
import { ChartTool } from 'src/exploration/model/chart-tool';
import { useOntologiesState } from '../../management/providers/universes';

import './ontology-view.less';

const FORCE_LOADING = false;

const messages = defineMessages({
    fetchingUniverse: {
        id: 'settings.universe.fetch-universe.message',
        defaultMessage: 'Loading universe',
    },
    fetchingUniversesError: {
        id: 'settings.universe.fetch-universe.error',
        defaultMessage: 'An error occurred while fetching the universe',
    },
    ontology: {
        id: 'settings.universe.fetch-universe.ontology',
        defaultMessage: 'Ontology',
    },
    seeForm: {
        id: 'settings.universe.fetch-universe.seeForm',
        defaultMessage: 'See form',
    },
    publish: {
        id: 'settings.universe.publish',
        defaultMessage: 'Publish',
    },
    createAnObject: {
        id: 'settings.universe.createAnObject',
        defaultMessage: 'Create an object',
    },
    publishDateInfo: {
        id: 'settings.universe.publishDateInfo',
        defaultMessage: 'Published on {date} at {time}',
    },
    publishByInfo: {
        id: 'settings.universe.publishByInfo',
        defaultMessage: 'by {user}',
    },
    import: {
        id: 'settings.universe.import',
        defaultMessage: 'Import',
    },
    export: {
        id: 'settings.universe.export',
        defaultMessage: 'Export',
    },
    exportError: {
        id: 'settings.universe.exportError',
        defaultMessage: 'Failed to export the ontology',
    },
    clone: {
        id: 'settings.universe.clone',
        defaultMessage: 'Clone',
    },
    cloneError: {
        id: 'settings.universe.cloneError',
        defaultMessage: 'Failed to clone the ontology',
    },
    notDeletion: {
        id: 'settings.universe.notDeletion',
        defaultMessage: 'No rule',
    },
    deleteAfter: {
        id: 'settings.universe.deleteAfter',
        defaultMessage: 'Delete after',
    },
    deleteAt: {
        id: 'settings.universe.deleteOn',
        defaultMessage: 'Delete at',
    },
    dayLabel: {
        id: 'settings.universe.dayLabel',
        defaultMessage: 'day(s)',
    },
    weekLabel: {
        id: 'settings.universe.weekLabel',
        defaultMessage: 'week(s)',
    },
    monthLabel: {
        id: 'settings.universe.monthLabel',
        defaultMessage: 'month(s)',
    },
    yearLabel: {
        id: 'settings.universe.yearLabel',
        defaultMessage: 'year(s)',
    },
    noSelection: {
        id: 'settings.properties-panel.NoSelection',
        defaultMessage: 'No selection',
    },
    noOntology: {
        id: 'settings.properties-panel.NoOntology',
        defaultMessage: 'No ontology',
    },
});

interface ItemSelected {
    itemName: string;
    type: 'vertex' | 'edge';
}

export const LIBRARY_PANEL_MIN_WIDTH = 400;
const CREATE_EDGE_MODAL_NAME = 'CREATE_EDGE_MODAL_NAME';
const CREATE_OBJECT_MODAL_NAME = 'CREATE_OBJECT_MODAL_NAME';
const PUBLISH_ONTOLOGY_MODAL_NAME = 'PUBLISH_ONTOLOGY_MODAL_NAME';
const ONTOLOGY_LAYOUT: Chart.LayoutOptions = {
    name: 'organic',
    tightness: 0,
};

const UNIT_LABEL = {
    Hour: messages.dayLabel,
    Day: messages.dayLabel,
    Week: messages.weekLabel,
    Month: messages.monthLabel,
    Year: messages.yearLabel,
};

const INITIAL_POLICIES: RetentionPolicyVertexEdge = { effects: [] };

function getVertexByName(name: string, ontology: FullOntology) {
    return ontology?.objectTypes.filter((vertex) => vertex.name === name)[0];
}

export function OntologyView() {
    const intl = useIntl();
    const navigate = useNavigate();
    const classNames = useClassNames('ontology-view');
    const { ontologyId } = useParams<{ ontologyId: string }>();

    const [itemsSelected, setItemsSelected] = useState<ItemSelected[]>([]);
    const [ontology, setOntology] = useState<FullOntology>();
    const [vertexSelected, setVertexSelected] = useState<FullOntologyObjectType>();
    const [edgeSelected, setEdgeSelected] = useState<FullOntologyLinkType>();
    const [objectMenuVertex, setObjectMenuVertex] = useState<FullOntologyObjectType>();
    const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
    const [relationMenuEdge, setRelationMenuEdge] = useState<FullOntologyLinkType>();
    const [objectHoveredOver, setObjectHoveredOver] = useState('');
    const [retention, setRetention] = useState<RetentionPolicyVertexEdge>(INITIAL_POLICIES);
    const chartPositionsRef = useRef<Chart.Positions>();

    const notifications = useArgNotifications();
    const { updateOntologies } = useOntologiesState();
    const modalContext = useArgModalContext();
    const toolbarContext = useToolContext('settings.ontology.toolbar');
    const blobImagesProvider = useBlobImagesProvider();
    const imageAlignmentProvider = useImageAlignmentProvider();
    const [forcePan, handleMouseDown, handleMouseUp, handleKeyDown, handleKeyUp] = useGraphPanning(ChartTool.Hand);
    const canImportExport = useHasPermission<ExplorationPermissions>('exploration.import.export.settings');

    const createEdgeModalHandler = useMemo(() => {
        const close = () => modalContext.close(CREATE_EDGE_MODAL_NAME);
        const open = () => {
            if (!vertexSelected) {
                return;
            }
            modalContext.open(
                CREATE_EDGE_MODAL_NAME,
                <CreateEdgeModal
                    closeModal={close}
                    ontology={ontology}
                    vertex={vertexSelected}
                    setOntology={setOntology}
                />,
            );
        };

        return {
            open,
            close,
        };
    }, [modalContext, ontology, vertexSelected]);
    const createObjectModalHandler = useMemo(() => {
        const close = () => modalContext.close(CREATE_OBJECT_MODAL_NAME);
        const open = () => {
            if (!ontology) {
                return;
            }
            modalContext.open(
                CREATE_OBJECT_MODAL_NAME,
                <CreateObjectModal
                    closeModal={() => modalContext.close(CREATE_OBJECT_MODAL_NAME)}
                    ontology={ontology}
                    setOntology={setOntology}
                />,
            );
        };

        return {
            open,
            close,
        };
    }, [modalContext, ontology]);
    const publishOntologyModalHandler = useMemo(() => {
        const close = () => modalContext.close(PUBLISH_ONTOLOGY_MODAL_NAME);
        const open = () => {
            if (!ontology) {
                return;
            }

            modalContext.open(
                PUBLISH_ONTOLOGY_MODAL_NAME,
                <PublishOntologyModal
                    closeModal={() => modalContext.close(PUBLISH_ONTOLOGY_MODAL_NAME)}
                    ontology={ontology}
                />,
            );
        };

        return {
            open,
            close,
        };
    }, [modalContext, ontology]);

    const [fetchUniverseMonitor] = useEffectAsync(async (progressMonitor: ProgressMonitor) => {
        if (!ontologyId) {
            return;
        }

        try {
            const ontology = await ontologiesConnector.getFullOntology(ontologyId);
            setOntology(ontology);
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            notifications.snackError({ message: messages.fetchingUniversesError }, error as Error);
            throw error;
        }
    }, [ontologyId], messages.fetchingUniverse, 1, GLOBAL_PM);

    useEffect(() => {
        if (vertexSelected) {
            setVertexSelected(
                ontology?.objectTypes.find(({ name }) => name === vertexSelected.name),
            );
        }
        if (edgeSelected) {
            setEdgeSelected(ontology?.linkTypes.find(({ name }) => name === edgeSelected.name));
        }
    }, [ontology]);

    const graphItems = useMemo(() => {
        return getGraphItemsForOntology(
            ontology,
            itemsSelected.map((item) => item.itemName),
            blobImagesProvider,
            objectHoveredOver,
            imageAlignmentProvider,
        );
    }, [ontology, itemsSelected, imageAlignmentProvider, objectHoveredOver]);

    const onChartClick: Chart.onClickHandler = useCallback((event) => {
        const {
            id,
            subItem,
        } = event;

        if (!id || !isNumber(subItem?.index)) {
            return;
        }

        const itemGlyphs = graphItems[id].glyphs;
        const subItemClicked = itemGlyphs?.[subItem!.index];
        if (!subItemClicked || subItemClicked.label?.text !== '+') {
            return;
        }

        createEdgeModalHandler.open();
    }, [createEdgeModalHandler, graphItems]);

    const onChartHover: Chart.onHoverHandler = useCallback((event: Chart.PointerEvent) => {
        const { id } = event;
        const vertex = ontology?.objectTypes.find((vertex) => vertex.name === id);
        if (vertex) {
            setObjectHoveredOver(id || '');

            return;
        }

        setObjectHoveredOver('');
    }, [ontology?.objectTypes]);

    const onChartRightClick: Chart.onContextMenuHandler = useCallback((event: Chart.PreventablePointerEvent) => {
        const { id, x, y } = event;
        const vertex = ontology?.objectTypes.find((vertex) => vertex.name === id);
        const edge = ontology?.linkTypes.find((edge) => edge.name === id);

        if (vertex) {
            setObjectMenuVertex(vertex);
            setMenuPosition({ x, y });

            return true;
        }

        if (edge) {
            setRelationMenuEdge(edge);
            setMenuPosition({ x, y });

            return true;
        }

        return true;
    }, [ontology?.linkTypes, ontology?.objectTypes]);

    const onChartChange = useCallback((event: Chart.ChangeEvent) => {
        const selection = event.selection;
        if (selection) {
            const itemsSelected: ItemSelected[] = Object.entries(selection).map(([key, value]) => {
                if (value.id1) {
                    return { itemName: key, type: 'edge' };
                } else {
                    return { itemName: key, type: 'vertex' };
                }
            });
            setItemsSelected(itemsSelected);
            setVertexSelected(
                ontology?.objectTypes.find((vertex) =>
                    itemsSelected
                        .filter(({ type }) => type === 'vertex')
                        .some((selection) => selection.itemName === vertex.name),
                ),
            );
            setObjectMenuVertex(undefined);
            setRelationMenuEdge(undefined);
            setEdgeSelected(
                ontology?.linkTypes.find((edge) =>
                    itemsSelected
                        .filter(({ type }) => type === 'edge')
                        .some((selection) => selection.itemName === edge.name),
                ),
            );
        }
        if (event.positions && event.why === 'user' /* User has manually positioned nodes */) {
            chartPositionsRef.current = event.positions;
        }
    }, [ontology?.linkTypes, ontology?.objectTypes]);

    const [onExportClick, exportOntologyPM] = useCallbackAsync(async (progressMonitor: ProgressMonitor) => {
        try {
            if (ontology) {
                await exportOntology(ontology, progressMonitor);
            }
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            notifications.snackError({ message: messages.exportError }, error as Error);
            throw error;
        }
    }, [notifications, ontology]);

    const [onCloneClick, cloneOntologyPM] = useCallbackAsync(async (progressMonitor: ProgressMonitor) => {
        try {
            if (!ontologyId) {
                return;
            }
            const sub1 = new SubProgressMonitor(progressMonitor, 1);
            await ontologiesConnector.cloneOntology(ontologyId, sub1);

            const sub2 = new SubProgressMonitor(progressMonitor, 1);
            await updateOntologies(sub2);
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            notifications.snackError({ message: messages.cloneError }, error as Error);
            throw error;
        }
    }, [notifications, ontologyId, updateOntologies]);


    const renderOntologyPanel = useCallback(() => {
        return <UniverseLibraryPanel ontology={ontology} setOntology={setOntology} />;
    }, [ontology]);

    useToolItem(
        toolbarContext,
        {
            path: 'left/ontology',
            type: 'panel',
            icon: 'icon-histogram',
            tooltip: messages.ontology,
        },
        {
            panelRender: renderOntologyPanel,
            loading: fetchUniverseMonitor?.isRunning || !ontology,
        },
    );

    const navigateToForm = useCallback(() => {
        if (!ontologyId || !vertexSelected) {
            return;
        }
        const formCustomisationUrl = computeFormCustomisationUrl(ontologyId, vertexSelected.name);
        navigate(formCustomisationUrl);
    }, [vertexSelected, navigate, ontologyId]);


    useToolItem(
        toolbarContext,
        {
            path: 'right/form-customisation',
            type: 'button',
            icon: 'icon-file',
            label: messages.seeForm,
            className: classNames('&-form-customisation-button'),
        },
        {
            disabled: !vertexSelected,
            onClick: navigateToForm,
        },
    );

    const renderDesignPanel = useCallback(() => {
        return (
            <DesignPropertiesPanel
                ontology={ontology}
                setOntology={setOntology}
                vertexSelected={vertexSelected}
                edgeSelected={edgeSelected}
                setVertexSelected={setVertexSelected}
                setEdgeSelected={setEdgeSelected}
            />
        );
    }, [edgeSelected, ontology, vertexSelected]);

    useToolItem(
        toolbarContext,
        {
            path: 'right/style-customisation',
            type: 'panel',
            icon: 'icon-brush',
        },
        {
            panelRender: renderDesignPanel,
            loading: fetchUniverseMonitor?.isRunning || !ontology,
        },
    );

    const retentionSetUp = useCallback((retention: RetentionPolicyVertexEdge, retentionTarget: RetentionPolicyActionTargetKind | 'Property' = 'Vertex', targetProperty?: string) => {
        let delay = undefined;
        let delayProperty = undefined;
        let unit = undefined;
        let expireAtProperty = undefined;

        if (retentionTarget === 'Property' && targetProperty) {
            const propertiesRetention = retention.effects.filter(item => item?.hasOwnProperty('property')) as Array<RetentionPolicyActionEffectOnProperty>;
            const propertyRetention = propertiesRetention.find(item => item?.property?.targetProperty === targetProperty) as RetentionPolicyActionEffectOnProperty;
            if (propertyRetention) {
                expireAtProperty = (propertyRetention?.property?.retention as RententionPolicyExpireAtProperty)?.expireAtProperty;
                delay = ((propertyRetention?.property?.retention as RententionPolicyTimeToLive)?.timeToLive as RententionPolicyTimeToLiveBasedOnDelay)?.delay;
                unit = (propertyRetention?.property?.retention as RententionPolicyTimeToLive)?.timeToLive?.unit;
                delayProperty = ((propertyRetention?.property?.retention as RententionPolicyTimeToLive)?.timeToLive as RententionPolicyTimeToLiveBasedOnProperty)?.delayProperty;
            }
        } else {
            const objectRetention = retention.effects.find((item: RetentionPolicyActionEffect) => item?.hasOwnProperty('object')) as RetentionPolicyActionEffectOnObject;
            if (objectRetention) {
                expireAtProperty = (objectRetention.object?.retention as RententionPolicyExpireAtProperty)?.expireAtProperty;
                delay = ((objectRetention.object?.retention as RententionPolicyTimeToLive)?.timeToLive as RententionPolicyTimeToLiveBasedOnDelay)?.delay;
                unit = (objectRetention?.object?.retention as RententionPolicyTimeToLive)?.timeToLive?.unit;
                delayProperty = ((objectRetention?.object?.retention as RententionPolicyTimeToLive)?.timeToLive as RententionPolicyTimeToLiveBasedOnProperty)?.delayProperty;
            }
        }

        if (delay && unit) {
            return `${intl.formatMessage(messages.deleteAfter)}: "${delay}" ${intl.formatMessage(UNIT_LABEL[unit])}`;
        }
        if (delayProperty && unit) {
            return `${intl.formatMessage(messages.deleteAfter)}: "${delayProperty}" ${intl.formatMessage(UNIT_LABEL[unit])}`;
        }
        if (expireAtProperty) {
            return `${intl.formatMessage(messages.deleteAt)}: "${expireAtProperty}"`;
        }

        return `${intl.formatMessage(messages.notDeletion)}`;
    }, [intl]);

    const handleDragStart = useCallback((event: Chart.DragStartEvent<Element>) => {
        if (forcePan) {
            event.setDragOptions({ type: 'pan' });
        }
    }, [forcePan]);

    const propertiesPanelRender = useCallback(() => {
        if (!ontology) {
            return (
                <div className={classNames('&', 'empty')}>
                    <EmptyPane
                        message={messages.noSelection}
                        icon='icon-info'
                        size='medium'
                    />
                </div>
            );
        }
        if (vertexSelected) {
            return (
                <ObjectPropertiesPanel
                    className={classNames('&-panel-object')}
                    vertex={vertexSelected}
                    ontology={ontology}
                    setOntology={setOntology}
                    retentionSetUp={retentionSetUp}
                    retention={retention}
                    setRetention={setRetention}
                />
            );
        }
        if (edgeSelected) {
            return (
                <EdgePropertiesPanel
                    className={classNames('&-panel-edge')}
                    edge={edgeSelected}
                    fromVertex={getVertexByName(edgeSelected.sourceObjectName, ontology)}
                    toVertex={getVertexByName(edgeSelected.destinationObjectName, ontology)}
                    ontology={ontology}
                    setOntology={setOntology}
                    retentionSetUp={retentionSetUp}
                    retention={retention}
                    setRetention={setRetention}
                />
            );
        }

        return (
            <div className={classNames('&', 'empty')}>
                <EmptyPane
                    message={messages.noSelection}
                    icon='icon-info'
                    size='medium'
                />
            </div>
        );
    }, [classNames, edgeSelected, ontology, retention, retentionSetUp, vertexSelected]);

    useToolItem(
        toolbarContext,
        {
            path: 'right/properties-panel',
            type: 'panel',
            icon: 'icon-info',
        },
        {
            panelRender: propertiesPanelRender,
            loading: fetchUniverseMonitor?.isRunning || !ontology,
        },
    );
    useToolItem(
        toolbarContext,
        {
            path: 'left/create-object',
            type: 'button',
            icon: 'icon-add-outline',
            label: messages.createAnObject,
        },
        {
            loading: fetchUniverseMonitor?.isRunning || !ontology,
            onClick: createObjectModalHandler.open,
        },
    );

    if (fetchUniverseMonitor?.isRunning || !ontology || FORCE_LOADING) {
        return (
            <div className={classNames('&', 'loading')}>
                <LoadingPane progressMonitor={fetchUniverseMonitor} />
            </div>
        );
    }

    return (
        <div className={classNames('&')}>
            <div className={classNames('&-header')}>
                <div className={classNames('&-universe-title')}>
                    <h1>{ontology?.name}</h1>
                    <UniverseTableKebabMenu
                        ontologyId={ontology.id}
                        ontologyName={ontology.name}
                        universeId={ontology.universeIds[0]}
                    />
                </div>
                <div className={classNames('&-publish-container')}>
                    <ArgButton
                        icon='icon-plus-box-multiple-outline'
                        className={classNames('&-clone-button')}
                        label={messages.clone}
                        type='ghost'
                        onClick={onCloneClick}
                        disabled={!ontology}
                        loading={cloneOntologyPM?.isRunning}
                    />
                    {canImportExport && <ArgButton
                        icon='icon-upload'
                        className={classNames('&-export-button')}
                        label={messages.export}
                        type='ghost'
                        onClick={onExportClick}
                        disabled={!ontology}
                        loading={exportOntologyPM?.isRunning}
                    />}
                    <div className={classNames('&-publish-text')}>
                        <span>
                            <FormattedMessage
                                {...messages.publishDateInfo}
                                values={{
                                    date: (
                                        <FormattedDate
                                            value={ontology.lastPublishedDate}
                                            year='numeric'
                                            month='long'
                                            day='2-digit'
                                        />
                                    ),
                                    time: (
                                        <FormattedTime
                                            value={ontology.lastPublishedDate}
                                            format='HH MM'
                                        />
                                    ),
                                }}
                            />
                        </span>
                        <span>
                            <FormattedMessage
                                {...messages.publishByInfo}
                                values={{
                                    user: ontology.lastPublishedBy.displayName,
                                }}
                            />
                        </span>
                    </div>
                    <ArgButton onClick={publishOntologyModalHandler.open} label={messages.publish} />
                </div>
            </div>
            <ArgToolbarLayout
                className={classNames('&-body')}
                toolbarContext={toolbarContext}
                rightPanelMinWidth={LIBRARY_PANEL_MIN_WIDTH}
                leftPanelMinWidth={LIBRARY_PANEL_MIN_WIDTH}
                rightPanelDefaultWidth={LIBRARY_PANEL_MIN_WIDTH}
                leftPanelDefaultWidth={LIBRARY_PANEL_MIN_WIDTH}
                environmentContext={undefined}
            >
                <Chart
                    id='id'
                    className={classNames('&-body-chart')}
                    items={graphItems}
                    layout={ONTOLOGY_LAYOUT}
                    onChange={onChartChange}
                    onClick={onChartClick}
                    onHover={onChartHover}
                    onContextMenu={onChartRightClick}
                    positions={chartPositionsRef.current}
                    options={{
                        ...settings.options,
                        imageAlignment: imageAlignmentProvider.get(),
                        backgroundColor: 'white',
                    }}
                    onDragStart={handleDragStart}
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                    onKeyDown={handleKeyDown}
                    onKeyUp={handleKeyUp}
                />
                {objectMenuVertex && (
                    <ObjectMenu
                        className={classNames('&-body-object-menu-vertex')}
                        vertex={objectMenuVertex}
                        x={menuPosition.x}
                        y={menuPosition.y}
                        ontology={ontology}
                        setOntology={setOntology}
                        closeMenu={() => setObjectMenuVertex(undefined)}
                    />
                )}
                {relationMenuEdge && (
                    <RelationMenu
                        className={classNames('&-body-object-menu-relation')}
                        edge={relationMenuEdge}
                        x={menuPosition.x}
                        y={menuPosition.y}
                        closeMenu={() => setRelationMenuEdge(undefined)}
                        ontology={ontology}
                        setOntology={setOntology}
                    />
                )}
            </ArgToolbarLayout>
        </div>
    );
}
