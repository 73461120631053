import { debounce, DebouncedFunc } from 'lodash';
import { RefObject, useCallback, useLayoutEffect, useRef } from 'react';

import { ClassValue, SelectionProvider, useClassNames } from 'src/components/basic';
import { ArgonosModule } from '../../../components/application/modules';
import { useDataProviderForTable } from '../../../components/basic/arg-providers/use-data-provider-for-table';
import { Extension } from '../../models/extension';
import { ExtensionsDataProvider } from '../providers/extensions-data-provider';
import { ExtensionCardItem } from './extension-card-item';

import './extension-card-items-list.less';

const CLASSNAME = 'settings-extension-card-items-list';
const FETCH_MORE_EXTENSIONS_SCROLL_THRESHOLD_PERCENTAGE = 70;

export interface ExtensionCardItemsListProps {
    dataProvider: ExtensionsDataProvider;
    argonosModule: ArgonosModule | undefined;
    bodyRef: RefObject<HTMLDivElement>;
    search: string | undefined;
    selectionProvider?: SelectionProvider<Extension>;
    handleRefresh: () => void;
    className?: ClassValue;
}

export function ExtensionCardItemsList(props: ExtensionCardItemsListProps) {
    const {
        dataProvider,
        bodyRef,
        argonosModule,
        search,
        selectionProvider,
        handleRefresh,
        className,
    } = props;
    const classNames = useClassNames(CLASSNAME);

    const scrollDebounce = useRef<DebouncedFunc<any>>();

    useDataProviderForTable(dataProvider, 0, 1);

    const getDisplayedElements = useCallback((): Extension[] => {
        const elements: Extension[] = [];
        for (let row = 0; row < (dataProvider.rowCount || 1); row++) {
            const dataRow = dataProvider.getRow(row) as Extension;

            if (dataRow.name) {
                elements.push(dataRow);
            }
        }

        return elements;
    }, [dataProvider]);


    useLayoutEffect(() => {
        const bodyContent = bodyRef.current;
        if (!bodyContent) {
            return;
        }

        const onScroll = () => {
            if (scrollDebounce.current) {
                scrollDebounce.current.cancel();
            }
            scrollDebounce.current = debounce(async () => {
                if (!bodyRef.current) {
                    return;
                }
                const percentScroll = Math.floor((bodyRef.current.scrollTop /
                    (bodyRef.current.scrollHeight - bodyRef.current.clientHeight)) * 100);

                if (percentScroll && percentScroll >= FETCH_MORE_EXTENSIONS_SCROLL_THRESHOLD_PERCENTAGE) {
                    const extensions = getDisplayedElements();
                    dataProvider.getRow(extensions.length);
                }
            }, 500);
            scrollDebounce.current();
        };

        bodyContent.addEventListener('scroll', onScroll);

        return () => {
            if (scrollDebounce.current) {
                scrollDebounce.current.cancel();
            }
            bodyContent?.removeEventListener('scroll', onScroll);
        };
    }, [bodyRef.current, dataProvider, getDisplayedElements]);

    return (
        <div className={classNames('&', className)}>
            {getDisplayedElements().map((extension: Extension) => {
                return (
                    <ExtensionCardItem
                        key={extension.name}
                        extension={extension}
                        argonosModule={argonosModule}
                        search={search}
                        selectionProvider={selectionProvider}
                        onSuccess={handleRefresh}
                    />
                );
            })}
        </div>
    );
}
