import React, { ReactNode } from 'react';

import { ClassValue, useClassNames } from '../../../components/basic';
import { SubModulesBrander } from './sub-modules-brander';
import { ApplicationOrModuleBranding } from '../../models/visual-identity';
import { ArgonosModuleId } from 'src/components/application/modules';
import { ArgonosModulesRegistry } from '../../../components/application/argonos-modules-registry';

import '../components/modules-brander.less';


interface ModulesBranderProps {
    className?: ClassValue;
    modulesBrandingList: ApplicationOrModuleBranding[];
    onCustomNameChange: (customName: string, argonosModuleId: ArgonosModuleId) => void;
    onIconChange: (icon: Blob | undefined, argonosModuleId: ArgonosModuleId) => void;
    onLogoChange: (logo: Blob | undefined, argonosModuleId: ArgonosModuleId) => void;
}

export function ModulesBrander(props: ModulesBranderProps) {
    const {
        modulesBrandingList,
        className,
        onCustomNameChange,
        onIconChange,
        onLogoChange,
    } = props;

    const classNames = useClassNames('modules-brander');

    const list: ReactNode[] = ArgonosModulesRegistry.getInstance().listEnabled().map((module) => {
        const moduleBranding = modulesBrandingList.find((m) => m.id === module.id);

        if (!moduleBranding) {
            return null;
        }

        return <SubModulesBrander
            key={module.id}
            moduleBranding={moduleBranding}
            onCustomNameChange={(customName) => onCustomNameChange(customName, module.id)}
            onIconChange={(icon) => onIconChange(icon, module.id)}
            onLogoChange={(logo) => onLogoChange(logo, module.id)}
            logoPlaceholder={module.iconURL}
        />;
    }).value();


    return (
        <div className={classNames('&', className)}>
            {list}
        </div>
    );
}
