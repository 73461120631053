import { chain, forEach, isNil, isNumber, mapValues, pull, union } from 'lodash';
import React, {
    CSSProperties,
    DragEvent,
    ForwardedRef,
    forwardRef,
    MouseEvent,
    ReactNode,
    UIEvent,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from 'react';
import Debug from 'debug';
import { MessageDescriptor } from 'react-intl';
import useResizeObserver from '@react-hook/resize-observer';

import { ColumnChangeReason } from './types';
import { expandColumn } from './expand-column';
import { SelectionCell } from './selection-cell';
import { performColumnMove } from './move-column';
import { handleColumnResize } from './resize-column';
import { useScroll } from '../../../hooks/use-scroll';
import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ArgContextMenu } from '../arg-tools/arg-context-menu';
import { VirtualColumnScrollContainer } from './virtual-columns-container';
import { DataFilter, DataProvider, DataSorter, PropertySorter } from '../arg-providers/data-provider';
import { SelectionProvider } from '../arg-providers/selection-provider';
import { useDataProviderForTable } from '../arg-providers/use-data-provider-for-table';
import { useSelection } from '../arg-providers/use-selection';
import { ArgTable2Column } from '../arg-table/arg-table2';
import { ARG_TABLE3_CLASSNAME } from './shared-classnames';
import { ArgDraggableType, useDraggableKey } from '../arg-hooks/use-draggable-key';
import { escapeColumnKey } from '../utils';
import { ArgRenderedText, ArgRenderFunction } from '../types';

import './arg-table3.less';

const debug = Debug('basic:components:ArgTable3');

const DEFAULT_HEADER_HEIGHT = 30;
const LIST_SELECTION_COLUMN_WIDTH = 32;
const TABLE_SELECTION_COLUMN_WIDTH = 40;
const DEFAULT_TYPE = 'table';

export const DEFAULT_SORTABLE = true;

export const SELECTION_COLUMN = 'selection-column';
export const HORIZONTAL_SCROLLBAR_HEIGHT = 12;

export type ColumnKey = string;

export type ArgTableColumn3Sorter<T> = ((a: T, b: T) => number) | 'fromServer';

export interface ArgTableColumn3<T> extends Omit<ArgTable2Column<T>, 'width' | 'key' | 'sorter' | 'title'> {
    key: ColumnKey;

    // The column's title. When omitted default to columnName.
    title?: ReactNode | MessageDescriptor | ((column: ArgTableColumn3<T>, propertySorter: PropertySorter | undefined, lockIcon: ReactNode, sortIcon: ReactNode) => ReactNode);
    width?: number;
    columnName: string; // Needed by undo/redo
    className?: ClassValue;
    cellClassName?: ClassValue | ((data: any, item: T | undefined, index: number) => (ClassValue | undefined));
    sortable?: boolean;
    selected?: boolean;
    resizable?: boolean;
    rowHeader?: boolean;
    movable?: boolean;
    disableContextMenu?: boolean;
    sorter?: ArgTableColumn3Sorter<T>;
    additionalHeader?: ReactNode | ((column: ArgTableColumn3<T>) => ReactNode);
    columnSortName?: string; // To precise the name of the column if different from the key

    cellTooltip?: boolean | ((data: any, rowValue: T) => ArgRenderedText);
    cellTooltipClassName?: ClassValue;
}

export interface ArgTable3BodyRef {
    getCurrentScrollTop: () => number;
    scrollToRowIndex: (row: number, behavior?: ScrollBehavior) => void;
    scrollToTop: (top: number, behavior?: ScrollBehavior) => void;
}

export type ArgTable3OnDragStartHandler<T> = (event: DragEvent, row: T) => void;

export enum ArgTable3RowState {
    Error = '@@###error###@@',
    Loading = '@@###loading###@@',
}

export interface RowState<T> {
    data: T | ArgTable3RowState;
    className?: ClassValue;
    draggable?: boolean;
}

export interface ArgTable3Props<T, F extends DataFilter> {
    columns: ArgTableColumn3<T>[];
    initialItemsCount: number;
    rowHeight: number;
    dataProvider?: DataProvider<T, F>;
    className?: ClassValue;
    header?: boolean;
    selectionProvider?: SelectionProvider<T>;
    onSelectionChange?: (row: T, newState: boolean, selectionProvider: SelectionProvider<T>) => void;
    type?: 'table' | 'list';
    renderLoadingCell?: (column: ArgTableColumn3<T>, index?: number) => ReactNode;
    renderErrorCell?: (column: ArgTableColumn3<T>, index?: number, error?: Error) => ReactNode;
    sort?: DataSorter;
    filter?: F;
    onSortChange?: (sort: DataSorter | undefined, column: ArgTableColumn3<T>) => void;
    lockedColumns?: Record<ColumnKey, true>;
    onLockedColumnsChange?: (column: ArgTableColumn3<T>, locks: Record<ColumnKey, boolean>) => void;
    initialLockedColumns?: Record<ColumnKey, boolean>;
    visibleColumns?: ColumnKey[];
    initialVisibleColumns?: ColumnKey[];
    onVisibleColumnsChange?: (visibleColumnKeys: ColumnKey[], changeReasons: ColumnChangeReason[]) => void;
    columnWidths?: Record<ColumnKey, number>;
    onColumnWidthChange?: (column: ArgTableColumn3<T>, width: number) => void;
    searchScrollTop?: number;
    onContextMenuRender?: (
        event: MouseEvent,
        row: T | undefined,
        rowIndex: number | undefined,
        closeMenu: () => void,
        getPopupContainer?: (node: HTMLElement) => HTMLElement
    ) => ReactNode;
    searchValue?: string;
    adjustColumnsOnFirstDraw?: boolean;
    adjustColumns?: boolean;
    headerHeight?: number;
    additionalHeaderHeight?: number;
    disabled?: boolean;
    noSelectionColumn?: boolean;
    onRowClick?: (event: MouseEvent, row: T, rowIndex: number, dataColumn?: string) => void;
    draggable?: ArgDraggableType;
    draggableModifierKey?: 'Control';
    onDragStart?: ArgTable3OnDragStartHandler<T>;
    onDragEnd?: (event: DragEvent) => void;
    isRowSelectable?: (row: T) => boolean;
    isRowHighlighted?: (row: T, rowIndex: number) => boolean;

    scrollColumnName?: string;

    onRowDoubleClick?: (event: MouseEvent, row: T, rowIndex: number) => void;

    emptyRenderer?: ArgRenderFunction;
}

export function _ArgTable3<T, F extends DataFilter = any>(props: ArgTable3Props<T, F>, ref: ForwardedRef<ArgTable3BodyRef>) {
    const {
        columns,
        initialItemsCount,
        rowHeight,
        dataProvider,
        className,
        header = true,
        selectionProvider,
        onSelectionChange,
        sort: externalSort,
        filter,
        onSortChange,
        renderLoadingCell,
        renderErrorCell,
        type = DEFAULT_TYPE,
        lockedColumns: externalLockedColumns,
        visibleColumns: externalVisibleColumns,
        isRowHighlighted,
        initialLockedColumns = {},
        initialVisibleColumns,
        onLockedColumnsChange,
        onVisibleColumnsChange,
        searchScrollTop,
        columnWidths: externalColumnWidths,
        onColumnWidthChange,
        onContextMenuRender,
        searchValue,
        adjustColumnsOnFirstDraw,
        headerHeight = DEFAULT_HEADER_HEIGHT,
        additionalHeaderHeight,
        disabled,
        noSelectionColumn,
        onRowClick,
        draggable,
        draggableModifierKey,
        onDragStart,
        onDragEnd,
        isRowSelectable,
        onRowDoubleClick,
        scrollColumnName,
        adjustColumns,
        emptyRenderer,
    } = props;


    const classNames = useClassNames(ARG_TABLE3_CLASSNAME); // Be careful: also used for screenshot
    const areRowsDraggable = useDraggableKey(true, draggableModifierKey);

    const containerRef = useRef<HTMLDivElement>(null);
    const bodyRef = useRef<HTMLDivElement>(null);
    const lockedBodyRef = useRef<HTMLDivElement>(null);
    const headerBodyRef = useRef<HTMLDivElement>(null);

    const initialSort: DataSorter = useMemo(() => ({
        propertySorters: (
            chain([...columns])
                .filter((column) => !isNil(column.defaultSortOrder) && !isNil(column.columnSortName || column.key))
                .map((column) => ({
                    propertyName: column.columnSortName || column.key,
                    order: column.defaultSortOrder === 'ascend' ? 'ascending' : 'descending',
                }))
                .value() as PropertySorter[]
        ),
    }), [columns]);

    const [hoverRowIndex, setHoverRowIndex] = useState<number>();
    const [internalSort, setInternalSort] = useState<DataSorter>();

    const [rowContextMenuVisible, setRowContextMenuVisible] = useState<{
        event: MouseEvent;
        row: T | undefined;
        rowIndex: number | undefined;
    }>();

    const applyInternalSort = !('sort' in props);
    const sort = ((applyInternalSort) ? internalSort : externalSort) || initialSort;

    const [internalLockedColumns, setInternalLockedColumns] = useState<Record<ColumnKey, boolean>>(initialLockedColumns || {});
    const useInternalLockedColumns = !('lockedColumns' in props);
    const lockedColumns = (useInternalLockedColumns) ? internalLockedColumns : externalLockedColumns;

    const [internalVisibleColumns, setInternalVisibleColumns] = useState<ColumnKey[] | undefined>(initialVisibleColumns);
    const useInternalVisibleColumns = !('visibleColumns' in props);
    const visibleColumns = (useInternalVisibleColumns) ? internalVisibleColumns : externalVisibleColumns;

    const [internalColumnWidths, setInternalColumnWidths] = useState<Record<ColumnKey, number>>({});
    const useInternalColumnWidths = !('columnWidths' in props);
    const columnWidths = (useInternalColumnWidths) ? internalColumnWidths : externalColumnWidths;

    const [leftColumnsDragTransforms, setLeftColumnsDragTransforms] = useState<Record<ColumnKey, string>>();
    const [rightColumnsDragTransforms, setRightColumnsDragTransforms] = useState<Record<ColumnKey, string>>();
    const [draggedColumnKey, setDraggedColumnKey] = useState<ColumnKey>();

    const adjustColumnStateRef = useRef<'waiting' | 'done'>('waiting');
    const adjustColumnStateTimerRef = useRef<ReturnType<typeof setTimeout>>();

    let itemsCount: number | undefined = initialItemsCount;
    if (isNumber(dataProvider?.rowCount)) {
        itemsCount = dataProvider?.rowCount;
    }

    const {
        totalHeight,
        visibleNodeCount,
        startNode,
        scrollDisplayManager,
        scrollPosition: [scrollLeft, scrollTop],
        containerHeight,
        containerWidth,
    } = useScroll<HTMLDivElement, T>(itemsCount || 0, rowHeight, containerRef, bodyRef, headerBodyRef, lockedBodyRef);

    useImperativeHandle(ref, () => {
        return {
            getCurrentScrollTop: () => {
                return scrollTop;
            },
            scrollToRowIndex: (row: number, behavior: ScrollBehavior = 'smooth') => {
                bodyRef.current?.scroll({
                    top: row * rowHeight,
                    behavior: behavior,
                });
            },
            scrollToTop: (top: number, behavior: ScrollBehavior = 'smooth') => {
                bodyRef.current?.scroll({
                    top: top,
                    behavior: behavior,
                });
            },
        };
    }, [rowHeight, scrollTop]);

    const dataProviderStateId = useDataProviderForTable(dataProvider, startNode, startNode + visibleNodeCount - 1, columns, filter, sort, searchValue);

    useSelection(selectionProvider);

    useEffect(() => {
        adjustColumnStateRef.current = 'waiting';
    }, [dataProvider]);

    useResizeObserver(containerRef.current, (entry: ResizeObserverEntry) => {
        if (!adjustColumns || columns.some((column) => column.resizable)) {
            return;
        }
        adjustColumnStateRef.current = 'done';
        handleAdjustColumnsWidth(entry.target as HTMLElement);
    });

    const columnsWithSelection: ArgTableColumn3<T>[] = useMemo(() => {
        if (!selectionProvider || !columns || noSelectionColumn) {
            return columns || [];
        }

        const columnsWithSelection = [{
            key: SELECTION_COLUMN,
            columnName: '',
            dataIndex: '',
            width: type === 'list' ? LIST_SELECTION_COLUMN_WIDTH : TABLE_SELECTION_COLUMN_WIDTH,
            className: 'selection-column',
            rowHeader: true,
            render: function checkRender(_: any, row: T, rowIndex?: number) {
                const disabled = isRowSelectable ? !isRowSelectable(row) : undefined;

                return (
                    <SelectionCell<T>
                        row={row}
                        key={rowIndex}
                        disabled={disabled}
                        selectionProvider={selectionProvider}
                        onSelectionChange={onSelectionChange}
                    />
                );
            },

        } as ArgTableColumn3<T>, ...columns];

        return columnsWithSelection;
    }, [selectionProvider, columns, noSelectionColumn, type, isRowSelectable, onSelectionChange]);

    const handleDragStart: ArgTable3OnDragStartHandler<T> = useCallback((event, row): void => {
        onDragStart?.(event, row);
    }, [onDragStart]);

    const rowsCache = useMemo(() => {
        const map = new Map();

        if (!dataProvider) {
            return map;
        }

        const elements = scrollDisplayManager.getViewPortContent(startNode, visibleNodeCount);
        elements.forEach((element, _) => {
            if (element.row < 0) {
                return;
            }
            const rowData = dataProvider.getRow(element.row);
            let rowClassName: string | undefined = undefined;
            let draggableRow = false;
            let hasSelection = false;

            if (typeof (rowData) === 'object') {
                if (areRowsDraggable && draggable === true) {
                    draggableRow = true;
                }

                hasSelection = !!selectionProvider?.has(rowData) || !!isRowHighlighted?.(rowData, element.row);
                if (draggable === 'selection') {
                    draggableRow = hasSelection && areRowsDraggable;
                }
                if (hasSelection) {
                    rowClassName = 'selected';
                }
            }
            if (hoverRowIndex === element.row) {
                if (rowClassName) {
                    rowClassName += ' over';
                } else {
                    rowClassName = 'over';
                }
            }

            map.set(element.row, {
                data: rowData,
                className: rowClassName,
                draggable: draggableRow,
            });
        });

        return map;
    }, [
        isRowHighlighted,
        dataProvider,
        dataProviderStateId,
        areRowsDraggable,
        draggable,
        startNode,
        visibleNodeCount,
        selectionProvider?.stateId,
        hoverRowIndex,
        scrollDisplayManager,
        selectionProvider,
    ]);

    const handleColumnHeaderClick = useCallback((column: ArgTableColumn3<T>, event: UIEvent) => {
        event.preventDefault();

        if (!(column.sortable ?? DEFAULT_SORTABLE)) {
            return;
        }

        let newSort: DataSorter | undefined;

        if (sort?.propertySorters[0]?.propertyName === (column.columnSortName || column.key)) {
            if (sort.propertySorters[0].order === 'ascending') {
                newSort = {
                    propertySorters: [{
                        propertyName: column.columnSortName || column.key,
                        order: 'descending',
                    }],
                };
            }
        } else {
            newSort = {
                propertySorters: [{
                    propertyName: column.columnSortName || column.key,
                    order: 'ascending',
                }],
            };
        }

        if (applyInternalSort) {
            setInternalSort(newSort);
        }

        onSortChange && onSortChange(newSort, column);
    }, [sort.propertySorters, applyInternalSort, onSortChange]);

    const handleColumnHeaderDoubleClick = useCallback((column: ArgTableColumn3<T>, event: Event) => {
        if (event.defaultPrevented) {
            return;
        }

        event.preventDefault();

        if (column.rowHeader || !column.resizable) {
            return;
        }

        containerRef.current && expandColumn(containerRef.current,
            column,
            (newSize) => {
                if (useInternalColumnWidths) {
                    setInternalColumnWidths((prev) => {
                        return {
                            ...prev,
                            [column.key]: newSize,
                        };
                    });
                }
                onColumnWidthChange && onColumnWidthChange(column, newSize);
            },
        );
    }, [useInternalColumnWidths, onColumnWidthChange]);

    const handleMouseOver = useCallback((event: MouseEvent<HTMLElement>) => {
        const { rowIndex } = computeEventContext(event);

        if (rowIndex === undefined || hoverRowIndex === rowIndex) {
            return;
        }

        setHoverRowIndex(rowIndex);
    }, [hoverRowIndex]);

    const handleMouseLeave = useCallback((event: MouseEvent<HTMLDivElement>) => {
        setHoverRowIndex(undefined);
    }, []);

    const handleRowContextMenu = useCallback((event: MouseEvent<HTMLDivElement>, row: T, rowIndex: number) => {
        setRowContextMenuVisible({
            event,
            row,
            rowIndex,
        });
    }, []);

    const handleHideContextMenu = useCallback(() => {
        setRowContextMenuVisible(undefined);
    }, []);

    const handleDoubleClick = useCallback((event: MouseEvent<HTMLDivElement>) => {
        if (event.defaultPrevented) {
            return;
        }

        event.preventDefault();

        const { rowIndex } = computeEventContext(event);
        let row: T | undefined = undefined;
        if (rowIndex !== undefined) {
            const r = dataProvider!.getRow(rowIndex);
            if (typeof (r) === 'object') {
                row = r as T;
            }
        }

        if (row && onRowDoubleClick && rowIndex !== undefined) {
            onRowDoubleClick(event, row, rowIndex);
        }
    }, [dataProvider, onRowDoubleClick]);

    const handleContextMenu = useCallback((event: MouseEvent<HTMLDivElement>) => {
        event.preventDefault();

        const { rowIndex } = computeEventContext(event);
        let row: T | undefined = undefined;
        if (rowIndex !== undefined) {
            const r = dataProvider!.getRow(rowIndex);
            if (typeof (r) === 'object') {
                row = r as T;
            }
        }


        if (row && onContextMenuRender && rowIndex !== undefined) {
            handleRowContextMenu(event, row, rowIndex);
        }
    }, [dataProvider, handleRowContextMenu, onContextMenuRender]);

    const noVerticalScroll = containerHeight - headerHeight - (additionalHeaderHeight ?? 0) - HORIZONTAL_SCROLLBAR_HEIGHT > totalHeight;

    const visibleHeight = (itemsCount || 0) * rowHeight; // + ((noVerticalScroll) ? 0 : HORIZONTAL_SCROLLBAR_HEIGHT);

    const handleColumnVisible = useCallback((column: ArgTableColumn3<T>, visible: boolean) => {
        let newList = (visibleColumns) ? [...visibleColumns] : columns.map((c) => c.key);
        if (visible) {
            newList = union(newList, [column.key]);
        } else {
            newList = pull(newList, column.key);
        }

        if (useInternalVisibleColumns) {
            setInternalVisibleColumns(newList);
        }

        const changeReason: ColumnChangeReason = {
            type: 'visible',
            state: visible,
            source: column.key,
        };

        onVisibleColumnsChange && onVisibleColumnsChange(newList, [changeReason]);
    }, [columns, visibleColumns, onVisibleColumnsChange, useInternalVisibleColumns]);

    const [leftColumns, rightsColumns] = useMemo<[ArgTableColumn3<T>[], ArgTableColumn3<T>[]]>(() => {
        const leftColumns: ArgTableColumn3<T>[] = [];
        const rightsColumns: ArgTableColumn3<T>[] = [];

        columnsWithSelection.forEach((c) => {
            if (!c.rowHeader) {
                return;
            }
            leftColumns.push(c);
        });

        if (visibleColumns?.length) {
            visibleColumns.forEach((columnKey) => {
                const col = columns.find((c) => c.key === columnKey);
                if (!col || col.rowHeader) {
                    return;
                }

                if (lockedColumns?.[columnKey]) {
                    leftColumns.push(col);

                    return;
                }
                rightsColumns.push(col);
            });
        } else {
            columnsWithSelection.forEach((c) => {
                if (c.rowHeader) {
                    return;
                }
                if (lockedColumns?.[c.key]) {
                    leftColumns.push(c);

                    return;
                }
                rightsColumns.push(c);
            });
        }

        return [leftColumns, rightsColumns];
    }, [selectionProvider, columnsWithSelection, lockedColumns, visibleColumns, columns]);

    const handleColumnLock = useCallback((column: ArgTableColumn3<T>, locked: boolean) => {
        const locks: Record<ColumnKey, boolean> = {};

        if (locked) {
            const idx = rightsColumns.indexOf(column);
            for (let i = 0; i <= idx; i++) {
                locks[rightsColumns[i].key] = true;
            }
        } else {
            const idx = leftColumns.indexOf(column);
            for (let i = idx; i < leftColumns.length; i++) {
                locks[leftColumns[i].key] = false;
            }
        }

        if (useInternalLockedColumns) {
            setInternalLockedColumns((prev) => {
                return { ...prev, ...locks };
            });
        }

        onLockedColumnsChange && onLockedColumnsChange(column, locks);
    }, [onLockedColumnsChange, useInternalLockedColumns, leftColumns, rightsColumns]);

    const handleColumnMove = useCallback((column: ArgTableColumn3<T>, event: MouseEvent) => {
        const isLocked = !!lockedColumns?.[column.key];

        const changeReason: ColumnChangeReason = {
            type: 'move',
            source: column.key,
        };

        containerRef.current && performColumnMove(containerRef.current,
            column,
            (isLocked) ? leftColumns : rightsColumns,
            (!isLocked) ? leftColumns : rightsColumns,
            isLocked,
            column.movable !== false,
            (isLocked) ? setLeftColumnsDragTransforms : setRightColumnsDragTransforms,
            setDraggedColumnKey,
            handleColumnHeaderDoubleClick,
            event,
            (columnIds: string[]) => {
                if (useInternalVisibleColumns) {
                    setInternalVisibleColumns(columnIds);
                }
                onVisibleColumnsChange && onVisibleColumnsChange(columnIds, [changeReason]);
            });
    }, [
        useInternalVisibleColumns,
        onVisibleColumnsChange,
        lockedColumns,
        handleColumnHeaderDoubleClick,
        leftColumns,
        rightsColumns,
    ]);

    const handleRowClick = useCallback((event: MouseEvent<HTMLElement>) => {
        const {
            rowIndex,
            resizeColumnId,
            dataColumn,
        } = computeEventContext(event);

        if (event.defaultPrevented || resizeColumnId || disabled) {
            return;
        }

        if (isNumber(rowIndex) && dataProvider) {
            event.preventDefault();

            const rowData = dataProvider.getRow(rowIndex);
            if (rowData === ArgTable3RowState.Error || rowData === ArgTable3RowState.Loading || !onRowClick) {
                return;
            }

            onRowClick(event, rowData as T, rowIndex, dataColumn);

            return;
        }
    }, [dataProvider, disabled, onRowClick]);

    const handleMouseDown = useCallback((event: MouseEvent<HTMLElement>) => {
        const {
            headerColumnId,
            resizeColumnId,
        } = computeEventContext(event);

        if (event.defaultPrevented) {
            return;
        }

        if (resizeColumnId) {
            if (event.button !== 0) {
                return;
            }

            const column = columns.find((c) => c.key === resizeColumnId);
            if (!column) {
                return;
            }
            containerRef.current && handleColumnResize(containerRef.current,
                column,
                event,
                !!lockedColumns?.[column.key],
                (newSize: number) => {
                    if (useInternalColumnWidths) {
                        setInternalColumnWidths((prev) => {
                            return {
                                ...prev,
                                [column.key]: newSize,
                            };
                        });
                    }
                    onColumnWidthChange && onColumnWidthChange(column, newSize);
                }, () => {
                    let newVisibleColumns;

                    if (visibleColumns?.length) {
                        newVisibleColumns = [...visibleColumns];
                    } else {
                        newVisibleColumns = columns.map((c) => c.key);
                    }

                    newVisibleColumns = pull(newVisibleColumns, column.key);
                    if (useInternalVisibleColumns) {
                        setInternalVisibleColumns(newVisibleColumns);
                    }

                    const changeReason: ColumnChangeReason = {
                        type: 'visible',
                        state: false,
                        source: column.key,
                    };

                    onVisibleColumnsChange && onVisibleColumnsChange(newVisibleColumns, [changeReason]);
                },
            );

            return;
        }

        if (disabled) {
            return;
        }

        if (headerColumnId) {
            if (event.button !== 0) {
                return;
            }
            const column = columns.find((c) => c.key === headerColumnId);
            if (!column) {
                return;
            }

            if ((event.target as HTMLElement).tagName.toUpperCase() === 'INPUT') {
                return true; //Custom header with an editable title
            }

            column.movable && handleColumnMove(column, event);

            return;
        }
    }, [
        disabled,
        columns,
        lockedColumns,
        useInternalColumnWidths,
        onColumnWidthChange,
        visibleColumns,
        useInternalVisibleColumns,
        onVisibleColumnsChange,
        handleColumnMove,
    ]);

    const leftColumnsWidth = leftColumns.reduce((acc, col) => {
        let colWidth = columnWidths?.[col.key];

        if (colWidth === undefined) {
            if (col.width === undefined) {
                return acc;
            }

            colWidth = col.width;
        }

        return acc + colWidth;
    }, 0);

    const handleColumnScroll = useCallback(() => {
        if (!scrollColumnName) {
            return;
        }

        const scrollColumnElement = containerRef.current?.querySelector<HTMLDivElement>(`[data-header="${scrollColumnName}"]`);

        if (!scrollColumnElement) {
            return;
        }

        bodyRef.current?.scroll({ behavior: 'smooth', left: scrollColumnElement.offsetLeft });
    }, [scrollColumnName]);

    const handleAdjustColumnsWidth = useCallback((body: HTMLElement) => {
        debug('handleAdjustColumnsWidth', body, adjustColumnStateRef.current);

        for (; body;) {
            if (body.hasAttribute('data-table')) {
                break;
            }
            if (!body.parentElement) {
                console.error('This element has no parent table');

                return;
            }
            body = body.parentElement;
        }

        if (adjustColumnStateTimerRef.current) {
            clearTimeout(adjustColumnStateTimerRef.current);
            adjustColumnStateTimerRef.current = undefined;
        }

        function adjust() {
            const columnSizes: Record<string, number> = {};
            let newColumnSizes: Record<string, number> = {};

            let totalWidth = 0;

            const bodyWidth = (body.getBoundingClientRect().width - (selectionProvider ? 40 : 0));

            //console.log('BodyWidth=', bodyWidth, body.getBoundingClientRect().width);

            debug('handleAdjustColumnsWidth.adjust', 'Body width=', bodyWidth);

            columns.forEach((column) => {
                if (column.rowHeader) {
                    return;
                }

                const columnComponent = body.querySelector(`[data-column="${escapeColumnKey(column.key)}"]`) as HTMLElement;

                if (!columnComponent) {
                    debug('handleAdjustColumnsWidth', 'Can not find column', column.key, column.columnName);

                    return;
                }

                const columnWidth = columnComponent.getBoundingClientRect().width;
                columnSizes[column.key] = columnWidth;
                newColumnSizes[column.key] = columnWidth;
                totalWidth += columnWidth;

                expandColumn(body,
                    column, (newSize) => {
                        debug('handleAdjustColumnsWidth', 'Request new size=', newSize, 'for column', column.columnName);

                        newColumnSizes[column.key] = newSize;
                        totalWidth += newSize - columnSizes[column.key];
                    });
            });

            debug('handleAdjustColumnsWidth.adjust', 'columnSizes=', columnSizes, 'newColumnSizes=', newColumnSizes, 'totalWidth=', totalWidth);

            if (bodyWidth > 0) {
                const leftWidth = bodyWidth - totalWidth;
                if (leftWidth > 0) {
                    const leftByColumn = Math.floor(leftWidth / columns.length);
                    debug('handleAdjustColumnsWidth', 'Add', leftByColumn, 'pixels ');
                    newColumnSizes = mapValues(newColumnSizes, (newWidth, columnKey) => {
                        return newWidth + leftByColumn;
                    });
                }
            }

            debug('handleAdjustColumnsWidth.adjust', 'newColumnSizes=', newColumnSizes);

            forEach(newColumnSizes, (newWidth, columnKey) => {
                const column = columns.find((c) => c.key === columnKey);
                if (!column) {
                    return;
                }

                if (useInternalColumnWidths) {
                    setInternalColumnWidths((prev) => {
                        return {
                            ...prev || [],
                            [column.key]: newWidth,
                        } as Record<ColumnKey, number>;
                    });
                }
                onColumnWidthChange?.(column, newWidth);
            });

            handleColumnScroll();
        }

        function waitForAdjust() {
            // do it as soon as possible
            requestAnimationFrame(adjust);
        }

        adjustColumnStateTimerRef.current = setTimeout(waitForAdjust, 0);
    }, [selectionProvider, columns, useInternalColumnWidths, onColumnWidthChange, handleColumnScroll]);

    const handleDataLoaded = useCallback((body: HTMLElement) => {
        debug('handleDataLoaded', 'adjustColumnsOnFirstDraw=', adjustColumnsOnFirstDraw);
        if (!adjustColumnsOnFirstDraw) {
            return;
        }

        if (adjustColumnStateRef.current === 'done') {
            return;
        }

        adjustColumnStateRef.current = 'done';

        handleAdjustColumnsWidth(body);
    }, [adjustColumnsOnFirstDraw, handleAdjustColumnsWidth]);

    useEffect(() => {
        handleColumnScroll();
    }, [handleColumnScroll]);

    const style = {
        '--arg-table3-header-height': `${headerHeight}px`,
        '--arg-table3-additional-header-height': `${additionalHeaderHeight || 0}px`,
        '--arg-table3-row-height': `${rowHeight}px`,
    } as CSSProperties;

    const cls = {
        disabled,
        [`type-${type}`]: true,
    };

    return (
        <div
            className={classNames('&', className, cls)}
            onMouseOver={handleMouseOver}
            onClick={handleRowClick}
            onMouseDown={handleMouseDown}
            onMouseLeave={handleMouseLeave}
            onContextMenu={handleContextMenu}
            onDoubleClick={handleDoubleClick}
            ref={containerRef}
            style={style}
            data-table={true}
            data-clone-component='arg-table3'
        >

            {/* locked columns */}
            <VirtualColumnScrollContainer<T>
                rowsCache={rowsCache}
                rowHeight={rowHeight}
                startNode={startNode}
                visibleNodeCount={visibleNodeCount}
                scrollTop={scrollTop}
                globalScrollTop={scrollTop}
                scrollLeft={0}
                totalHeight={visibleHeight}
                noVerticalScroll={noVerticalScroll}
                columns={leftColumns}
                searchValue={searchValue}
                columnsStartIndex={0}
                columnsEndIndex={leftColumns.length}
                scrollDisplayManager={scrollDisplayManager}
                className={classNames('&-left')}
                locked={true}
                firstColumn={true}
                lastColumn={!rightsColumns.length}
                header={header}
                bodyRef={lockedBodyRef}
                itemsCount={itemsCount || 0}
                headerHeight={headerHeight}
                additionalHeaderHeight={additionalHeaderHeight}
                renderLoadingCell={renderLoadingCell}
                renderErrorCell={renderErrorCell}
                sort={sort}
                onColumnLock={handleColumnLock}
                onColumnSort={handleColumnHeaderClick}
                onColumnVisible={handleColumnVisible}
                canLockColumn={!!lockedColumns}
                dragColumnTransforms={leftColumnsDragTransforms}
                draggedColumnKey={draggedColumnKey}
                columnWidths={columnWidths}
                onColumnWidthChange={onColumnWidthChange}
                disabled={disabled}
                onDragStart={handleDragStart}
                onDragEnd={onDragEnd}
            />

            {/* unlocked columns   */}
            <VirtualColumnScrollContainer<T>
                rowsCache={rowsCache}
                rowHeight={rowHeight}
                startNode={startNode}
                searchValue={searchValue}
                visibleNodeCount={visibleNodeCount}
                noVerticalScroll={noVerticalScroll}
                scrollLeft={scrollLeft}
                searchScrollTop={searchScrollTop}
                globalScrollTop={scrollTop}
                totalHeight={visibleHeight}
                columns={rightsColumns}
                columnsStartIndex={0}
                columnsEndIndex={rightsColumns.length}
                scrollDisplayManager={scrollDisplayManager}
                bodyRef={bodyRef}
                className={classNames('&-right')}
                firstColumn={!leftColumns.length}
                lastColumn={true}
                header={header}
                headerBodyRef={headerBodyRef}
                itemsCount={itemsCount || 0}
                headerHeight={headerHeight}
                additionalHeaderHeight={additionalHeaderHeight}
                renderLoadingCell={renderLoadingCell}
                renderErrorCell={renderErrorCell}
                sort={sort}
                leftColumnsWidth={leftColumnsWidth}
                onColumnLock={handleColumnLock}
                onColumnSort={handleColumnHeaderClick}
                onColumnVisible={handleColumnVisible}
                canLockColumn={!!lockedColumns}
                dragColumnTransforms={rightColumnsDragTransforms}
                draggedColumnKey={draggedColumnKey}
                columnWidths={columnWidths}
                onColumnWidthChange={onColumnWidthChange}
                onDataLoaded={handleDataLoaded}
                disabled={disabled}
                onDragStart={handleDragStart}
                onDragEnd={onDragEnd}
                data-screenshot-id='arg-table-right'
            />

            {rowContextMenuVisible && !disabled && onContextMenuRender &&
                <ArgContextMenu
                    overlay={(getPopupContainer) => onContextMenuRender(
                        rowContextMenuVisible.event,
                        rowContextMenuVisible.row,
                        rowContextMenuVisible.rowIndex,
                        handleHideContextMenu,
                        getPopupContainer,
                    )}
                    visible={true}
                    x={rowContextMenuVisible.event.clientX}
                    y={rowContextMenuVisible.event.clientY}
                    onHide={handleHideContextMenu} />}
            {
                itemsCount === 0 && emptyRenderer && <div className={classNames('&-empty')}>
                    {emptyRenderer()}
                </div>
            }
        </div>
    );
}

export const ArgTable3 = forwardRef(_ArgTable3) as <T, F extends DataFilter = any> (
    props: ArgTable3Props<T, F> & { ref?: ForwardedRef<ArgTable3BodyRef>}
) => ReturnType<typeof _ArgTable3>;

function computeEventContext(event: MouseEvent<HTMLElement>): {
    rowIndex?: number;
    headerColumnId?: string;
    dragColumnId?: string;
    resizeColumnId?: string;
    dataColumn?: string;
} {
    let target: HTMLElement | null = event.target as HTMLElement;

    let rowIndex: number | undefined;
    let headerColumnId: string | undefined;
    let dataColumn: string | undefined;
    let dragColumnId: string | undefined;
    let resizeColumnId: string | undefined;

    for (; target; target = target.parentElement) {
        const headerDrag = target.getAttribute('data-dragcursor');
        if (headerDrag) {
            dragColumnId = headerDrag;
            break;
        }

        const headerResizer = target.getAttribute('data-columnresizer');
        if (headerResizer) {
            resizeColumnId = headerResizer;
            break;
        }

        const $rowIndex = target.getAttribute('data-rowindex');
        if ($rowIndex) {
            rowIndex = parseInt($rowIndex);

            const $dataColumn = target.parentElement?.getAttribute('data-column');
            if ($dataColumn) {
                dataColumn = $dataColumn;
            }
            break;
        }

        const $columnId = target.getAttribute('data-header');
        if ($columnId) {
            headerColumnId = $columnId;
            break;
        }

        const $table = target.getAttribute('data-table');
        if ($table) {
            return {};
        }
    }

    return {
        rowIndex,
        headerColumnId,
        dragColumnId,
        resizeColumnId,
        dataColumn,
    };
}
