import { cloneDeep, isEqual } from 'lodash';

import { GetOntologyStyleDTO } from 'src/settings/models/dtoApi';
import { GraphCustomizerState } from 'src/exploration/hooks/use-graph-style-customisation';
import {
    ContinuousValueIntervaleType,
    DiscreteValue,
    Gradient,
    Interval,
    StyleControlType,
    UserDefinedContent,
} from 'src/components/common/graph/customisation/graph-style';
import { VertexStyle } from 'src/exploration/model/vertex';
import { FullOntologyItemStyle, FullOntologyLinkType, FullOntologyObjectType } from '../../types';
import { immutableSet } from '../../../../../components/basic';
import { EdgeStyle } from 'src/exploration/model/edge';

type StyleKey = keyof GetOntologyStyleDTO;

export const addProperty = (
    existingStyle: GetOntologyStyleDTO | undefined,
    itemSelected: FullOntologyObjectType | FullOntologyLinkType | undefined,
    properties: UserDefinedContent,
    styleKey: StyleKey,
): GetOntologyStyleDTO | undefined => {
    if (!itemSelected || !existingStyle) {
        return existingStyle;
    }

    const newStyle = immutableSet(existingStyle, [styleKey, itemSelected.name], {
        userDefinedContent: getNewUserDefinedContent(
            properties,
            existingStyle[styleKey][itemSelected.name]?.userDefinedContent,
        ),
        ruleSets: existingStyle[styleKey][itemSelected.name]?.ruleSets || {},
        titleProperty: existingStyle[styleKey][itemSelected.name]?.titleProperty,
    });

    if (isEqual(newStyle, existingStyle)) {
        return existingStyle;
    }

    return newStyle;
};

const getNewUserDefinedContent = (
    properties: VertexStyle,
    userDefinedContent?: VertexStyle,
) => {
    if (!userDefinedContent) {
        return properties;
    }

    return { ...userDefinedContent, ...properties };
};

export const updateStyleFromState = (
    currentStyle: GetOntologyStyleDTO,
    itemSelected: FullOntologyObjectType | FullOntologyLinkType | undefined,
    newState: GraphCustomizerState,
    styleKey: StyleKey,
) => {
    const newStyle = cloneDeep(currentStyle);
    if (itemSelected?.name) {
        newStyle[styleKey][itemSelected.name] = {
            ...newStyle[styleKey][itemSelected.name],
            ruleSets: newState.ruleSets,
            titleProperty: newState.titleProperty,
        };
    }

    return newStyle;
};

export const getDefaultValueFromControlType = (
    property: string | undefined,
    controlType?: StyleControlType,
) => {
    let value: DiscreteValue | Interval | Gradient | null = null;

    if (property !== undefined && controlType !== undefined) {
        switch (controlType) {
            case 'gradient':
                value = {
                    gradient: {
                        type: 'Closed' as ContinuousValueIntervaleType,
                        left: null,
                        right: null,
                    },
                } as Gradient;

                break;
            case 'interval':
                value = { value: null };
                break;

            default:
                value = {
                    value: null,
                } as DiscreteValue;
                break;
        }
    }

    return value;
};

/**
 * Get color of mixed style edge (through userDefinedContent)
 */
export function getEdgeColor(mixedEdgeStyle?: EdgeStyle & FullOntologyItemStyle) {
    // Use fillColor cause edge custom style are stored through userDefinedContent which doesnt have color prop
    const color = mixedEdgeStyle?.fillColor || mixedEdgeStyle?.color;

    return color;
}
