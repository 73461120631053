import React, { useCallback } from 'react';
import { defineMessages } from 'react-intl';

import { ArgButton, ArgInputSearch, ClassValue, useArgModalContext, useClassNames } from 'src/components/basic';
import { CreateUniverseModal } from '../create-universe-modal/create-universe-modal';
import { useOntologiesState } from '../../providers/universes';

import './table-toolbar.less';


const messages = defineMessages({
    newUniverse: {
        id: 'settings.universes-management-page.toolbar.new',
        defaultMessage: 'New',
    },
    import: {
        id: 'settings.universes-management-page.toolbar.import',
        defaultMessage: 'Import',
    },
    searchPlaceholder: {
        id: 'settings.universes-management-page.search.placeholder',
        defaultMessage: 'Search for a universe',
    },
});

interface TableToolbarProps {
    className?: ClassValue;
    handleSearch: (value: string) => void;
}

export function TableToolbar(props: TableToolbarProps) {
    const { className, handleSearch } = props;

    const classNames = useClassNames('settings-table-toolbar');
    const modalContext = useArgModalContext();
    const { updateOntologies } = useOntologiesState();

    const handleCreateUniverse = useCallback(() => {
        modalContext.open('create-universe',
            <CreateUniverseModal
                onUniverseCreation={updateOntologies}
                onClose={() => modalContext.close('create-universe')}
            />,
        );
    }, [modalContext, updateOntologies]);

    return (
        <div className={classNames('&', className)}>
            <ArgInputSearch
                className={classNames('&-search')}
                placeholder={messages.searchPlaceholder}
                onInputChange={handleSearch}
            />
            <ArgButton
                size='medium'
                type='primary'
                icon='icon-plus'
                className={classNames('&-item')}
                label={messages.newUniverse}
                onClick={handleCreateUniverse}
            />
        </div>
    );
}
