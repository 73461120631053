import { useCallback } from 'react';
import { defineMessages } from 'react-intl';

import { ArgCheckboxMinus } from '../arg-checkbox/arg-checkbox';
import { SelectionProvider, SelectionSource } from '../arg-providers/selection-provider';
import { ArgTableRowState, DataProvider } from '../arg-providers/data-provider';
import { ArgRenderedText } from '../types';


const messages = defineMessages({
    allDataAreNotLoaded: {
        id: 'common.basic.arg-table4.selection-all-cell.AllDataAreNotLoaded',
        defaultMessage: 'Selection is impossible because not all data has been loaded.',
    },
    unselectAll: {
        id: 'common.basic.arg-table4.selection-all-cell.UnselectAll',
        defaultMessage: 'Unselect all',
    },
    selectAll: {
        id: 'common.basic.arg-table4.selection-all-cell.SelectAll',
        defaultMessage: 'Select all',
    },
});

interface SelectionAllCellProps<T> {
    selectionProvider: SelectionProvider<T>;
    onAllSelectionChange?: (newState: boolean, selectionProvider: SelectionProvider<T>) => void;
    dataProvider: DataProvider<T>;
    selectionSource?: SelectionSource;
}

export function SelectionAllCell<T>(props: SelectionAllCellProps<T>) {
    const {
        selectionProvider,
        dataProvider,
        onAllSelectionChange,
        selectionSource,
    } = props;

    let tooltip:ArgRenderedText = null;

    const checked = (selectionProvider.count === dataProvider.rowCount)
        ? true
        : (selectionProvider.count)
            ? 'minus'
            : false;

    if (checked) {
        tooltip = messages.unselectAll;
    } else {
        tooltip = messages.selectAll;
    }

    let disabled = false;//(!dataProvider.hasAllRows || dataProvider.rowCount === undefined);
    if (!dataProvider.hasAllRows || dataProvider.rowCount === undefined) {
        if (checked === false) {
            tooltip = messages.allDataAreNotLoaded;
            disabled = true;
        }
    }

    const handleOnChange = useCallback((checked: boolean|'minus') => {
        if (onAllSelectionChange) {
            onAllSelectionChange(checked === true, selectionProvider);

            return;
        }
        if (checked !== true) {
            selectionProvider.clear(selectionSource || 'selection-all-cell');

            return;
        }

        const rowCount = dataProvider.rowCount;
        if (rowCount === undefined) {
            throw new Error('RowCount is undefined');
        }

        const selection:T[] = new Array(rowCount);

        for (let i = 0; i < rowCount; i++) {
            const rowValue = dataProvider.getRow(i);

            if (rowValue === ArgTableRowState.Loading || rowValue === ArgTableRowState.Error || rowValue === ArgTableRowState.NotFound) {
                continue;
            }

            selection.push(rowValue);
        }

        selectionProvider.set(selection, selectionSource || 'arg-table4:select-all');
    }, [dataProvider, onAllSelectionChange, selectionProvider, selectionSource]);

    return (
        <ArgCheckboxMinus
            disabled={disabled}
            size='node'
            value={checked}
            onChange={handleOnChange}
            tooltip={tooltip}
        />
    );
}
