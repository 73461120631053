import { isNumber } from 'lodash';

import { DataFilter, DataSorter, PagedDataProvider, ProgressMonitor, ProgressMonitorOptions, ProgressMonitorsFactoryType } from '../../../components/basic';
import ExtensionConnector from '../../connectors/extensions-connector';
import { Extension } from '../../models/extension';
import { ArgonosModule } from '../../../components/application/modules';

const PAGE_ROW_COUNT = 50;
export class ExtensionsDataProvider extends PagedDataProvider<Extension> {
    #argonosModule: ArgonosModule | undefined;

    constructor(
        argonosModule: ArgonosModule | undefined,
        errorHandler: (error: Error) => void,
        progressMonitorsFactory?: ProgressMonitorsFactoryType,
        progressMonitorOptions?: ProgressMonitorOptions,
        loadPageTimeout?: number) {
        super(PAGE_ROW_COUNT, progressMonitorsFactory, progressMonitorOptions, loadPageTimeout, errorHandler);

        this.#argonosModule = argonosModule;
    }

    protected async loadPageContent(
        pageIndex: number,
        pageSize: number,
        filter: DataFilter | undefined,
        sorter: DataSorter | undefined,
        progressMonitor: ProgressMonitor): Promise<Extension[]> {
        const ret = await ExtensionConnector.getInstance().getExtensions(
            this.searchTerm,
            pageIndex * this.pageRowCount,
            pageSize,
            sorter?.propertySorters[0]?.propertyName,
            sorter?.propertySorters?.[0]?.order,
            this.#argonosModule,
            progressMonitor,
        );

        this.totalRowCount = ret.total;

        return ret.data;
    }

    protected get totalRowCountMayVary(): boolean {
        return true;
    }
}
