import { cloneDeep, set } from 'lodash';
import { useEffect, useMemo } from 'react';
import { defineMessages, FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';

import { ArgCombo, useClassNames } from 'src/components/basic';
import { stringSorter } from '../../../../../utils/sorter';
import { DropDownOption, RuleTarget, SchemaObject } from '../../../../models/policy';
import { ROW_HEIGHT, isSchemaObject } from '../../policy-utils';
import { ReadOnlyFilterInput } from '../read-only-filter-input/read-only-filter-input';

import './universe-item-filter.less';

interface UniverseItemFilterProps<T> {
    object: SchemaObject;
    currentPath: string;
    dropdownOpts: DropDownOption[];
    pathOfParentFilter?: string;
    onChange: React.Dispatch<React.SetStateAction<T>>;
    editable: boolean;
    onChangeItemFilter?: (schema: SchemaObject) => void;
    externalSchemaObject: SchemaObject | undefined;
}

const messages = defineMessages({
    for: {
        id: 'settings.policy-universe-type-filter.for',
        defaultMessage: 'On',
    },
    object: {
        id: 'settings.policy-universe-type-filter.object',
        defaultMessage: 'Object',
    },
    relation: {
        id: 'settings.policy-universe-type-filter.relation',
        defaultMessage: 'Relation',
    },
    select: {
        id: 'settings.policy-universe-type-filter.select',
        defaultMessage: 'Select',
    },
    allKinds: {
        id: 'settings.policy-universe-type-filter.allKinds',
        defaultMessage: 'All kinds',
    },
    allTypes: {
        id: 'settings.policy-universe-type-filter.allTypes',
        defaultMessage: 'All types',
    },
});

interface Kind {
    key: string;
    _kind: string | undefined;
    label: MessageDescriptor;
}

const KINDS: Kind[] = [
    {
        key: 'all',
        _kind: undefined,
        label: messages.allKinds,
    },
    {
        key: 'Vertex',
        _kind: 'Vertex',
        label: messages.object,
    },
    {
        key: 'Edge',
        _kind: 'Edge',
        label: messages.relation,
    },
];

interface Type {
    name: string | MessageDescriptor;
    value: string | undefined;
    key: string;
}

export function UniverseItemFilter<T extends { Targets: RuleTarget[] }>({
    object,
    currentPath,
    dropdownOpts,
    pathOfParentFilter,
    onChange,
    onChangeItemFilter,
    editable,
    externalSchemaObject,
}: UniverseItemFilterProps<T>) {
    const classNames = useClassNames('settings-universe-item-filter');
    const intl = useIntl();

    const types = useMemo<Type[]>(() => {
        const ret: Type[] = [...dropdownOpts].sort((a, b) => stringSorter<DropDownOption>(a, b, item => item.displayName)).map((option) => ({
            name: option.displayName,
            value: option.name,
            key: `property:${option.name}`,
        }));

        if (!externalSchemaObject?._type) {
            ret.unshift({
                name: messages.allTypes,
                value: undefined,
                key: 'all',
            });
        }

        return ret;
    }, [dropdownOpts, externalSchemaObject]);

    // If the dropdown options change (e.g. if the _kind filter has changed) and the _type is not within the new list. Set it to undefined
    useEffect(() => {
        if (isSchemaObject(object) && object._type && !dropdownOpts.map(option => option.name).includes(object._type)) {
            const schemaObject: SchemaObject = {
                _kind: object._kind as 'Vertex' | 'Edge' | undefined,
                _type: undefined,
            };
            onChange((currentRule) => {
                // When resetting the _type field, if there is a property filter as well (e.g. currentPath ends with and[0]), we want to remove it by getting rid of the parent `and`
                return set(cloneDeep(currentRule), pathOfParentFilter || currentPath, {
                    object: schemaObject,
                });
            });
            onChangeItemFilter?.(schemaObject);
        }
    }, [dropdownOpts, object._type, currentPath, object._kind, onChange, pathOfParentFilter, onChangeItemFilter, object]);

    const isKindEditable = editable && !externalSchemaObject?._kind;
    const typeValue = types.find((t) => (t.value === object._type));

    return (
        <div className={classNames('&-object-form-container')} style={{ height: ROW_HEIGHT }}>
            <div className={classNames('&-for-text')}>{intl.formatMessage(messages.for)}</div>
            {isKindEditable ? (
                <ArgCombo<Kind>
                    className={classNames('&-kind-field')}
                    placeholder={messages.select}
                    popoverClassName='arg-input-popover-no-max-width'
                    size='small'
                    items={KINDS}
                    value={KINDS.find((k) => k._kind === object._kind)}
                    getItemLabel='label'
                    getItemKey='key'
                    cardinality='one'
                    onChange={(value) => {
                        const schemaObject: SchemaObject = {
                            _kind: value._kind,
                            _type: undefined,
                        };
                        onChange((currentRule) => {
                            return set(cloneDeep(currentRule), `${currentPath}.object`, schemaObject);
                        });
                        onChangeItemFilter?.(schemaObject);
                    }}
                />
            ) : (
                <ReadOnlyFilterInput
                    className={classNames('&-kind-field')}
                    value={KINDS.find((k) => k._kind === object._kind)?.label}
                />
            )}
            {object._kind &&
                (editable ? (
                    <ArgCombo<Type>
                        className={classNames('&-type-field')}
                        placeholder={messages.select}
                        popoverClassName='arg-input-popover-no-max-width'
                        items={types}
                        value={typeValue}
                        getItemKey='key'
                        getItemLabel='name'
                        cardinality='one'
                        size='small'
                        onChange={(value: Type) => {
                            const schemaObject: SchemaObject = {
                                _kind: object._kind as 'Vertex' | 'Edge' | undefined,
                                _type: value.value,
                            };
                            onChange((currentPolicy) => {
                                return set(cloneDeep(currentPolicy), `${currentPath}.object`, schemaObject);
                            });
                            onChangeItemFilter?.(schemaObject);
                        }}
                        enableFilter={true}
                    />
                ) : (
                    <ReadOnlyFilterInput
                        className={classNames('&-type-field')}
                        value={typeValue?.name || object._type || <FormattedMessage {...messages.allTypes} />}
                    />
                ))}
        </div>
    );
}
