import React, { ReactNode } from 'react';
import { last } from 'lodash';

import { ClassValue, highlightSplit, ThreeDotsLoading, useClassNames } from '../../basic';

import './selection-count.less';

export interface SelectionCountProps {
    path?: any;
    total: ReactNode;
    count: ReactNode;
    barClassNames: ClassValue;
    bgColor: string | undefined;
    hideBar: boolean | undefined;
    isLoading: boolean | undefined;
    querySearch: string | undefined;
    countPercent: number | undefined;
}

export function SelectionCount(props: SelectionCountProps) {
    const { count, total, countPercent, hideBar, path, querySearch, isLoading, bgColor, barClassNames } = props;

    const classNames = useClassNames('selection-count');
    //under 0.15% the width is not visible on some navigator then the 0.2% is a minimum
    const countPercentValue = typeof (countPercent) === 'number' ? `${Math.min(100, countPercent > 0.2 ? countPercent : 0.2)}%` : 0;

    return (
        <div className={classNames('&')}>
            {path && (
                <div className={classNames('&-node-label')}>
                    {path && (
                        <span className={classNames('&-node-label-name')}>
                            {highlightSplit((last(path) as any).name, querySearch)}
                        </span>
                    )}
                    {isLoading &&
                        <div className={classNames('&-node-count', '&-node-count-loading')}>
                            <ThreeDotsLoading />
                        </div>}
                </div>
            )}
            {!isLoading && (count || total) && <div className={classNames('&-node-count')}>
                {count && <div className={classNames('&-node-count-count')}>
                    {count}
                </div>}
                {count && total && <div className={classNames('&-node-count-separator')}>{'/'}</div>}
                {total &&
                    <div className={classNames('&-node-count-total')}>
                        {total}
                    </div>}
                <div className={classNames('&-node-count-bar')} style={{
                    color: bgColor,
                }}>
                    <div className={classNames('&-node-count-bar-bg')} />
                    {!hideBar && (

                        <div className={classNames('&-node-count-bar-inner', barClassNames)} style={{
                            width: countPercentValue,
                            visibility: (!hideBar && typeof (countPercent) === 'number') ? 'visible' : 'hidden',
                        }} />
                    )}
                </div>
            </div>}
        </div>
    );
}
