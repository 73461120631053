import { useEffect } from 'react';
import Debug from 'debug';
import { first } from 'lodash';

import { ProgressMonitor, SubProgressMonitor } from '../basic';
import { Connector } from '../../utils/connector';
import { ArgonosModule } from '../application/modules';
import { ArgonosModulesRegistry } from '../application/argonos-modules-registry';
import { useCurrentArgonosModule } from '../application/argonos-current-module';


const debug = Debug('common:caches:PagesAccess');

const LAST_EXECUTED_MODULE_ID = 'ARG_LAST_EXECUTED_MODULE_ID';
const CURRENT_MODULE_ID = 'ARG_CURRENT_MODULE_ID';
const TRY_PAGE_ACCESS_PROPERTY = 'ARG_PAGE_ACCESS_TRY';

export function useRecordPageAccess() {
    const argonosModule = useCurrentArgonosModule();

    useEffect(() => {
        debug('useRecordPageAccess', 'Record page', argonosModule);
        if (!argonosModule) {
            window.localStorage?.removeItem(CURRENT_MODULE_ID);

            return;
        }
        window.localStorage?.setItem(LAST_EXECUTED_MODULE_ID, argonosModule.id);
        window.localStorage?.setItem(CURRENT_MODULE_ID, argonosModule.id);
    }, [argonosModule]);
}

export function getLastExecutedModule(): ArgonosModule | undefined {
    const path = window.localStorage.getItem(LAST_EXECUTED_MODULE_ID);
    if (path === null) {
        return undefined;
    }

    const argonosModule = ArgonosModulesRegistry.getInstance().getById(path);
    if (argonosModule?.enabled === false) {
        return undefined;
    }

    return argonosModule;
}

export function authorizedPageAccessed() {
    debug('authorizedPageAccessed');
    window.localStorage.removeItem(TRY_PAGE_ACCESS_PROPERTY);
}

export function unauthorizedPageAccessed() {
    debug('unauthorizedPageAccessed');
    // The page is no longer accessible
    window.localStorage.removeItem(CURRENT_MODULE_ID);
}

export async function computePageAccess(progressMonitor: ProgressMonitor): Promise<ArgonosModule | undefined> {
    const argonosModules = ArgonosModulesRegistry.getInstance().listEnabled().value();

    debug('computePageAccess', 'argonosModules=', argonosModules);

    if (!window.localStorage.getItem(TRY_PAGE_ACCESS_PROPERTY)) {
        window.localStorage.setItem(TRY_PAGE_ACCESS_PROPERTY, 'true');

        const argonosModule = getLastExecutedModule();
        debug('computePageAccess', 'recordPageAccess=', argonosModule?.id);
        if (argonosModule?.routeURL) {
            return argonosModule;
        }
    }

    window.localStorage.setItem(TRY_PAGE_ACCESS_PROPERTY, 'true');

    const result: ArgonosModule[] = [];

    const ps = argonosModules.map((argonosModule:ArgonosModule) => {
        const { testURL, apiURL, routeURL } = argonosModule;

        debug('computePageAccess', 'validated URL=', testURL, 'for module', argonosModule.id);

        progressMonitor.verifyCancelled();

        if (!testURL || !routeURL) {
            return false;
        }

        if (!apiURL) {
            console.warn('API is not defined for test=', test);

            return false;
        }

        debug('computePageAccess', 'test URL=', argonosModule.testURL);
        const sub1 = new SubProgressMonitor(progressMonitor, 1);
        let ps = Connector.getInstance().request(testURL, {
            verifyJSONResponse: true,
            noRedirectIfUnauthorized: true,
            api: apiURL,
        }, sub1);

        ps = ps.then(() => {
            result.push(argonosModule);
        }, (error) => {
            console.error(error);
        });

        return ps;
    });

    await Promise.all(ps);

    window.localStorage.removeItem(TRY_PAGE_ACCESS_PROPERTY);

    result.sort((m1, m2) => {
        const order1 = m1.loginOrder ?? 0;
        const order2 = m2.loginOrder ?? 0;

        return order2 - order1;
    });

    return first(result);
}
