import { isEmpty } from 'lodash';
import React, { ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import { defineMessages } from 'react-intl';
import { useParams } from 'react-router';

import {
    ArgButton,
    ArgDragAndDropUploader,
    ArgImage,
    ArgInputSearch,
    ArgMessageRenderer,
    ArgTable4,
    ArgTable4Column,
    ArgTooltip2,
    ArgUploaderButton,
    ClassValue,
    highlightSplit,
    ProgressMonitor,
    SelectionProvider,
    SubProgressMonitor,
    useArgNotifications,
    useClassNames,
    useSelectionList,
} from 'src/components/basic';
import { ArgonosModuleId } from '../../../components/application/modules';
import { ArgonosModulesRegistry } from '../../../components/application/argonos-modules-registry';
import { isResponse409 } from '../../../components/basic/utils/response-error';
import { DateByUser } from '../../../components/common/date-by-user';
import { EmptyPane } from '../../../components/common/panes/empty-pane';
import { LoadingPane } from '../../../components/common/panes/loading-pane';
import { PageHeader } from '../../common-components/page-header';
import ExtensionConnector from '../../connectors/extensions-connector';
import { Extension, ExtensionCreationOptions } from '../../models/extension';
import { ExtensionActionMenu } from '../components/extension-action-menu';
import { ExtensionActionsButtonMenu } from '../components/extension-actions-button-menu';
import { ExtensionCardItemsList } from '../components/extension-card-items-list';
import { ExtensionErrorNotificationDescription } from '../components/extension-error-notification-description';
import { ExtensionInformationPanel } from '../components/extension-information-panel';
import { ExtensionsDataProvider } from '../providers/extensions-data-provider';
import { useHasPermission } from '../../../contexts/user-permission';
import { SettingsPermissions } from '../../permissions/permissions';
import { ErrorPane } from '../../../components/common/panes/error-pane';

import './extensions-view.less';

const CLASSNAME = 'settings-extensions-view';

const SETTINGS_EXTENSION_TABLE_SELECTION_SOURCE = 'settings-extension-table';

const SUPPORTED_IMPORT_MIME_TYPES = [
    'application/x-zip-compressed', 'application/zip',
];

const FORCE_ERROR = false;

const messages = defineMessages({
    extensionsTitle: {
        id: 'settings.extensions-view.extensionsTitle',
        defaultMessage: 'Extensions',
    },
    emptyExtensions: {
        id: 'settings.extensions-view.emptyExtensions',
        defaultMessage: 'No extensions',
    },
    searchPlaceholder: {
        id: 'settings.extensions-view.searchPlaceholder',
        defaultMessage: 'Search',
    },
    name: {
        id: 'settings.extensions-view.name',
        defaultMessage: 'Name',
    },
    description: {
        id: 'settings.extensions-view.description',
        defaultMessage: 'Description',
    },
    active: {
        id: 'settings.extensions-view.active',
        defaultMessage: 'Active',
    },
    inactive: {
        id: 'settings.extensions-view.inactive',
        defaultMessage: 'Inactive',
    },
    status: {
        id: 'settings.extensions-view.status',
        defaultMessage: 'Status',
    },
    modification: {
        id: 'settings.extensions-view.modification',
        defaultMessage: 'Modification',
    },
    publishedDate: {
        id: 'settings.extensions-view.publishedDate',
        defaultMessage: 'Last publication',
    },
    creation: {
        id: 'settings.extensions-view.creation',
        defaultMessage: 'Creation',
    },
    delete: {
        id: 'settings.extensions-view.delete',
        defaultMessage: 'Delete',
    },
    viewTypeTileTooltip: {
        id: 'settings.extensions-view.viewTypeTileTooltip',
        defaultMessage: 'Display in tile format',
    },
    viewTypeTableTooltip: {
        id: 'settings.extensions-view.viewTypeTableTooltip',
        defaultMessage: 'Display in table format',
    },
    addExtension: {
        id: 'settings.extensions-view.addExtension',
        defaultMessage: 'Add an extension',
    },
    invalidType: {
        id: 'settings.extensions-view.InvalidType',
        defaultMessage: 'This format is not supported. Only zip/gzip files are supported',
    },
    dropConfigurationMessage: {
        id: 'settings.extensions-view.dropConfigurationMessage',
        defaultMessage: 'Drag and drop a .zip or .gzip file',
    },
    extensionAdded: {
        id: 'settings.extensions-view.extensionAdded',
        defaultMessage: 'Extension added',
    },
    addExtensionSuccess: {
        id: 'settings.extensions-view.addExtensionSuccess',
        defaultMessage: 'The extension was successfully added',
    },
    addExtensionError: {
        id: 'settings.extensions-view.addExtensionError',
        defaultMessage: 'Cannot add extension',
    },
    addExtensionDescriptionError: {
        id: 'settings.extensions-view.addExtensionDescriptionError',
        defaultMessage: '{count, plural, =1 {1 error detected } other {{count} errors detected}}',
    },
    nameDuplicateError: {
        id: 'settings.extensions-view.nameDuplicateError',
        defaultMessage: 'An extension with the same name already exists',
    },
    nameDuplicateDescriptionError: {
        id: 'settings.extensions-view.nameDuplicateDescriptionError',
        defaultMessage: 'An extension with the same name already exists. Do you want to overwrite the extension with the same name ?',
    },
    overwriteExtension: {
        id: 'settings.extensions-view.overwriteExtension',
        defaultMessage: 'Overwrite',
    },
    loadingExtensionsError: {
        id: 'settings.extensions-view.loadingExtensionsError',
        defaultMessage: 'An error occurred while loading the extensions',
    },
});

export enum PageHeadersButtons {
    INFO = 'info',
}

enum ViewType {
    TABLE = 'table',
    TILE = 'tile',
}

export interface ExtensionsViewProps {
    className?: ClassValue;
}

export function ExtensionsView(props: ExtensionsViewProps) {
    const {
        className,
    } = props;
    const classNames = useClassNames(CLASSNAME);

    const [viewType, setViewType] = useState<ViewType>(ViewType.TABLE);
    const [search, setSearch] = useState<string>();
    const [buttonSelected, setButtonSelected] = useState<string>();

    const notifications = useArgNotifications();

    const bodyRef = useRef<HTMLDivElement>(null);

    const { argonosModule: argonosModuleId } = useParams<{ argonosModule: ArgonosModuleId }>();

    const allowImportExtension = useHasPermission<SettingsPermissions>('admin.moduleExtensions.edition');

    const argonosModule = argonosModuleId ? ArgonosModulesRegistry.getInstance().getById(argonosModuleId) : undefined;
    const [extensionsProviderError, setExtensionsProviderError] = useState<Error>();

    const extensionProviderErrorHandler = useCallback((error: Error) => {
        notifications.snackError({ message: messages.loadingExtensionsError }, error);

        setExtensionsProviderError(error);
    }, [notifications]);

    const dataProviderRef = useRef<ExtensionsDataProvider>(new ExtensionsDataProvider(argonosModule, extensionProviderErrorHandler));
    const dataProvider = dataProviderRef.current;

    const extensionsSelectionProvider = useMemo(
        () => new SelectionProvider<Extension>(SETTINGS_EXTENSION_TABLE_SELECTION_SOURCE, (extension) => extension.name),
        [],
    );

    const handleSearch = useCallback((value: string) => {
        setSearch(value);
    }, []);

    const handleChangeViewType = useCallback((value: ViewType) => {
        setViewType(value);
    }, []);

    const handleRefresh = useCallback(() => {
        dataProvider.refreshPages();
        extensionsSelectionProvider.clear('clearAll');
    }, [dataProvider, extensionsSelectionProvider]);

    const importExtension = useCallback(async (blob: Blob, progressMonitor: ProgressMonitor, extensionCreationOptions?: ExtensionCreationOptions) => {
        if (!blob || !SUPPORTED_IMPORT_MIME_TYPES.includes(blob.type)) {
            notifications.snackError({ message: messages.invalidType });

            return;
        }

        try {
            const result = await ExtensionConnector.getInstance().importExtension(
                blob,
                argonosModule,
                extensionCreationOptions,
                progressMonitor,
            );

            if (isEmpty(result) || !result.errors) {
                notifications.snackSuccess({ message: messages.extensionAdded, description: messages.addExtensionSuccess });
                handleRefresh();

                return;
            }
            const nbImportErrors = result.errors.length;

            notifications.notifError(
                {
                    message: messages.addExtensionError,
                    description: messages.addExtensionDescriptionError,
                    details: (
                        <ExtensionErrorNotificationDescription
                            errors={result.errors}
                        />
                    ),
                },
                undefined,
                {
                    count: nbImportErrors,
                });
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            if (isResponse409(error)) {
                const sub1 = new SubProgressMonitor(progressMonitor, 1);
                notifications.notifWarning(
                    {
                        message: messages.nameDuplicateError,
                        description: messages.nameDuplicateDescriptionError,
                        buttonLabel: messages.overwriteExtension,
                        onClick: () => importExtension(blob, sub1, { overwrite: true, keepState: true }),
                    },
                );

                return;
            }
            notifications.snackError({ message: messages.addExtensionError }, error as Error);
            throw error;
        }
    }, [notifications, argonosModule, handleRefresh]);

    const getDisplayedElements = useCallback((): Extension[] => {
        const elements: Extension[] = [];
        for (let row = 0; row < (dataProvider.rowCount || 0); row++) {
            const dataRow = dataProvider.getRow(row) as Extension;
            if (dataRow.name) {
                elements.push(dataRow);
            }
        }

        return elements;
    }, [dataProvider]);

    const [selectedExtensionsNames] = useSelectionList(extensionsSelectionProvider);

    const selectedExtensions = useMemo(() => {
        const extensions = getDisplayedElements();

        return extensions.filter((extension) => selectedExtensionsNames.includes(extension.name));
    }, [selectedExtensionsNames, getDisplayedElements]);

    const columns = useMemo<ArgTable4Column<Extension>[]>(() => {
        const columns: ArgTable4Column<Extension>[] = [
            {
                key: 'icon',
                columnName: '',
                dataIndex: 'metadata.icon',
                sortable: false,
                width: 60,
                maxWidth: 60,
                render: function display(data: string, extension: Extension) {
                    return <ArgImage src={extension.metadata.icon} />;
                },
            },
            {
                key: 'name',
                columnName: 'Name',
                columnSortName: 'Name',
                title: messages.name,
                dataIndex: 'name',
                sorter: 'fromServer',
                width: 350,
                maxWidth: 350,
                render: function display(data: string, extension: Extension, _index: number | undefined, search?: string | null) {
                    return highlightSplit(extension.name, search);
                },
            },
            {
                key: 'description',
                columnName: 'Description',
                title: messages.description,
                dataIndex: 'metadata.description',
                columnSortName: 'Description',
                sorter: 'fromServer',
                ellipsis: true,
                width: 350,
                maxWidth: 350,
                render: function display(data: string, extension: Extension, _index: number | undefined, search?: string | null) {
                    const description = extension.metadata?.description;
                    const descriptionLabel = search && description ?
                        highlightSplit(description, search) : description;

                    return <ArgTooltip2 title={descriptionLabel}>{descriptionLabel}</ArgTooltip2>;
                },
            },
            {
                key: 'createdDate',
                columnName: 'Creation Date',
                title: messages.creation,
                sorter: 'fromServer',
                columnSortName: 'CreatedDate',
                dataIndex: 'createdDate',
                ellipsis: true,
                width: 200,
                maxWidth: 200,
                render: function LastUpdate(date: Date, extension: Extension) {
                    return (
                        <DateByUser
                            date={date}
                            relative={true}
                            user={extension.createdBy}
                        />
                    );
                },
            },
            {
                key: 'lastUpdatedDate',
                columnName: 'Last Updated Date',
                title: messages.modification,
                sorter: 'fromServer',
                columnSortName: 'LastUpdatedDate',
                dataIndex: 'lastUpdatedDate',
                width: 200,
                maxWidth: 200,
                ellipsis: true,
                render: function LastUpdate(date: Date, extension: Extension) {
                    return (
                        <DateByUser
                            date={date}
                            relative={true}
                            user={extension.lastUpdatedBy}
                        />
                    );
                },
                defaultSortOrder: 'descend',
            },
            {
                key: 'lastPublishedDate',
                columnName: 'Last Published Date',
                title: messages.publishedDate,
                sorter: 'fromServer',
                columnSortName: 'LastPublishedDate',
                dataIndex: 'lastPublishedDate',
                width: 200,
                maxWidth: 200,
                ellipsis: true,
                render: function LastUpdate(date: Date, extension: Extension) {
                    return (
                        <DateByUser
                            date={date}
                            relative={true}
                            user={extension.lastPublishedBy}
                        />
                    );
                },
            },
            {
                key: 'state',
                columnName: 'Status',
                title: messages.status,
                resizable: true,
                sorter: 'fromServer',
                columnSortName: 'IsEnabled',
                width: 50,
                maxWidth: 50,
                dataIndex: 'state.enabled',
                render: function display(state) {
                    return (
                        <span>
                            {state
                                ? <ArgMessageRenderer size='small' message={messages.active} />
                                : <ArgMessageRenderer size='small' message={messages.inactive} />}
                        </span>
                    );
                },
            },
            {
                key: 'action-menu',
                columnName: '',
                title: '',
                width: 50,
                maxWidth: 50,
                resizable: false,
                dataIndex: 'id',
                render: function dataRow(id, extension) {
                    return (
                        <ExtensionActionMenu
                            extension={extension}
                            argonosModule={argonosModule}
                            onSuccess={handleRefresh}
                        />
                    );
                },
            },
        ];

        return columns;
    }, [argonosModule, handleRefresh]);

    const toolbar = useMemo(() => {
        return (
            <div className={classNames('&-toolbar')}>
                <div className={classNames('&-toolbar-left')}>
                    <ArgInputSearch
                        onInputChange={handleSearch}
                        placeholder={messages.searchPlaceholder}
                        className={classNames('&-toolbar-search')}
                    />
                    {<ExtensionActionsButtonMenu
                        selectedExtensions={selectedExtensions}
                        argonosModule={argonosModule}
                        onSuccess={handleRefresh}
                    />}
                </div>

                <div className={classNames('&-toolbar-right')}>
                    <ArgButton
                        className={classNames({
                            selected: viewType === ViewType.TILE,
                        })}
                        icon='icon-line-style'
                        size='large'
                        type='ghost'
                        tooltip={messages.viewTypeTileTooltip}
                        onClick={() => handleChangeViewType(ViewType.TILE)}
                    />
                    <ArgButton
                        className={classNames({
                            selected: viewType === ViewType.TABLE,
                        })}
                        icon='icon-line-weight'
                        size='large'
                        type='ghost'
                        tooltip={messages.viewTypeTableTooltip}
                        onClick={() => handleChangeViewType(ViewType.TABLE)}
                    />
                    {allowImportExtension &&
                        <div className={classNames('&-toolbar-right')}>
                            <ArgUploaderButton
                            type='primary'
                            key='upload'
                            size='medium'
                            icon='icon-plus1'
                            method={importExtension}
                            label={messages.addExtension}
                            className={classNames('&-upload-button')}
                            />
                        </div>}
                </div>
            </div>
        );
    }, [classNames, handleSearch, handleRefresh, importExtension, selectedExtensions, argonosModule, handleChangeViewType, allowImportExtension, viewType]);

    const renderExtensions = useCallback(() => {
        let body: ReactNode = null;
        if (!columns) {
            body = (
                <div className='arg-layout'>
                    <LoadingPane
                        className={classNames('&-pane')}
                    />
                </div>
            );
        } else if (viewType === ViewType.TABLE) {
            body = (
                <ArgTable4<Extension>
                    className={classNames('&', 'settings-extensions-view-table')}
                    columns={columns}
                    initialItemsCount={10}
                    headerHeight={45}
                    rowHeight={45}
                    dataProvider={dataProvider}
                    searchValue={search}
                    selectionProvider={extensionsSelectionProvider}
                    selectionSource={SETTINGS_EXTENSION_TABLE_SELECTION_SOURCE}
                    showSelectAllButton={true}
                    adjustColumnsOnFirstDraw={true}
                    emptyRenderer={() => <EmptyPane
                        message={messages.emptyExtensions}
                        className={classNames('&-pane')}
                        size='medium'
                    />}
                />
            );
        } else {
            body = (
                <ExtensionCardItemsList
                    dataProvider={dataProvider}
                    argonosModule={argonosModule}
                    bodyRef={bodyRef}
                    search={search}
                    selectionProvider={extensionsSelectionProvider}
                    handleRefresh={handleRefresh}
                />
            );
        }

        return (
            <React.Fragment>
                {toolbar}
                <div ref={bodyRef} className={classNames('&-body', viewType)}>
                    {body}
                </div>
            </React.Fragment>
        );
    }, [
        classNames,
        viewType,
        search,
        toolbar,
        extensionsSelectionProvider,
        columns,
        dataProvider,
        handleRefresh,
        argonosModule,
    ]);

    if (FORCE_ERROR || extensionsProviderError) {
        return <div className={classNames('&', 'error')}>
            <ErrorPane className={classNames('&-error')} error={extensionsProviderError} />
        </div>;
    }

    return (
        <ArgDragAndDropUploader
            method={importExtension}
            className={classNames(className, '&', '& &-uploader')}
            dropMessage={messages.dropConfigurationMessage}
            disabled={!allowImportExtension}
        >
            <PageHeader
                title={messages.extensionsTitle}
                selectedItem={buttonSelected || ''}
                setSelectedItem={setButtonSelected}
                buttons={[{ key: PageHeadersButtons.INFO, icon: 'icon-information-outline' }]}
            />
            <div className={classNames('&-content')}>
                <div className={classNames('&-main-view')}>
                    {renderExtensions()}
                </div>
                {buttonSelected === PageHeadersButtons.INFO && (
                    <div className={classNames('&-extension-panel')}>
                        <ExtensionInformationPanel
                            selectedExtensions={selectedExtensions}
                        />
                    </div>
                )}
            </div>

        </ArgDragAndDropUploader>
    );
}
