import React, { useCallback, useEffect, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { defaultTo, isEqual, isNil, size } from 'lodash';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { ControlProps } from './controls-type';
import { RangeDataModel } from './range/range';
import { DateRangePickerWithHistogram } from './range/date-range-picker-with-histogram';
import { RangePickerControlHistogram } from './range/range-picker-control-histogram';
import { ArgButtonGroup, ArgChangeReason, ArgCombo, dayjs, useClassNames } from '../../basic';
import { AddField } from './add-property-field';
import { UndefinedButton } from './undefined-button';
import { internalisationDateFormat } from '../../../utils/dates/internalisation-date-format';

import './date-picker-control.less';

const TRANSITION_TIMEOUT = 200;

const messages = defineMessages({
    selectPresets: {
        id: 'common.controls.datepicker.SelectPlaceholder',
        defaultMessage: 'Select a preset',
    },
    customDate: {
        id: 'common.controls.datepicker.customDate',
        defaultMessage: 'Custom',
    },
    presetDate: {
        id: 'common.controls.datepicker.PresetDate',
        defaultMessage: 'Preset',
    },
    todayLabel: {
        id: 'common.controls.datePicker.TodayLabel',
        defaultMessage: 'Today',
    },
    thisMonthLabel: {
        id: 'common.controls.datePicker.ThisMonthLabel',
        defaultMessage: 'This month',
    },
    thisYearLabel: {
        id: 'common.controls.datePicker.ThisYearLabel',
        defaultMessage: 'This year',
    },
    firstDateLabel: {
        id: 'common.controls.datePicker.FirstDateLabel',
        defaultMessage: 'Min',
    },
    lastDateLabel: {
        id: 'common.controls.datePicker.LastDateLabel',
        defaultMessage: 'Max',
    },
    LTM: {
        id: 'common.controls.datePicker.LTM',
        defaultMessage: 'Last Twelve Months',
    },
    MTD: {
        id: 'common.controls.datePicker.MTD',
        defaultMessage: 'Month To Date',
    },
    QTD: {
        id: 'common.controls.datePicker.QTD',
        defaultMessage: 'Quarter To Date',
    },
    YTD: {
        id: 'common.controls.datePicker.YTD',
        defaultMessage: 'Year To Date',
    },
    NoPresets: {
        id: 'common.controls.datePicker.NoPreset',
        defaultMessage: 'No available presets',
    },
    LTM_ABREV: {
        id: 'common.controls.datePicker.LTM_ABREV',
        defaultMessage: 'LTM',
    },
    MTD_ABREV: {
        id: 'common.controls.datePicker.MTD_ABREV',
        defaultMessage: 'MTD',
    },
    QTD_ABREV: {
        id: 'common.controls.datePicker.QTD_ABREV',
        defaultMessage: 'QTD',
    },
    YTD_ABREV: {
        id: 'common.controls.datePicker.YTD_ABREV',
        defaultMessage: 'YTD',
    },
});

const selectList: DateRangePresets[] = ['LTM', 'MTD', 'YTD', 'QTD'];

const CLEAR_CUSTOM: RangeDataModel<dayjs.Dayjs> = {
    lowerBound: {
        included: true,
        value: undefined,
    },
    upperBound: {
        included: true,
        value: undefined,
    },
};

export type DateRangePresets = 'MTD' | 'LTM' | 'QTD' | 'YTD';

export type DatePickerMode = 'preset' | 'custom';

export interface DatePickerDataType {
    mode?: DatePickerMode;
    preset?: DateRangePresets;
    ranges?: RangeDataModel<dayjs.Dayjs>[];
    includeUndefined?: boolean;
}

interface DatePickerControlProps extends ControlProps<DatePickerDataType> {
    defaultMode?: DatePickerMode;
}

export function DatePickerControl(props: DatePickerControlProps) {
    const {
        className,
        value,
        defaultMode = 'custom',
        onChange,
        propertyInfo,
        onReset,
        showUndefined,
        acceptsParameters,
        readOnly,
    } = props;

    const classNames = useClassNames('arg-datePickerControl');

    const intl = useIntl();

    const handleUndefinedButtonChange = useCallback((newValue: boolean) => {
        onChange((prev) => ({
            ...prev,
            includeUndefined: newValue,
        }), 'selection');
    }, [onChange]);

    const dateFormat = () => {
        const dateFormat = internalisationDateFormat(intl);

        return dateFormat;
    };

    const [minValue, maxValue] = useMemo<[dayjs.Dayjs | undefined, dayjs.Dayjs | undefined]>(() => {
        let minValue = undefined;
        let maxValue = undefined;

        if (propertyInfo?.minValue) {
            minValue = dayjs.utc(propertyInfo?.minValue);
        }
        if (propertyInfo?.maxValue) {
            maxValue = dayjs.utc(propertyInfo?.maxValue);
        }

        return [minValue, maxValue];
    }, [propertyInfo]);

    useEffect(() => {
        if (minValue === undefined || maxValue === undefined || !isNil(value)) {
            return;
        }

        onChange(prev => ({
            ...prev,
            ranges: [{
                lowerBound: {
                    included: true,
                    value: minValue,
                },
                upperBound: {
                    included: true,
                    value: maxValue,
                },
            }],
        }), 'init');
    }, [minValue, maxValue, value]);

    const handleButtonClick = (mode: 'preset' | 'custom') => (): void => {
        // Turning in closing animation
        onChange((prev) => {
            if (defaultTo(prev?.mode, defaultMode) === mode) { //clikcing on already active button does not trigger this action
                return prev;
            }

            return {
                ...prev,
                mode,
            };
        }, 'selection');
    };

    const handlePresetButtonClick = handleButtonClick('preset');
    const handleCustomButtonClick = handleButtonClick('custom');

    const handlePresetSelectChange = (presets: DateRangePresets | undefined) => {
        if (!presets) {
            return;
        }

        onChange((prev) => {
            return {
                mode: 'preset',
                preset: presets,
                ranges: [CLEAR_CUSTOM],
                includeUndefined: prev?.includeUndefined,
            };
        }, 'selection');
    };

    const handleDateRangeChange = (index: number, setAction: (dateRange: RangeDataModel<dayjs.Dayjs>) => RangeDataModel<dayjs.Dayjs>, reason: ArgChangeReason) => {
        onChange((prev) => {
            if (!prev?.ranges) {
                return prev;
            }

            const prevRange = prev.ranges[index];
            const newRange = setAction(prevRange);

            if (isEqual(prev, newRange)) {
                return prev;
            }

            const newRanges = [...prev.ranges];
            newRanges[index] = newRange;

            return {
                ...prev,
                ranges: newRanges,
                includeUndefined: prev?.includeUndefined,
            };
        }, reason);
    };

    const handleSingleRangePickerDelete = (index: number) => {
        onChange((prev) => {
            if (!prev?.ranges) {
                return prev;
            }
            const newRanges = prev.ranges.filter((range, i) => (index !== i));

            return {
                mode: 'custom',
                ranges: newRanges,
                includeUndefined: prev?.includeUndefined,
            };
        }, 'selection');
    };

    const handleAddAdditionalRangePicker = () => {
        onChange((prev) => {
            if (!prev?.ranges) {
                return prev;
            }

            const newRanges = [...prev.ranges];

            newRanges.push({
                lowerBound: {
                    included: true,
                    value: undefined,
                },
                upperBound: {
                    included: true,
                    value: undefined,
                },
            });

            return {
                mode: 'custom',
                ranges: newRanges,
                includeUndefined: prev?.includeUndefined,
            };
        }, 'selection');
    };

    let datePickerControlContent;

    switch (defaultTo(value?.mode, defaultMode)) {
        case 'preset': {
            const getItemLabel = (item: string) => {
                const label = messages[(item) as keyof typeof messages];

                return label;
            };

            datePickerControlContent = (
                <CSSTransition key='preset' timeout={TRANSITION_TIMEOUT} classNames='arg-universe-filter-anim-item'>
                    <div className={classNames('&-presets-container')}>
                        <ArgCombo<DateRangePresets>
                            size='medium'
                            cardinality='one'
                            className={classNames('&-presets', 'select-date')}
                            data-testid='arg-DatePickerControl-preset-select-test'
                            placeholder={messages.selectPresets}
                            value={value?.preset}
                            items={selectList}
                            onChange={handlePresetSelectChange}
                            getItemLabel={getItemLabel}
                            disabled={readOnly}
                            readOnly={readOnly}
                        />
                        {showUndefined !== false && <div className={classNames('&-presets-undefined')}>
                            <UndefinedButton
                                className={classNames('&-undefined-button')}
                                value={value?.includeUndefined ?? false}
                                onChange={handleUndefinedButtonChange}
                                count={propertyInfo?.numberOfMissing}
                                disabled={readOnly} />
                        </div>}
                    </div>
                </CSSTransition>
            );
        }
            break;

        case 'custom':
            datePickerControlContent = (
                <CSSTransition
                    key='custom'
                    timeout={TRANSITION_TIMEOUT} classNames='arg-universe-filter-anim-item '
                    data-testid='custom-date-test'>
                    <TransitionGroup className={classNames('&-custom', 'arg-universe-filter-anim')}>
                        {propertyInfo?.valueSpread && (
                            <CSSTransition
                                key='histogram'
                                timeout={TRANSITION_TIMEOUT}
                                classNames='arg-universe-filter-anim-item'>
                                <RangePickerControlHistogram
                                    valueSpread={propertyInfo?.valueSpread}
                                    className={classNames('&-custom-histogram', { 'has-multi-ranges': size(value?.ranges) > 1 })} />
                            </CSSTransition>
                        )}

                        {(minValue && maxValue) && (
                            <CSSTransition
                                key='range-bounds'
                                timeout={TRANSITION_TIMEOUT}
                                classNames='arg-universe-filter-anim-item'>
                                <div className={classNames('&-custom-range-bounds')}>
                                    <div
                                        className={classNames('&-custom-range-bounds-lower')}
                                        data-testid='lower-bound-test'>
                                        {minValue.format(dateFormat())}
                                    </div>
                                    <div
                                        className={classNames('&-custom-range-bounds-upper')}
                                        data-testid='upper-bound-test'>
                                        {maxValue.format(dateFormat())}
                                    </div>
                                </div>
                            </CSSTransition>
                        )}

                        {
                            value?.ranges?.map((rangeValue, index: number) => {
                                return (
                                    <CSSTransition
                                        key={index}
                                        timeout={TRANSITION_TIMEOUT}
                                        classNames='arg-universe-filter-anim-item'>
                                        <DateRangePickerWithHistogram
                                            index={index}
                                            canDelete={(value?.ranges?.length ?? 0) > 1}
                                            value={rangeValue}
                                            onReset={onReset}
                                            minValue={minValue}
                                            maxValue={maxValue}
                                            onChange={handleDateRangeChange}
                                            onDelete={handleSingleRangePickerDelete}
                                            acceptsParameters={acceptsParameters}
                                        />
                                    </CSSTransition>
                                );
                            })
                        }

                        <CSSTransition
                            key='add-property'
                            timeout={TRANSITION_TIMEOUT}
                            classNames='arg-universe-filter-anim-item'>
                            <div
                                className={classNames('&-custom-add-property', { 'hide-undefined': showUndefined === false })}>

                                {showUndefined !== false &&
                                    <UndefinedButton
                                        className={classNames('&-undefined-button')}
                                        value={value?.includeUndefined ?? false}
                                        onChange={handleUndefinedButtonChange}
                                        count={propertyInfo?.numberOfMissing}
                                        disabled={readOnly} />}

                                <AddField
                                    className={classNames('&-custom-add-property-button')}
                                    handleAddPropertyField={handleAddAdditionalRangePicker}
                                    disabled={readOnly}
                                />
                            </div>
                        </CSSTransition>
                    </TransitionGroup>
                </CSSTransition>
            );
            break;
    }

    return (
        <>
            <ArgButtonGroup
                className={classNames('&', '&-types', className)}
                data-testid='date-picker-control-custom-dates'
                buttons={[{
                    label: messages.customDate,
                    onClick: handleCustomButtonClick,
                    type: defaultTo(value?.mode, defaultMode) === 'preset' ? 'secondary' : 'primary',
                }, {
                    label: messages.presetDate,
                    onClick: handlePresetButtonClick,
                    type: defaultTo(value?.mode, defaultMode) === 'custom' ? 'secondary' : 'primary',
                }]}
            />
            <TransitionGroup className={classNames('&-type-anim', 'arg-universe-filter-anim')}>
                {datePickerControlContent}
            </TransitionGroup>
        </>
    );
}
