import { defineMessages } from 'react-intl';

import { registerToolItem, Tool } from '../../components/basic';
import { SettingsEnvironmentContext } from '../envrionment-contexts/settings-environment-context';
import { SETTINGS_LEFT_MENU_CONTEXT_NAME } from '../envrionment-contexts/context-names';
import { NavItem } from '../models/navigation';
import { ArgonosModule } from 'src/components/application/modules';

export const WEBHOOKS_EXTERNAl_COMPONENTS_ROUTE = '/settings/webhooks-external-components/:extensionsScope';
export type ExtensionScope = 'externalComponents' | 'webhooks';

const messages = defineMessages({
    webhooks: {
        id: 'settings.users.menu.webhooks',
        defaultMessage: 'Webhooks',
    },
    externalComponents: {
        id: 'settings.users.menu.externalComponents',
        defaultMessage: 'External components',
    },
});

export function webhookNavItems(argonosModule: ArgonosModule) {
    if (!argonosModule || !argonosModule.webhookEnabled) {
        return [];
    }

    const navItem: NavItem = {
        path: `/settings/webhooks/${encodeURIComponent(argonosModule.id)}`,
        label: messages.webhooks,
        icon: 'icon-application-outline',
        iconSize: 20,
        children: [],
    };

    return [navItem];
}

// Register tool items for webhooks of given argonosModule. If argonosModule is omitted registers of central admin.
export function registerWebhookToolItems(path: string, order: number, argonosModule?: ArgonosModule): void {
    if (argonosModule && !argonosModule.webhookEnabled) {
        return;
    }

    registerToolItem(SETTINGS_LEFT_MENU_CONTEXT_NAME, {
        path,
        order,
        label: messages.webhooks,
        icon: 'icon-webhook',
        visible: (environmentContext: SettingsEnvironmentContext) => {
            if (!environmentContext.hasAnyPermissions('admin.webhook.access')) {
                return false;
            }

            return true;
        },
        onClick: (tool: Tool<SettingsEnvironmentContext>, environmentContext: SettingsEnvironmentContext) => {
            const url = argonosModule ?
                `/settings/${encodeURIComponent(argonosModule.id)}/webhooks` :
                '/settings/webhooks';
            environmentContext.navigate(url);
        },
    });
}

// Registers tool items for the external components of central admin.
export function registerExternalComponentsToolItems(path: string, order: number) {
    registerToolItem(SETTINGS_LEFT_MENU_CONTEXT_NAME, {
        path,
        order,
        label: messages.externalComponents,
        icon: 'icon-application-outline',
        visible: (environmentContext: SettingsEnvironmentContext) => {
            if (!environmentContext.hasAnyPermissions('preparation.remote.component.access')) {
                return false;
            }

            return true;
        },
        onClick: (tool: Tool<SettingsEnvironmentContext>, environmentContext: SettingsEnvironmentContext) => {
            environmentContext.navigate('/settings/external-components');
        },
    });
}

export function setupExtensions() {
    registerWebhookToolItems('global/webhooks', 300);
}
