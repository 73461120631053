import { useCallback, useMemo } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { GetOntologiesDTO } from 'src/settings/models/dtoApi';
import { ArgCheckbox, ArgCheckboxMinus, ArgRelativeTime, ArgTable2Column, ClassNamesFn } from 'src/components/basic';
import { UniverseTableKebabMenu } from '../universe-table-kebab-menu/universe-table-kebab-menu';

import './universe-table.less';

export const messages = defineMessages({
    by: {
        id: 'settings.universe-table.creation-date-by',
        defaultMessage: 'By ',
    },
    nameColumn: {
        id: 'settings.universe-table.name',
        defaultMessage: 'Name',
    },
    description: {
        id: 'settings.universe-table.description',
        defaultMessage: 'Description',
    },
    creationDate: {
        id: 'settings.universe-table.creation-date',
        defaultMessage: 'Creation date',
    },
});

export function useTableColumns(
    ontologies: GetOntologiesDTO[],
    universesIdSelected: Set<string>,
    setUniversesIdSelected: (ids: Set<string>) => void,
    classNames: ClassNamesFn,
): ArgTable2Column<GetOntologiesDTO>[] {
    const handleUniverseSelection = useCallback((universeId: string) => {
        const newSet = new Set(universesIdSelected);
        if (universesIdSelected.has(universeId)) {
            newSet.delete(universeId);
            setUniversesIdSelected(newSet);

            return;
        }

        newSet.add(universeId);
        setUniversesIdSelected(newSet);
    }, [universesIdSelected, setUniversesIdSelected]);

    const handleAllUniversesSelection = useCallback(() => {
        if (universesIdSelected.size > 0) {
            setUniversesIdSelected(new Set());

            return;
        }

        const universesId = ontologies.map((universe) => universe.id);
        setUniversesIdSelected(new Set(universesId));
    }, [ontologies, universesIdSelected, setUniversesIdSelected]);

    const getAllUniversesCheckboxValue = useCallback(() => {
        if (universesIdSelected.size === 0) {
            return false;
        }

        if (universesIdSelected.size === ontologies.length) {
            return true;
        }

        return 'minus';
    }, [universesIdSelected, ontologies]);

    const ret = useMemo<ArgTable2Column<GetOntologiesDTO>[]>(
        () => [
            {
                key: 'check',
                title: function header() {
                    return (
                        <ArgCheckboxMinus
                            size='node'
                            value={getAllUniversesCheckboxValue()}
                            onChange={() => handleAllUniversesSelection()}
                        />
                    );
                },
                dataIndex: 'id',
                width: 40,
                render: function dataRow(id) {
                    return (
                        <ArgCheckbox
                            size='node'
                            value={universesIdSelected.has(id)}
                            onChange={() => handleUniverseSelection(id)}
                        />
                    );
                },
            },
            {
                key: 'name',
                title: messages.nameColumn,
                dataIndex: 'name',
                render: function dataRow(name) {
                    return name;
                },
            },
            {
                key: 'description',
                columnName: 'description',
                title: messages.description,
                dataIndex: 'description',
                sortable: true,
            },
            {
                key: 'creationDate',
                sortable: true,
                columnName: 'Creation date',
                title: messages.creationDate,
                dataIndex: 'creationDate',
                render: function display(creationDate, universe) {
                    return universe.createdDate ? (
                        <div className={classNames('&-cell-last-modified')}>
                            <ArgRelativeTime date={new Date(universe.createdDate)} numeric='auto' />
                            <span className={classNames('&-cell-last-modified-user')}>
                                <FormattedMessage {...messages.by} />
                                {universe.createdBy?.displayName}
                            </span>
                        </div>
                    ) : (
                        <div />
                    );
                },
            },
            {
                key: 'kebab-menu',
                title: '',
                width: 50,
                dataIndex: 'id',
                render: function dataRow(id, ontology) {
                    return <UniverseTableKebabMenu ontologyId={ontology.id} ontologyName={ontology.name} universeId={ontology.universeIds[0]} />;
                },
            },
        ],
        [
            getAllUniversesCheckboxValue,
            handleAllUniversesSelection,
            handleUniverseSelection,
            universesIdSelected,
            classNames,
        ],
    );

    return ret;
}
