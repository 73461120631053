import { useCallback, useEffect, useState } from 'react';
import { chain, defaultTo, includes } from 'lodash';
import { defineMessages, FormattedMessage, MessageDescriptor } from 'react-intl';

import { ClassValue, useArgNotifications, useClassNames, useProgressMonitor } from '../../../components/basic';
import { EmptyPane } from '../../../components/common/panes/empty-pane';
import { LoadingPane } from '../../../components/common/panes/loading-pane';
import {
    ContextualVariable,
    ContextualVariableType,
    ContextualVariableValueType,
} from '../../../exploration/model/contextual-variable';
import { messages as contextualVariablesMessages } from '../index';
import { ActionButtons } from '../components/action-buttons';
import { messages as variableMessages } from '../components/create-variable-modal';
import { useTableColumns } from '../components/columns';
import { ContextualVariablesTable } from '../components/contextual-variables-table';
import { ExplorationConnector } from '../../../exploration/utils/connector/exploration-connector';

import './contextual-variables.less';

export interface TableContextualVariable {
    key: string;
    name: string;
    displayName: string;
    level: number;
    mode?: MessageDescriptor;
    value?: ContextualVariableValueType;
    description?: string;
    isGroup: boolean;
    groupInfos?: {
        childrenVariableDisplayNames: string[];
        isLastLevel: boolean;
    };
    variableInfo?: {
        type: ContextualVariableType;
    };
    original?: ContextualVariable;
}

const messages = defineMessages({
    noExistingVar: {
        id: 'settings.contextual-variables.empty.title',
        defaultMessage: 'No variables',
    },
    emptyDetails: {
        id: 'settings.contextual-variables.empty.details',
        defaultMessage: 'Create a new variable or group',
    },
    loadingContextualVariables: {
        id: 'settings.contextual-variables.loading.title',
        defaultMessage: 'Loading contextual variables',
    },
    emptyGroupPlaceHolder: {
        id: 'settings.contextual-variables.table.empty-group.placeholder',
        defaultMessage: 'Group is empty. Please add a new variable',
    },
    loadContextualVariablesError: {
        id: 'settings.contextual-variables.LoadContextualVariablesError',
        defaultMessage: 'Failed to load contextual variables',
    },
    saveContextualVariablesError: {
        id: 'settings.contextual-variables.SaveContextualVariablesError',
        defaultMessage: 'Failed to save contextual variables',
    },
});

interface ContextualVariablesViewProps {
    className?: ClassValue;
}

export function ContextualVariablesView(props: ContextualVariablesViewProps) {
    const {
        className,
    } = props;

    const classNames = useClassNames('contextual-variables');
    const notifications = useArgNotifications();

    const [contextualVariables, setContextualVariables] = useState<ContextualVariable[]>([]);
    const [tableContextualVariables, setTableContextualVariables] = useState<TableContextualVariable[]>([]);
    const [loadContextualVariablesProgressMonitor, setLoadContextualVariablesProgressMonitor] =
        useProgressMonitor();
    const [search, setSearch] = useState<string>();
    const [loadContextualVariableStateId, setLoadContextualVariableStateId] = useState<number>(0);

    useEffect(() => {
        const loadContextualVariablesAsync = async () => {
            const progressMonitor = setLoadContextualVariablesProgressMonitor(
                messages.loadingContextualVariables,
                1,
            );
            try {
                const contextualVariables = await ExplorationConnector.getInstance().getContextualVariables(
                    progressMonitor,
                );
                setContextualVariables(contextualVariables);
            } catch (error) {
                if (progressMonitor.isCancelled) {
                    return;
                }
                notifications.snackError({ message: messages.loadContextualVariablesError }, error as Error);
            } finally {
                progressMonitor.done();
            }
        };
        loadContextualVariablesAsync();
    }, [loadContextualVariableStateId]);

    const buildPath = useCallback((...args: string[]) => {
        return args.join('/');
    }, []);

    const getPathFragments = useCallback((path: string) => {
        return path.split('/');
    }, []);

    useEffect(() => {
        // Prepare data for table view
        const tableContextualVariables = new Array<TableContextualVariable>();
        const sortedVariables = [...contextualVariables].sort((a, b) =>
            defaultTo(a.path, '').localeCompare(defaultTo(b.path, '')),
        );
        const variablesByPath = chain(sortedVariables)
            .groupBy((variable) => variable.path || '')
            .value();
        const createdPaths = new Array<string>();
        Object.keys(variablesByPath).forEach((path) => {
            const variables = variablesByPath[path];
            let variableLevel = 0;
            let currentFullPath = '';
            if (path) {
                const pathFragments = getPathFragments(path);
                variableLevel = pathFragments.length;
                const childrenVariableDisplayNames = sortedVariables
                    .filter((variable) => variable.path && variable.path.startsWith(path))
                    .map((variable) => variable.displayName);

                // Add groups
                pathFragments.forEach((pathFragment, index) => {
                    currentFullPath = currentFullPath
                        ? buildPath(currentFullPath, pathFragment)
                        : pathFragment;
                    if (!includes(createdPaths, currentFullPath)) {
                        tableContextualVariables.push({
                            key: currentFullPath,
                            name: pathFragment,
                            displayName: pathFragment,
                            level: index,
                            isGroup: true,
                            groupInfos: {
                                childrenVariableDisplayNames: childrenVariableDisplayNames,
                                isLastLevel: index + 1 === pathFragments.length,
                            },
                        });

                        createdPaths.push(currentFullPath);
                    }
                });
            }
            tableContextualVariables.push(
                ...variables.map((variable) => {
                    const retValue: TableContextualVariable = {
                        key: currentFullPath
                            ? buildPath(currentFullPath, variable.id)
                            : variable.id,
                        name: variable.id,
                        displayName: variable.displayName,
                        level: variableLevel,
                        description: variable.description,
                        mode: variableMessages.manual,
                        value: variable.value,
                        isGroup: false,
                        variableInfo: {
                            type: variable.type,
                        },
                        original: variable,
                    };

                    return retValue;
                }),
            );
        });

        setTableContextualVariables(() => {
            return tableContextualVariables;
        });
    }, [contextualVariables, buildPath, getPathFragments]);

    const handleSearch = useCallback((value: string) => {
        setSearch(value);
    }, []);

    const columns = useTableColumns(setContextualVariables);

    return (
        <>
            <div className={classNames('&', className)}>
                <div className={classNames('&-header')}>
                    <h1 className={classNames('&-header-title-container')}>
                        <span className={classNames('&-header-title')}>
                            <FormattedMessage
                                {...contextualVariablesMessages.contextualVariables}
                            />
                        </span>
                    </h1>
                </div>
                <ActionButtons
                    handleSearch={handleSearch}
                    setContextualVariables={setContextualVariables}
                />
                <div className={classNames('&-body')}>
                    {loadContextualVariablesProgressMonitor?.isRunning ? (
                        <LoadingPane
                            className={classNames('&-body-content')}
                            progressMonitor={loadContextualVariablesProgressMonitor}
                        />
                    ) : tableContextualVariables.length ? (
                        <ContextualVariablesTable
                            columns={columns}
                            tableContextualVariables={tableContextualVariables}
                            search={search}
                        />
                    ) : (
                        <EmptyPane className={classNames('&-body-content')}>
                            <div className={classNames('&-body-content-empty')}>
                                <div className={classNames('&-body-content-empty-title')}>
                                    <FormattedMessage {...messages.noExistingVar} />
                                </div>
                                <div className={classNames('&-body-content-empty-details')}>
                                    <FormattedMessage {...messages.emptyDetails} />
                                </div>
                            </div>
                        </EmptyPane>
                    )}
                </div>
            </div>
        </>
    );
}
