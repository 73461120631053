import React, { useState } from 'react';
import ClassNamesFn from 'classnames';
import { isNil, isNumber, slice } from 'lodash';
import { defineMessages } from 'react-intl';

import { ArgComboLine, ArgInputNumber, dayjs } from '../../../../basic';
import {
    AdvancedStyleType,
    ContinuousValueIntervaleType,
    Gradient,
    Interval,
    IntervalSymbols,
    LineStyle,
    RuleSet,
    StyleControlType,
    UserDefinedContent,
} from '../graph-style';
import { ButtonWithBadge } from '../common/button-with-badge';
import { CustomColorAndIconPicker } from '../common/custom-color-and-icon-picker';
import { DEFAULT_OBJECT_TYPE_COLORS, GRAPH_NODE_MIN_SIZE } from '../../constants';
import { Side } from '../advanced-style';
import { EditorPickerOptions, Property } from '../../../controls/controls-type';
import { DataType } from 'src/components/common/data-types';

const messages = defineMessages({
    mainColor: {
        id: 'exploration.controls.utils.MainColor',
        defaultMessage: 'Main Color',
    },
});


export enum PropertyTypes {
    String = 'String',
    MultiString = 'MultiString',
    Number = 'Number',
    Date = 'Date',
    Bool = 'Bool',
    Unknown = 'Unknown',
}

export interface PropertyInfo {
    type: PropertyTypes;
    isContinuous?: boolean;
}

export interface CommonControlProps {
    type: AdvancedStyleType;
    property: Property;
    index: number;
    fillColor?: string;
    strokeColor?: string;
    iconColor?: string;
    badgeFillColor?: string;
    badgeIconName?: string;
    doesBadgeBlink?: boolean;
    size?: number;
    iconName?: string;
    lineStyle?: LineStyle;
    onStyleChange?: (advancedStyleType: AdvancedStyleType, style: Record<string, any>, index: number) => void;
    canBeRemoved?: boolean;
    onRemoveComponent?: (advancedStyleType: AdvancedStyleType, index: number) => Promise<void>;
    isUndefinedStyleRule?: boolean;
    pickerOptions?: EditorPickerOptions<string>;
}

export function useGetStyleModifier(
    type: AdvancedStyleType,
    classNames: typeof ClassNamesFn,
    index: number,
    fillColor?: string,
    iconColor?: string,
    strokeColor?: string,
    badgeFillColor?: string,
    badgeIconName?: string,
    size?: number,
    iconName?: string,
    onStyleChange?: (label: AdvancedStyleType, style: Record<string, any>, index: number) => void,
    doesBadgeBlink?: boolean,
    lineStyle?: LineStyle,
) {
    const [popoverVisible, setPopoverVisible] = useState(false);
    const onSizeChange = (size: number | null) => {
        let _size = size;
        if (_size === null || _size < GRAPH_NODE_MIN_SIZE) {
            _size = GRAPH_NODE_MIN_SIZE;
        }
        const _sizeRounded = Number((Number((_size * 10).toFixed(2)) / 10).toFixed(2));

        onStyleChange?.('size', { size: _sizeRounded }, index);
    };
    switch (type) {
        case 'color': {
            const colorEditor = (
                <CustomColorAndIconPicker
                    className={classNames('&-color-picker')}
                    fillColor={fillColor}
                    iconColor={iconColor}
                    strokeColor={strokeColor}
                    defaultColors={DEFAULT_OBJECT_TYPE_COLORS}
                    //onFillColorChange={(color) => onStyleChange?.('color', { fillColor: color }, index)}
                    //onStrokeColorChange={(color) => onStyleChange?.('color', { strokeColor: color }, index)}
                    onIconColorChange={(color) => onStyleChange?.('color', { iconColor: color }, index)}
                    asColorPicker={true}
                    onlyOneColor='icon'
                    onlyOneTitle={messages.mainColor}
                    onPopoverClose={() => setPopoverVisible(false)}
                />
            );

            return (
                <ButtonWithBadge
                    className={classNames('&-button-with-badge')}
                    backgroundColor={iconColor}
                    //borderColor={(strokeColor && strokeColor !== 'none') ? strokeColor : iconColor}
                    //iconColor={iconColor}
                    popover={colorEditor}
                    popoverVisible={popoverVisible}
                    onPopoverVisibleChange={setPopoverVisible}
                    popoverPlacement='left'
                />
            );
        }

        case 'icon': {
            const iconEditor = (
                <CustomColorAndIconPicker
                    className={classNames('&-icon-picker')}
                    iconName={iconName}
                    onIconChange={(icon) => onStyleChange?.('icon', { iconName: icon }, index)}
                    asIconPicker={true}
                    onPopoverClose={() => setPopoverVisible(false)}
                />
            );

            return (
                <ButtonWithBadge
                    className={classNames('&-button-with-badge')}
                    popover={iconEditor}
                    popoverVisible={popoverVisible}
                    onPopoverVisibleChange={setPopoverVisible}
                    popoverPlacement='left'
                    borderColor='#D4D9E0'
                    iconName={iconName}
                    iconColor='#000000'
                />
            );
        }
        case 'size': {
            return (
                <ArgInputNumber
                    value={size}
                    clearable={false}
                    onChange={onSizeChange}
                    displayRightControl={true}
                    step={GRAPH_NODE_MIN_SIZE}
                    min={GRAPH_NODE_MIN_SIZE}
                />
            );
        }
        case 'badges': {
            const badgesEditor = (
                <CustomColorAndIconPicker
                    className={classNames('&-badges-picker')}
                    fillColor={fillColor}
                    defaultColors={DEFAULT_OBJECT_TYPE_COLORS}
                    iconName={iconName}
                    onFillColorChange={(color) => onStyleChange?.('badges', { fillColor: color }, index)}
                    onStrokeColorChange={(color) => onStyleChange?.('badges', { strokeColor: color }, index)}
                    onIconColorChange={(color) => onStyleChange?.('badges', { iconColor: color }, index)}
                    onIconChange={(icon) => onStyleChange?.('badges', { iconName: icon }, index)}
                    onBadgeSizeChange={(size) => onStyleChange?.('badges', { size }, index)}
                    onBadgeBlinkChange={(blink) => onStyleChange?.('badges', { badgeBlink: blink }, index)}
                    asBadgePicker={true}
                    size={size}
                    doesBadgeBlink={!!doesBadgeBlink}
                    onlyOneColor='fill'
                    onPopoverClose={() => setPopoverVisible(false)}
                />
            );

            return (
                <ButtonWithBadge
                    className={classNames('&-button-with-badge')}
                    backgroundColor={fillColor}
                    borderColor={strokeColor}
                    popover={badgesEditor}
                    popoverVisible={popoverVisible}
                    onPopoverVisibleChange={setPopoverVisible}
                    popoverPlacement='left'
                    iconName={iconName}
                    iconColor='white'
                />
            );
        }

        case 'style': {
            return (
                <ArgComboLine
                    value={lineStyle ?? 'solid'}
                    onChange={(value) => onStyleChange?.('style', { lineStyle: value }, index)}
                    allowHidden={true}
                />
            );
        }

        default:
            throw new Error('undefined advanced style type');
    }
}

const flagInvalidIntervals = (
    invalidFieldsSet: Record<number,
        {
            invalidLeft: boolean;
            invalidRight: boolean;
        }>,
    firstIntervalIndex: number,
    secondIntervalIndex: number,
    firstIntervalToFlag: [left: 0 | 1, right: 0 | 1],
    secondIntervalToFlag: [left: 0 | 1, right: 0 | 1],
) => {
    invalidFieldsSet[firstIntervalIndex] = {
        invalidLeft: firstIntervalToFlag[0] ? true : !!invalidFieldsSet?.[firstIntervalIndex]?.invalidLeft,
        invalidRight: firstIntervalToFlag[1] ? true : !!invalidFieldsSet?.[firstIntervalIndex]?.invalidRight,
    };
    invalidFieldsSet[secondIntervalIndex] = {
        invalidLeft: secondIntervalToFlag[0] ? true : !!invalidFieldsSet?.[secondIntervalIndex]?.invalidLeft,
        invalidRight: secondIntervalToFlag[1] ? true : !!invalidFieldsSet?.[secondIntervalIndex]?.invalidRight,
    };
};

export const getInvalidFields = (ruleSets: RuleSet[]) => {
    const ruleSetsLength = ruleSets.length;
    const getRuleSetInfo = (ruleSet: RuleSet) => {
        return {
            left: (ruleSet?.condition as Interval)?.interval?.left,
            right: (ruleSet?.condition as Interval)?.interval?.right,
            type: (ruleSet?.condition as Interval)?.interval?.type,
        };
    };

    const ruleSetsWithoutUndefinedValueRuleSet = ruleSets[ruleSetsLength - 1]?.isUndefinedRuleSet
        ? slice(ruleSets, 0, ruleSetsLength - 1)
        : ruleSets;

    const invalidFieldsSet: Record<number, { invalidLeft: boolean; invalidRight: boolean }> = {};
    if (
        ruleSetsWithoutUndefinedValueRuleSet.length < 2 ||
        !(ruleSetsWithoutUndefinedValueRuleSet[0].condition as Interval).interval
    ) {
        return invalidFieldsSet;
    }

    for (let i = 0; i < ruleSetsWithoutUndefinedValueRuleSet.length - 1; i++) {
        const first = ruleSetsWithoutUndefinedValueRuleSet[i];
        const firstIntervalType = getRuleSetInfo(first).type;
        // first interval: a - b
        const a = getRuleSetInfo(first).left;
        const b = getRuleSetInfo(first).right;

        const interval1ClosedOrRightOpen = firstIntervalType === 'Closed' || firstIntervalType === 'RightOpen'; // [x, y[ or [x, y]
        const interval1ClosedOrLeftOpen = firstIntervalType === 'Closed' || firstIntervalType === 'LeftOpen'; // ]x, y] or [x ,y]
        //x === null => x is infinite
        if (a !== null || b !== null) {
            //intervals ]infinity, infinity[ are ignored
            for (let j = i + 1; j < ruleSetsWithoutUndefinedValueRuleSet.length; j++) {
                const second = ruleSetsWithoutUndefinedValueRuleSet[j];
                const secondIntervalType = getRuleSetInfo(second).type;
                // second interval: c -d
                const c = getRuleSetInfo(second).left;
                const d = getRuleSetInfo(second).right;
                const interval2ClosedOrRightOpen =
                    secondIntervalType === 'Closed' || secondIntervalType === 'RightOpen'; // [x, y[ or [x, y]
                const interval2ClosedOrLeftOpen = secondIntervalType === 'Closed' || secondIntervalType === 'LeftOpen'; // ]x, y] or [x, y]

                if (c !== null || d !== null) {
                    //intervals ]infinity, infinity[ are ignored
                    const cond1 = isNil(a) && isNil(c);
                    const cond2 = isNil(a) && !isNil(b) && !isNil(c) && !isNil(d) && (d < b || d === b);
                    const cond3 = !isNil(a) && !isNil(b) && !isNil(d) && isNil(c) && (b < d || d === b);
                    const cond4 =
                        !isNil(a) &&
                        !isNil(b) &&
                        !isNil(c) &&
                        !isNil(d) &&
                        ((a > c && b < d) || (a < c && b > d) || a === c || b === d);
                    const cond5 = isNil(b) && isNil(d);
                    const cond6 = !isNil(a) && isNil(b) && !isNil(c) && !isNil(d) && (c > a || c === a);
                    const cond7 = !isNil(a) && !isNil(b) && !isNil(c) && isNil(d) && (c < a || c === a);

                    // interval1: NOK - NOK
                    // interval2: NOK - NOK
                    if (cond1 || cond2 || cond3 || cond4 || cond5 || cond6 || cond7) {
                        flagInvalidIntervals(invalidFieldsSet, i, j, [1, 1], [1, 1]);

                        return invalidFieldsSet;
                    }

                    const cond8 =
                        !isNil(a) &&
                        isNil(b) &&
                        isNil(c) &&
                        !isNil(d) &&
                        (a < d || (a === d && interval1ClosedOrRightOpen && interval2ClosedOrLeftOpen));
                    const cond9 =
                        !isNil(a) &&
                        isNil(b) &&
                        !isNil(c) &&
                        !isNil(d) &&
                        (a < d || (a === d && interval1ClosedOrRightOpen && interval2ClosedOrLeftOpen));
                    const cond10 =
                        !isNil(a) &&
                        !isNil(b) &&
                        isNil(c) &&
                        !isNil(d) &&
                        (a < d || (a === d && interval1ClosedOrRightOpen && interval2ClosedOrLeftOpen));
                    const cond11 =
                        !isNil(a) &&
                        !isNil(b) &&
                        !isNil(c) &&
                        !isNil(d) &&
                        ((a < d && c < a) || (a === d && interval1ClosedOrRightOpen && interval2ClosedOrLeftOpen));

                    // interval1: NOK - OK
                    // interval2: OK - NOK
                    if (cond8 || cond9 || cond10 || cond11) {
                        flagInvalidIntervals(invalidFieldsSet, i, j, [1, 0], [0, 1]);

                        return invalidFieldsSet;
                    }

                    const cond12 =
                        isNil(a) &&
                        !isNil(b) &&
                        !isNil(c) &&
                        isNil(d) &&
                        (c < b || (c === b && interval1ClosedOrLeftOpen && interval2ClosedOrRightOpen));
                    const cond13 =
                        isNil(a) &&
                        !isNil(b) &&
                        !isNil(c) &&
                        !isNil(d) &&
                        (c < b || (c === b && interval1ClosedOrLeftOpen && interval2ClosedOrRightOpen));
                    const cond14 =
                        !isNil(a) &&
                        !isNil(b) &&
                        !isNil(c) &&
                        isNil(d) &&
                        (c < b || (c === b && interval1ClosedOrLeftOpen && interval2ClosedOrRightOpen));
                    const cond15 =
                        !isNil(a) &&
                        !isNil(b) &&
                        !isNil(c) &&
                        !isNil(d) &&
                        ((c < b && c > a) || (c === b && interval1ClosedOrLeftOpen && interval2ClosedOrRightOpen));

                    // interval1: NOK - OK
                    // interval2: OK - NOK
                    if (cond12 || cond13 || cond14 || cond15) {
                        flagInvalidIntervals(invalidFieldsSet, i, j, [0, 1], [1, 0]);

                        return invalidFieldsSet;
                    }
                }
            }
        }
    }

    return invalidFieldsSet;
};

export function getSpecificProps(
    advancedStyleType: AdvancedStyleType,
    userDefinedContent: UserDefinedContent | undefined,
    controlType?: StyleControlType,
): Record<string, any> {
    switch (advancedStyleType) {
        case 'color':
            return {
                fillColor: userDefinedContent?.fillColor,
                strokeColor: userDefinedContent?.strokeColor,
                iconColor: userDefinedContent?.iconColor,
            };
        case 'icon':
            return {
                iconName: userDefinedContent?.iconName,
            };
        case 'size':
            if (controlType === 'gradient') {
                return {
                    gradientSize: userDefinedContent?.gradientSize,
                };
            }

            return {
                size: userDefinedContent?.size,
            };
        case 'badges':
            return {
                fillColor: userDefinedContent?.fillColor,
                strokeColor: userDefinedContent?.strokeColor,
                iconName: userDefinedContent?.iconName,
                size: userDefinedContent?.size,
                doesBadgeBlink: userDefinedContent?.badgeBlink,
            };
        case 'style': {
            return {
                lineStyle: userDefinedContent?.lineStyle,
            };
        }

        default:
            return {};
    }
}

export const getConditionAsInterval = (ruleSet: RuleSet) => ruleSet?.condition as Interval;
export const getConditionAsGradient = (ruleSet: RuleSet) => ruleSet?.condition as Gradient;

export const getIntervalType = (intervalType: ContinuousValueIntervaleType): IntervalSymbols => {
    switch (intervalType) {
        case 'Closed':
            return {
                lowerBoundIntervalType: <span>&ge;</span>,
                upperBoundIntervalType: <span>&le;</span>,
            };
        case 'Open':
            return {
                lowerBoundIntervalType: <span>&gt;</span>,
                upperBoundIntervalType: <span>&lt;</span>,
            };
        case 'LeftOpen':
            return {
                lowerBoundIntervalType: <span>&gt;</span>,
                upperBoundIntervalType: <span>&le;</span>,
            };
        case 'RightOpen':
            return {
                lowerBoundIntervalType: <span>&ge;</span>,
                upperBoundIntervalType: <span>&lt;</span>,
            };

        //default => case 'RightOpen':
        default:
            return {
                lowerBoundIntervalType: <span>&ge;</span>,
                upperBoundIntervalType: <span>&lt;</span>,
            };
    }
};

export const getPropertyInfo = (property: Property): PropertyInfo => {
    const isPropertyTypeContinuous = property?.isContinuous;

    switch (property.type) {
        case DataType.String:
        case DataType.Char:
        case DataType.Guid:
        case DataType.Email:
        case DataType.Url:
        case DataType.PhoneNumber:
        case DataType.Address:
        case DataType.Country:
        case DataType.Text:
            return {
                type: PropertyTypes.String,
                isContinuous: isPropertyTypeContinuous,
            };
        case DataType.MultiString:
            return {
                type: PropertyTypes.MultiString,
                isContinuous: isPropertyTypeContinuous,
            };

        case DataType.Byte:
        case DataType.Short:
        case DataType.Int:
        case DataType.Long:
        case DataType.Float:
        case DataType.Double:
            return {
                type: PropertyTypes.Number,
                isContinuous: isPropertyTypeContinuous,
            };

        case DataType.Date:
        case DataType.DateTime:
            return {
                type: PropertyTypes.Date,
                isContinuous: isPropertyTypeContinuous,
            };

        case DataType.Bool:
            return {
                type: PropertyTypes.Bool,
                isContinuous: isPropertyTypeContinuous,
            };

        default:
            return {
                type: PropertyTypes.Unknown,
            };
    }
};

export const formatSize = (size: number | null) => {
    const formattedSize = size ? Number((Number((size * 10).toFixed(2)) / 10).toFixed(2)) : size;

    return formattedSize;
};

/** Returns the correct value taking into consideration the interval Bounds */
export const handleIntervalOverlapValues = (
    side: Side,
    value: number | dayjs.Dayjs | null | undefined,
    otherBound: number | dayjs.Dayjs | null | undefined,
    type: 'date' | 'number',
    bounds?: [number | dayjs.Dayjs | undefined, number | dayjs.Dayjs | undefined],
) => {
    if (isNil(value)) {
        return null;
    }

    let min: number | dayjs.Dayjs | null | undefined = null;
    let max: number | dayjs.Dayjs | null | undefined = null;

    if (side === 'left') {
        min = bounds ? bounds[0] : null;
        if (otherBound === null && isNil(bounds?.[1])) {
            max = null;
        } else if (!isNil(otherBound) && isNil(bounds?.[1])) {
            max = otherBound;
        } else if (isNil(otherBound) && !isNil(bounds) && !isNil(bounds?.[1])) {
            max = bounds[1];
        } else if (!isNil(otherBound) && !isNil(bounds) && !isNil(bounds?.[1])) {
            if (typeof otherBound === 'number') {
                max = Math.min(otherBound, bounds?.[1] as number);
            } else {
                max = dayjs.min(otherBound as dayjs.Dayjs, bounds?.[1] as dayjs.Dayjs);
            }
        }
    } else {
        // right
        max = bounds ? bounds[1] : null;
        if (isNil(otherBound) && isNil(bounds?.[0])) {
            min = null;
        } else if (!isNil(otherBound) && isNil(bounds?.[0])) {
            min = otherBound;
        } else if (isNil(otherBound) && !isNil(bounds) && !isNil(bounds?.[0])) {
            min = bounds[0];
        } else if (!isNil(otherBound) && !isNil(bounds) && !isNil(bounds?.[0])) {
            if (typeof otherBound === 'number') {
                min = Math.max(otherBound, bounds?.[0] as number);
            } else {
                min = dayjs.max(otherBound as dayjs.Dayjs, bounds?.[0] as dayjs.Dayjs);
            }
        }
    }

    if (isNil(min) && isNil(max)) {
        return value;
    } else if (!isNil(max) && isNil(min)) {
        if (strictCompare([value, max], 'gt')) {
            return max;
        } else {
            return value;
        }
    } else if (isNil(max) && !isNil(min)) {
        if (strictCompare([value, min], 'lt')) {
            return min;
        } else {
            return value;
        }
    } else if (!isNil(max) && !isNil(min)) {
        if (strictCompare([value, max], 'gt')) {
            return max;
        }
        if (strictCompare([value, min], 'lt')) {
            return min;
        } else {
            return value;
        }
    }

    return null;
};

export const strictCompare = (x: [a: number | dayjs.Dayjs, b: number | dayjs.Dayjs], comparisonType: 'lt' | 'gt') => {
    if (isNumber(x[0]) && isNumber(x[1])) {
        return comparisonType === 'lt' ? x[0] < x[1] : x[0] > x[1];
    }

    return comparisonType === 'lt'
        ? (x[0] as dayjs.Dayjs).isBefore(x[1] as dayjs.Dayjs)
        : (x[0] as dayjs.Dayjs).isAfter(x[1] as dayjs.Dayjs);
};
