import React, { useEffect, useState } from 'react';
import tinycolor, { ColorFormats } from 'tinycolor2';
import { CustomPicker } from 'react-color';
import { defineMessages, FormattedMessage } from 'react-intl';

import { ColorPalettePickerSaturation } from './color-palette-picker-saturation';
import { ColorPalettePickerHue } from './color-palette-picker-hue';
import { ColorPalettePickerInputs } from './color-palette-picker-inputs';
import { ColorPalettePickerDefaultColors } from './color-palette-picker-default-colors';
import { IconPicker } from './icon-picker';
import {
    ArgButton,
    ArgCheckbox,
    ArgInputNumber,
    ArgRenderedText,
    ClassValue, renderText,
    useClassNames,
} from '../../../../basic';
import { CustomHueSlider } from './custom-hue-slider';
import { CustomSaturationPointer } from './custom-saturation-pointer';
import { DEFAULT_BADGE_STYLE } from '../../constants';

import './color-and-icon-picker.less';

let _globalIconsList: string[];

const messages = defineMessages({
    fillColor: {
        id: 'exploration.graph.customisation.fill.color',
        defaultMessage: 'Fill',
    },
    strokeColor: {
        id: 'exploration.graph.customisation.stroke.color',
        defaultMessage: 'Stroke',
    },
    iconColor: {
        id: 'exploration.graph.customisation.icon.color',
        defaultMessage: 'Icon',
    },
    selectColor: {
        id: 'exploration.graph.customisation.select.color',
        defaultMessage: 'Select color',
    },
    cancelChanges: {
        id: 'exploration.graph.customisation.cancel.changes',
        defaultMessage: 'Cancel',
    },
    applyChanges: {
        id: 'exploration.graph.customisation.apply.changes',
        defaultMessage: 'Apply',
    },
    noColor: {
        id: 'exploration.graph.customisation.no.color',
        defaultMessage: 'No stroke color',
    },
    badgeBlink: {
        id: 'exploration.graph.customisation.badge.blink',
        defaultMessage: 'Blink',
    },
    badgeSizeLabel: {
        id: 'exploration.graph.customisation.badge.size.label',
        defaultMessage: 'Size',
    },
    resetStylesLabel: {
        id: 'exploration.graph.customisation.reset.styles.label',
        defaultMessage: 'Reset',
    },
});

type ColorType = 'icon' | 'fill' | 'stroke';

interface ColorAndIconPickerProps {
    className?: ClassValue;
    fillColor?: string;
    strokeColor?: string;
    iconColor?: string;

    iconName?: string;
    iconFontFamily?: string;
    defaultColors?: string[];

    onIconColorChange?: (color: string) => void;
    onFillColorChange?: (color: string) => void;
    onStrokeColorChange?: (color: string) => void;
    onIconChange?: (iconName: string) => void;
    onBadgeSizeChange?: (size: number | null) => void;
    onBadgeBlinkChange?: (blink: boolean) => void;

    size?: number;
    doesBadgeBlink?: boolean;

    onlyOneColor?: ColorType;
    onlyOneTitle?: ArgRenderedText;

    asIconPicker?: boolean;
    asColorPicker?: boolean;
    asBadgePicker?: boolean;
    onResetStyles?: () => Promise<void>;
    onPopoverClose?: () => void;
}

export function ColorAndIconPicker(props: ColorAndIconPickerProps) {
    const {
        className,
        fillColor,
        iconColor,
        strokeColor,
        iconName,
        onlyOneTitle,
        onFillColorChange,
        onIconColorChange,
        onStrokeColorChange,
        onIconChange,
        onlyOneColor,
        asIconPicker,
        asColorPicker,
        asBadgePicker,
        size,
        doesBadgeBlink,
        onBadgeSizeChange,
        onBadgeBlinkChange,
        onResetStyles,
        onPopoverClose,
        defaultColors,
    } = props;

    const classNames = useClassNames('exploration-color-and-icon-picker');
    const [selectedColorType, setSelectedColorType] = useState<ColorType>(onlyOneColor || 'fill');
    let currentColor: string | undefined;// = getColorType ? fillColor : strokeColor;
    let changeColor: ((color: string) => void) | undefined;// = isFillColor ? onFillColorChange : onStrokeColorChange;

    switch (selectedColorType) {
        case 'fill':
            currentColor = fillColor;
            changeColor = onFillColorChange;
            break;
        case 'icon':
            currentColor = iconColor;
            changeColor = onIconColorChange;
            break;
        case 'stroke':
            currentColor = strokeColor;
            changeColor = onStrokeColorChange;
            break;
    }

    const [hsl, setHsl] = useState<ColorFormats.HSLA>({ h: 0, s: 0, l: 0, a: 1 });
    const [hsv, setHsv] = useState<ColorFormats.HSVA>({ h: 0, s: 0, v: 0, a: 1 });

    const [iconsList, setIconsList] = useState(() => _globalIconsList);

    useEffect(() => {
        if (iconsList) {
            return;
        }

        import('../../../../../styles/fonts/icomoon/selection-icon-list.json')
            .then(icons => icons.default)
            .then((defaultIcons: any) => {
                _globalIconsList = Object.keys(defaultIcons);

                setIconsList(_globalIconsList);
            });
    }, []);

    useEffect(() => {
        setHsl(tinycolor(currentColor).toHsl());
        setHsv(tinycolor(currentColor).toHsv());
    }, [currentColor]);

    const onColorChange = (color: any, source: string) => {
        switch (source) {
            case 'saturation':
                setHsv(color);
                changeColor?.(tinycolor(color).toHexString());
                break;
            case 'hue':
                setHsl(color);
                changeColor?.(tinycolor({ ...hsv, h: color.h }).toHexString());
                break;
            case 'hex-input': {
                changeColor?.(tinycolor(color).toHexString());
                const extractedHsv = tinycolor(color).toHsv();
                setHsv(tinycolor(color).toHsv());

                extractedHsv?.s !== 0 && setHsl(tinycolor(color).toHsl());
                break;
            }
            case 'default-colors': {
                changeColor?.(tinycolor(color).toHexString());
                const hsvFromHex = tinycolor(color).toHsv();
                setHsv(tinycolor(color).toHsv());

                hsvFromHex?.s !== 0 && setHsl(tinycolor(color).toHsl());
                break;
            }
            case 'rgb-input': {
                changeColor?.(tinycolor(color).toHexString());
                const hsvFromRgb = tinycolor(color).toHsv();
                setHsv(tinycolor(color).toHsv());

                hsvFromRgb?.s !== 0 && setHsl(tinycolor(color).toHsl());

                break;
            }
            default:
                break;
        }
    };

    return (
        <div className={classNames('&', className)}>
            <div className={classNames('&-header')}>
                <ArgButton
                    className={classNames('&-close')}
                    size='small'
                    type='ghost'
                    icon='icon-cross'
                    onClick={onPopoverClose}
                />
            </div>
            <div className={classNames('&-content')}>
                {!asIconPicker &&
                    <div className={classNames('&-color')}>
                        <div className={classNames('&-select-color-label')}>
                            <FormattedMessage {...messages.selectColor} />
                        </div>
                        <div className={classNames('&-fill-and-stroke-color')}>
                            {onFillColorChange && (!onlyOneColor) &&
                                <div
                                    key='fill'
                                    className={classNames('&-fill-color', { selected: selectedColorType === 'fill' })}
                                    onClick={() => setSelectedColorType('fill')}
                                >
                                    <div
                                        className={classNames('&-fill-color-circle', { selected: selectedColorType === 'fill' })}
                                        style={{ backgroundColor: strokeColor }}
                                    >
                                        <div
                                            className={classNames('&-fill-color-circle-child', { selected: selectedColorType === 'fill' })}
                                            style={{ backgroundColor: fillColor }}
                                        />
                                    </div>
                                    {renderText(onlyOneTitle || messages.fillColor)}
                                </div>}
                            {onStrokeColorChange && (!onlyOneColor) &&
                                <div
                                    key='stroke'
                                    className={classNames('&-stroke-color', { selected: selectedColorType === 'stroke' })}
                                    onClick={() => setSelectedColorType('stroke')}
                                >
                                    <div
                                        className={classNames('&-stroke-color-circle', { selected: selectedColorType === 'stroke' })}
                                        style={{ backgroundColor: strokeColor }}
                                    >
                                        <div
                                            className={classNames('&-stroke-color-circle-child', {
                                                selected: selectedColorType === 'stroke',
                                            })}
                                            style={{ backgroundColor: fillColor }}
                                        />
                                    </div>
                                    {renderText(onlyOneTitle || messages.strokeColor)}
                                </div>}
                            {onIconColorChange && (!onlyOneColor) &&
                                <div
                                    key='icon'
                                    className={classNames('&-icon-color', { selected: selectedColorType === 'icon' })}
                                    onClick={() => setSelectedColorType('icon')}
                                >
                                    <div
                                        className={classNames('&-icon-color-circle', { selected: selectedColorType === 'icon' })}
                                        style={{ backgroundColor: strokeColor }}
                                    >
                                        <div
                                            className={classNames('&-icon-color-circle-child', {
                                                selected: selectedColorType === 'icon',
                                            })}
                                            style={{ backgroundColor: iconColor }}
                                        />
                                    </div>
                                    {renderText(onlyOneTitle || messages.iconColor)}
                                </div>}
                        </div>
                        <div className={classNames('&-selector-container', {
                            '&-selector-container-fill-selected': selectedColorType === 'fill',
                            '&-selector-container-stroke-selected': selectedColorType === 'stroke',
                            '&-selector-container-icon-selected': selectedColorType === 'icon',
                        })}>
                            <ColorPalettePickerSaturation
                                className={classNames('&-saturation')}
                                onChange={(color) => onColorChange(color, 'saturation')}
                                hsv={hsv}
                                hsl={hsl}
                                pointer={CustomSaturationPointer as unknown as React.ReactNode} // The type transformation is due to React Color types issue. See (https://github.com/casesandberg/react-color/blob/master/examples/custom-pointer/src/MyPicker.js)

                            />
                            <ColorPalettePickerHue
                                className={classNames('&-hue')}
                                onChange={(color) => onColorChange(color, 'hue')}
                                hsl={hsl}
                                direction='horizontal'
                                pointer={CustomHueSlider as unknown as React.ReactNode} // The type transformation is due to React Color types issue. See (https://github.com/casesandberg/react-color/blob/master/examples/custom-pointer/src/MyPicker.js)

                            />
                            <ColorPalettePickerInputs
                                className={classNames('&-color-inputs')}
                                hex={currentColor}
                                rgb={tinycolor(currentColor).toRgb()}
                                onHexChange={(color) => onColorChange(color, 'hex-input')}
                                onRgbChange={(color) => onColorChange(color, 'rgb-input')}
                            />

                            <ColorPalettePickerDefaultColors
                                className={classNames('&-color-defaults')}
                                hex={currentColor}
                                defaultColors={defaultColors}
                                onChange={(color) => onColorChange(color, 'default-colors')}
                            />
                            {currentColor === 'stroke' &&
                                <ArgCheckbox
                                    className={classNames('&-transparent-stroke-color')}
                                    size='small'
                                    label={messages.noColor}
                                    value={!strokeColor || strokeColor === 'none'}
                                    onChange={() => onStrokeColorChange?.((!strokeColor || strokeColor === 'none') ? '' : 'none')}
                                />}
                        </div>
                    </div>}
                {!asColorPicker && iconsList &&
                    <IconPicker
                        className={classNames('&-icons')}
                        icons={iconsList!}
                        selectedIcon={iconName}
                        onIconChange={onIconChange}
                    />}
                {!!asBadgePicker &&
                    <div className={classNames('&-badge')}>
                        <div className={classNames('&-badge-size-label')}>
                            <FormattedMessage {...messages.badgeSizeLabel} />
                        </div>
                        <div className={classNames('&-badge-controls')}>
                            <ArgInputNumber
                                step={DEFAULT_BADGE_STYLE.minSize}
                                min={DEFAULT_BADGE_STYLE.minSize}
                                className={classNames('&-badge-controls-size')}
                                value={size}
                                displayRightControl={true}
                                onChange={(size) => onBadgeSizeChange?.(size)}
                            />
                            <ArgCheckbox
                                className={classNames('&-badge-controls-blink')}
                                size='small'
                                label={messages.badgeBlink}
                                value={!!doesBadgeBlink}
                                onChange={onBadgeBlinkChange}
                            />
                        </div>
                    </div>}
            </div>
            {onResetStyles && <div className={classNames('&-footer')}>
                <ArgButton
                    className={classNames('&-reset')}
                    label={messages.resetStylesLabel}
                    onClick={onResetStyles}
                    type='secondary'
                />
            </div>}
        </div>
    );
}

export const CustomColorAndIconPicker = CustomPicker(ColorAndIconPicker);
