import { ReactNode, useEffect, useRef, useState } from 'react';
import { MessageDescriptor } from 'react-intl';

import { ArgTooltip2, renderText, useClassNames } from 'src/components/basic';
import { ROW_HEIGHT } from 'src/settings/universes/common/policy-utils';

import './read-only-filter-input.less';

interface ReadOnlyFilterInputProps {
    value: ReactNode | MessageDescriptor;
    className?: string;
}

export function ReadOnlyFilterInput(props: ReadOnlyFilterInputProps) {
    const {
        value,
        className,
    } = props;

    const classNames = useClassNames('settings-read-only-filter-input');
    const elementRef = useRef<HTMLDivElement>(null);
    const [showTooltip, setShowTooltip] = useState<boolean | null>(null);

    useEffect(() => {
        setShowTooltip(
            elementRef.current && elementRef.current?.offsetWidth < elementRef.current?.scrollWidth,
        );
    }, []);

    return (
        <ArgTooltip2
            placement='right'
            title={showTooltip ? value : ''}
            open={showTooltip ? undefined : false}
        >
            <div
                className={classNames('&-container', className)}
                style={{ maxHeight: ROW_HEIGHT }}
            >
                <div className={classNames('&-text')} ref={elementRef}>
                    {renderText(value)}
                </div>
            </div>
        </ArgTooltip2>
    );
}
