import classNames from 'classnames';
import React, { Dispatch, ReactNode, SetStateAction, useCallback, useMemo } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import { ArgTable2Column, highlightSplit } from 'src/components/basic';
import { ContextualVariable, ContextualVariableType } from 'src/exploration/model/contextual-variable';
import { TableContextualVariable } from '../views/contextual-variables';
import { ContextualVariableMenu, ContextualVariableMenuActions } from './contextual-variable-menu';

const messages = defineMessages({
    name: {
        id: 'settings.contextual-variables.table.name.title',
        defaultMessage: 'Name',
    },
    mode: {
        id: 'settings.contextual-variables.table.mode.title',
        defaultMessage: 'Mode',
    },
    value: {
        id: 'settings.contextual-variables.table.value.title',
        defaultMessage: 'Value',
    },
    description: {
        id: 'settings.contextual-variables.table.description.title',
        defaultMessage: 'Description',
    },
    emptyGroupPlaceHolder: {
        id: 'settings.contextual-variables.columns.empty-group.placeholder',
        defaultMessage: 'Group is empty. Please add a new variable',
    },
    deleteContextualVariableError: {
        id: 'settings.contextual-variables.columns.DeleteContextualVariableError',
        defaultMessage: 'Failed to delete contextual variable',
    },
    active: {
        id: 'settings.contextual-variables.active',
        defaultMessage: 'Active',
    },
    inactive: {
        id: 'settings.contextual-variables.inactive',
        defaultMessage: 'Inactive',
    },
});

export const useTableColumns = (
    setContextualVariables: Dispatch<SetStateAction<ContextualVariable[]>>,
): ArgTable2Column<TableContextualVariable>[] => {
    const intl = useIntl();

    const handleContextualVariableMenuVisibleCondition = useCallback(
        (key: ContextualVariableMenuActions, item: TableContextualVariable) => {
            switch (key) {
                case ContextualVariableMenuActions.AddSubGroup:
                    return item.isGroup;
                case ContextualVariableMenuActions.MoveTo:
                case ContextualVariableMenuActions.Edit:
                case ContextualVariableMenuActions.Delete:
                    return !item.isGroup;
                default:
                    return true;
            }
        },
        [],
    );

    const renderVariableValue = useCallback(
        (item: TableContextualVariable) => {
            let ret: ReactNode;
            if (item.value !== undefined && item.variableInfo) {
                switch (item.variableInfo.type) {
                    case ContextualVariableType.bool:
                        ret = (item.value as boolean)
                            ? intl.formatMessage(messages.active)
                            : intl.formatMessage(messages.inactive);
                        break;
                    case ContextualVariableType.hour:
                    case ContextualVariableType.int:
                    case ContextualVariableType.decimal:
                        ret = intl.formatNumber(item.value as number);
                        break;
                    default:
                        ret = item.value.toString();
                        break;
                }
            }

            return ret;
        },
        [intl],
    );

    const shouldRenderEmptyGroupPlaceholder = (item: TableContextualVariable) => {
        return (
            item.isGroup &&
            item.groupInfos?.isLastLevel &&
            !item.groupInfos.childrenVariableDisplayNames.length
        );
    };

    return useMemo(
        () => [
            {
                key: 'displayName',
                title: <FormattedMessage {...messages.name} />,
                sorter: (a, b) => {
                    if (!a.displayName && !b.displayName) return 0;
                    if (!a.displayName) return 1;
                    if (!b.displayName) return -1;

                    return a.displayName.localeCompare(b.displayName);
                },
                render: function renderName(
                    _value: any,
                    item: TableContextualVariable,
                    index,
                    search,
                ) {
                    return (
                        <div
                            style={item.level ? { marginLeft: `${item.level * 20}px` } : undefined}
                        >
                            {highlightSplit(item.displayName, search)}
                            {shouldRenderEmptyGroupPlaceholder(item) && (
                                <div className={classNames('&-body-table-empty-group-placeholder')}>
                                    <span>
                                        <FormattedMessage {...messages.emptyGroupPlaceHolder} />
                                    </span>
                                </div>
                            )}
                        </div>
                    );
                },
                dataIndex: 'displayName',
                ellipsis: true,
            },
            {
                key: 'mode',
                title: <FormattedMessage {...messages.mode} />,
                sorter: (a, b) => {
                    const aMode = a.mode;
                    const bMode = b.mode;

                    return [aMode, bMode].sort()[0] === aMode ? 1 : -1;
                },
                render: function renderMode(_value: any, item: TableContextualVariable) {
                    return item.mode ? <FormattedMessage {...item.mode} /> : undefined;
                },
                dataIndex: 'mode',
                ellipsis: true,
            },
            {
                key: 'value',
                title: <FormattedMessage {...messages.value} />,
                sorter: (a, b) => {
                    if (!a.value && !b.value) return 0;
                    if (!a.value) return 1;
                    if (!b.value) return -1;

                    return a.value.toString().localeCompare(b.value.toString());
                },
                render: function renderValue(_value: any, item: TableContextualVariable) {
                    return renderVariableValue(item);
                },
                dataIndex: 'value',
                ellipsis: true,
            },
            {
                key: 'description',
                title: <FormattedMessage {...messages.description} />,
                render: function renderDescription(
                    _value: any,
                    item: TableContextualVariable,
                    index,
                    search,
                ) {
                    return highlightSplit(item.description || '', search);
                },
                sorter: (a, b) => {
                    if (!a.description && !b.description) return 0;
                    if (!a.description) return 1;
                    if (!b.description) return -1;

                    return a.description.localeCompare(b.description);
                },
                dataIndex: 'description',
                ellipsis: true,
            },
            {
                key: 'actions',
                title: undefined,
                render: function renderActions(_value: any, item: TableContextualVariable) {
                    return (
                        <ContextualVariableMenu
                            visibleCondition={(key) =>
                                handleContextualVariableMenuVisibleCondition(key, item)}
                            variable={item.original}
                            setContextualVariables={setContextualVariables}
                        />
                    );
                },
                dataIndex: '',
                width: 30,
            },
        ],
        [],
    );
};
