import Debug from 'debug';
import { isObject } from 'lodash';

import { BaseConnector } from '../../utils/connectors/base-connector';
import { ProgressMonitor, setImmutable } from '../../components/basic';
import { ArgonosModule } from '../../components/application/modules';
import { ExtensionDescriptor, ExtensionId, ExtensionsList } from './models';
import { isResponse404 } from '../../components/basic/utils/response-error';

const debug = Debug('framework:extensions:ExtensionsConnector');

export class ExtensionsConnector extends BaseConnector {
    private static instance: ExtensionsConnector;

    static getInstance(): ExtensionsConnector {
        if (!ExtensionsConnector.instance) {
            ExtensionsConnector.instance = new ExtensionsConnector('extensions', undefined);
        }

        return ExtensionsConnector.instance;
    }

    static getDevelopmentURL(argonosModule: ArgonosModule): URL | undefined {
        try {
            const developmentExtensionStringURL = localStorage.getItem(`ARG_DEVELOPER_EXTENSIONS:${argonosModule.id}`);

            if (!developmentExtensionStringURL) {
                return undefined;
            }

            return new URL(developmentExtensionStringURL);
        } catch (error) {
            console.error('Error parsing development URL');

            return undefined;
        }
    }

    static computeCredentials(url: URL, argonosModule: ArgonosModule): RequestCredentials | undefined{
        const urlHostname = url.hostname;
        const urlPort = url.port;

        const developmentExtensionURL = ExtensionsConnector.getDevelopmentURL(argonosModule);

        if (!developmentExtensionURL) {
            return undefined;
        }

        if (urlHostname === developmentExtensionURL.hostname && urlPort === developmentExtensionURL.port) {
            return 'omit';
        }

        return undefined;
    }

    async loadExtensions(argonosModule: ArgonosModule, progressMonitor: ProgressMonitor): Promise<ExtensionsList> {
        const extensionListURL = new URL(
            `${argonosModule.apiURL}/extensions/active-extensions`,
            document.location.toString(),
        ).toString();
        const extensionsBaseURL = `${extensionListURL}/`;
        const extensionDevelopmentURL = ExtensionsConnector.getDevelopmentURL(argonosModule);
        const result = await this.loadExtensionsFromList(extensionListURL, extensionsBaseURL, argonosModule, progressMonitor, extensionDevelopmentURL);

        return result;
    }

    async loadExtensionsFromList(extensionListURL: string, extensionsBaseURL: string, argonosModule: ArgonosModule, progressMonitor: ProgressMonitor, extensionDevelopmentURL?: URL): Promise<ExtensionsList> {
        debug('loadExtensions', 'listURL=', extensionListURL, 'extensionsBaseURL=', extensionsBaseURL);


        let extensions: any;
        try {
            extensions = await this.request(extensionListURL, {
                forceURL: true,
                credentials: ExtensionsConnector.computeCredentials(new URL(extensionListURL), argonosModule),
            }, progressMonitor);
        } catch (x) {
            if (progressMonitor.isCancelled) {
                throw x;
            }

            console.error('Can not load plugin list', x);

            throw x;
        }

        const result:ExtensionsList = {
            extensions: extensions.moduleExtensions,
            extensionsBaseURL,
        };

        return result;
    }

    async loadExtensionManifest(extensionURL: string, argonosModule: ArgonosModule, extensionName: ExtensionId, progressMonitor: ProgressMonitor): Promise<ExtensionDescriptor> {
        const json = await this.request(extensionURL, {
            forceURL: true,
            noCache: false,
            credentials: ExtensionsConnector.computeCredentials(new URL(extensionURL), argonosModule),
        }, progressMonitor);

        const extensionDescriptor: ExtensionDescriptor = {
            ...json,

            baseURL: new URL('./', extensionURL).toString(),
            argonosModule,
        };
        if (extensionDescriptor.name !== extensionName) {
            console.warn(`Name of extension ${extensionName}' is not the same in the manifest.`);
            extensionDescriptor.name = extensionName;
        }

        const result = setImmutable(extensionDescriptor);

        return result;
    }

    async loadExtensionMain(mainURL: string, argonosModule: ArgonosModule, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<string> {
        const mainSource: string = await this.request(mainURL, {
            forceURL: true,
            noCache: false,
            credentials: ExtensionsConnector.computeCredentials(new URL(mainURL), argonosModule),
        }, progressMonitor);

        return mainSource;
    }

    async loadExtensionLocale(mainURL: string, argonosModule: ArgonosModule, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<Record<string, string>|undefined> {
        try {
            const json: Record<string, string> = await this.request(mainURL, {
                forceURL: true,
                verifyJSONResponse: true,
                noCache: false,
                credentials: ExtensionsConnector.computeCredentials(new URL(mainURL), argonosModule),
            }, progressMonitor);

            if (!isObject(json)) {
                throw new Error('Invalid locale content');
            }

            return json;
        } catch (error) {
            if (isResponse404(error)) {
                return undefined;
            }

            console.error('Can not load locale for', mainURL);
            throw error;
        }
    }
}
