import { defineMessages } from 'react-intl';
import React, { lazy, ReactElement, Suspense } from 'react';
import { Navigate, Route } from 'react-router-dom';

import { ArgonosModulesRegistry } from '../components/application/argonos-modules-registry';
import { Environment } from '../utils/environment';
import { ADMINISTRATION_ROOT_URL } from '../components/containers/routes-urls';
import { ADMIN_PERMISSIONS_REQUIRED } from './permissions/permissions';
import { ArgonosModuleRouterProps } from '../components/containers/authenticated-apps-router';
import { LoadingSuspense } from '../components/containers/loading-suspense';
import { getAdministrationApi } from '../utils/connectors/api-url';
import { ArgonosModule } from '../components/application/modules';
import { ADMIN_PERMISSION_CONSTRAINTS } from './permissions/permission-constraints';

const LazyAdminRouter = lazy(() => import('./utils/routing/admin-router'));

const FORCE_SUSPENSE_LOADING = false;


const messages = defineMessages({
    adminApplication: {
        id: 'common.applications.Admin',
        defaultMessage: 'Administration',
    },
});


function AdminApp(props: ArgonosModuleRouterProps) {
    const { className } = props;

    if (FORCE_SUSPENSE_LOADING) {
        return <LoadingSuspense />;
    }

    return (
        <Suspense fallback={<LoadingSuspense />}>
            <LazyAdminRouter
                className={className}
            />
        </Suspense>
    );
}

const ADMINISTRATION_MODULE: ArgonosModule = {
    id: 'chapsVision.Administration',
    subType: 'Administration',
    enabled: Environment.isAdministrationEnabled(),

    routeURL: ADMINISTRATION_ROOT_URL,

    requiredPermissions: ADMIN_PERMISSIONS_REQUIRED,
    permissionConstrains: ADMIN_PERMISSION_CONSTRAINTS,

    apiURL: getAdministrationApi(),

    iconURL: 'icon-progress-wrench',
    name: messages.adminApplication,
    documentationURL: Environment.docServerUrlAdministration,

    getRoutes(): ReactElement[] {
        return [
            <Route
                key='admin'
                path={ADMINISTRATION_ROOT_URL}
                element={<Navigate to='./environments' />}
            />,
            <Route
                key='admin/*'
                path={`${ADMINISTRATION_ROOT_URL}/*`}
                element={(
                    <AdminApp argonosModule={ADMINISTRATION_MODULE} />
                )}
            />,
        ];
    },

    hasRoles: true,
    hasUserRoles: true,
    hasGroupRoles: true,
    hasUserPermissions: true,
    scope: 'admin',
};

ArgonosModulesRegistry.getInstance().register(ADMINISTRATION_MODULE);
