import { useCallback } from 'react';
import { defineMessages, FormattedDate } from 'react-intl';

import {
    ArgRenderedText,
    ArgToolbar,
    ClassValue,
    hasOnlyContainer,
    KeyBindingDescriptor,
    ToolContext,
    TopBarProgress,
    useClassNames,
    useGlobalProgress,
    useToolContext,
    useToolItem,
    useToolNodes,
} from '../../basic';
import { UsersCacheScope } from '../../caches/users-repository';
import { Applications } from './applications';
import { useUserDropdown } from './use-user-dropdown';
import { useCurrentArgonosModule } from 'src/components/application/argonos-current-module';

import './top-bar.less';


const messages = defineMessages({
    backHomePage: {
        id: 'common.top-bar.BackHomePage',
        defaultMessage: 'Back to home page',
    },
    documentation: {
        id: 'common.top-bar.Documentation',
        defaultMessage: 'Documentation',
    },
});

interface TopBarProps<T> {
    className?: ClassValue;
    logoutKeyBinding?: KeyBindingDescriptor;
    title?: ArgRenderedText;
    homePage?: string;
    disableBackTooltip?: boolean;
    toolContext?: ToolContext<T>;
    environmentContext: T;
}

export function TopBar<T = undefined>(props: TopBarProps<T>) {
    const {
        className,
        title,
        homePage,
        disableBackTooltip,
        toolContext: externalToolContext,
        environmentContext,
    } = props;

    const classNames = useClassNames('common-top-bar');

    const internalToolContext = useToolContext<T>('common.top-bar.toolbar');
    const toolContext = externalToolContext || internalToolContext;

    const argonosModule = useCurrentArgonosModule();

    const handleDocumentation = useCallback(() => {
        if (!argonosModule.documentationURL) {
            return;
        }
        window.open(argonosModule.documentationURL, '_blank');
    }, [argonosModule.documentationURL]);

    const applicationsCustomRender = useCallback(() => {
        return (
            <Applications
                currentModuleId={argonosModule.id}
                backUrl={homePage}
                title={title}
                backTooltip={disableBackTooltip ? undefined : messages.backHomePage}
                className={classNames('&-left-application')}
            />
        );
    }, [classNames, disableBackTooltip, homePage, title, argonosModule]);

    useToolItem<T>(toolContext, {
        path: 'left/applications',
        order: 500,
        type: 'custom',
    }, {
        customRender: applicationsCustomRender,
    });

    useToolItem(toolContext, {
        path: 'left/end',
        order: 1000,
        type: 'marker',
    });

    useToolItem(toolContext, {
        path: 'center/begin',
        order: 0,
        type: 'marker',
    });

    useToolItem(toolContext, {
        path: 'center/end',
        order: 1000,
        type: 'marker',
    });

    useToolItem(toolContext, {
        path: 'right/begin',
        order: 0,
        type: 'marker',
    });

    const renderCurrentDate = useCallback(() => {
        const today = new Date();

        return (
            <span className={classNames('&-right-current-date')}>
                <FormattedDate
                    value={today}
                    day='numeric'
                    weekday='long'
                    month='long'
                />
            </span>
        );
    }, [classNames]);

    useToolItem(toolContext, {
        path: 'right/date',
        order: 10,
        type: 'custom',
        visible: false,
    }, {
        customRender: renderCurrentDate,
    });

    useToolItem(toolContext, {
        path: 'right/documentation',
        order: 800,
        type: 'button',
        tooltip: messages.documentation,
        icon: 'icon-help-circle-outline',
    }, {
        visible: !!argonosModule.documentationURL,
        onClick: handleDocumentation,
    });

    useUserDropdown(toolContext, 'right/user', 1000);

    useToolItem(toolContext, {
        path: 'right/end',
        order: 1200,
        type: 'marker',
    });

    const [centerTree] = useToolNodes<T>(toolContext, environmentContext, 'center');
    const hasCenter = !hasOnlyContainer(centerTree, environmentContext);

    const cls = {
        'has-center': hasCenter,
    };

    return (
        <UsersCacheScope name='top-bar'>
            <header className={classNames('&', cls, className)}>
                <TopBarGlobalProgress className={classNames('&-progress')} />
                <ArgToolbar<T>
                    key='left'
                    className={classNames('&-left')}
                    prefix='left'
                    toolbarContext={toolContext}
                    environmentContext={environmentContext}
                />
                {hasCenter && (
                    <ArgToolbar<T>
                        key='center'
                        className={classNames('&-center')}
                        prefix='center'
                        toolbarContext={toolContext}
                        environmentContext={environmentContext}
                    />
                )}
                <ArgToolbar<T>
                    key='right'
                    className={classNames('&-right')}
                    prefix='right'
                    toolbarContext={toolContext}
                    environmentContext={environmentContext}
                />
            </header>
        </UsersCacheScope>
    );
}

function TopBarGlobalProgress({ className }: {
    className: ClassValue;
}) {
    const showGlobalProgress = useGlobalProgress();

    return (
        <TopBarProgress
            className={className}
            visible={showGlobalProgress}
        />
    );
}
