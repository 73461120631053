import { cloneDeep, set } from 'lodash';
import { ReactNode, useState } from 'react';
import { defineMessages, FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';

import { ArgCombo, ArgIcon, isMessageDescriptor, useClassNames } from 'src/components/basic';
import { useAccessRuleStateContext } from '../../../../common/providers/policy-rules-provider';
import { EffectFilter } from '../../../../../models/policy';
import { getPermissions } from './utils';
import { ReadOnlyFilterInput } from 'src/settings/universes/common/rules/read-only-filter-input/read-only-filter-input';
import { DropdownTooltipField } from '../../../../common/dropdown-tooltip-field/dropdown-tooltip-field';

import './rule-effect.less';

const messages = defineMessages({
    select: {
        id: 'settings.rule-effect.operator.select',
        defaultMessage: 'Select',
    },
    add: {
        id: 'settings.rule-effect.add',
        defaultMessage: 'add',
    },
    remove: {
        id: 'settings.rule-effect.remove',
        defaultMessage: 'remove',
    },
    set: {
        id: 'settings.rule-effect.set',
        defaultMessage: 'set',
    },
    read: {
        id: 'settings.rule-effect.read',
        defaultMessage: 'read',
    },
    create: {
        id: 'settings.rule-effect.create',
        defaultMessage: 'create',
    },
    delete: {
        id: 'settings.rule-effect.delete',
        defaultMessage: 'delete',
    },
    clear: {
        id: 'settings.rule-effect.clear',
        defaultMessage: 'clear',
    },
    append: {
        id: 'settings.rule-effect.append',
        defaultMessage: 'append',
    },
    write: {
        id: 'settings.rule-effect.write',
        defaultMessage: 'write',
    },
    rights: {
        id: 'settings.rule-effect.rights',
        defaultMessage: 'the rights to',
    },
});

interface OperatorDropdownProps {
    targetType: 'object' | 'property';
    effectValue: EffectFilter;
    index: number;
}

export interface Operator {
    key: string;
    label: MessageDescriptor;
}

const OPERATORS: Operator[] = [
    {
        key: '+',
        label: messages.add,
    },
    {
        key: '-',
        label: messages.remove,
    },
    {
        key: '=',
        label: messages.set,
    },
];

export interface Permission {
    key: string;
    label: ReactNode;
}

const PERMISSIONS: Permission[] = [
    { key: 'read', label: <FormattedMessage {...messages.read} /> },
    { key: 'create', label: <FormattedMessage {...messages.create} /> },
    { key: 'delete', label: <FormattedMessage {...messages.delete} /> },
    { key: 'clear', label: <FormattedMessage {...messages.clear} /> },
    { key: 'append', label: <FormattedMessage {...messages.append} /> },
    { key: 'write', label: <FormattedMessage {...messages.write} /> },
];

const objectPermissions = ['read', 'create', 'delete'];
const propertiesPermissions = ['read', 'clear', 'append', 'write'];


export function OperatorDropdown(props: OperatorDropdownProps) {
    const {
        targetType,
        effectValue,
        index,
    } = props;

    const intl = useIntl();
    const classNames = useClassNames('settings-rule-effect');
    const { setRule, editable } = useAccessRuleStateContext();

    const validPermissions: Permission[] = (targetType === 'object' ? objectPermissions : propertiesPermissions)
        .map((k) => PERMISSIONS.find((p) => p.key === k)!);

    const permissions = getPermissions(effectValue.Permissions, validPermissions, OPERATORS);

    const [dropDownSelected, setDropdownSelected] = useState<Permission[]>(permissions?.permissions);

    return (
        <>
            <ArgIcon name='icon-arrow-right' className={classNames('&-arrow-icon')} />
            {editable ? (
                <ArgCombo<Operator>
                    className={classNames('&-universe-items-filters')}
                    hideTags={true}
                    placeholder={messages.select}
                    items={OPERATORS}
                    getItemKey='key'
                    getItemLabel='label'
                    value={permissions?.operator}
                    size='small'
                    popoverClassName='arg-input-popover-no-max-width'
                    onChange={(value) => {
                        setRule((currentRule) => {
                            const newOperator = value.key;
                            if (newOperator === permissions?.operator) {
                                return currentRule;
                            }

                            return set(cloneDeep(currentRule), `Effects[${index}]`, {
                                [targetType]: { ...effectValue, Permissions: [newOperator] },
                            });
                        });
                    }}
                />
            ) : (
                <ReadOnlyFilterInput
                    className={classNames('&-universe-items-short-filter')}
                    value={permissions?.operator?.label}
                />
            )}

            {permissions?.operator && (
                <div className={classNames('&-rights')}>{intl.formatMessage(messages.rights)}</div>
            )}

            {permissions?.operator &&
                (editable ? (
                    <ArgCombo<Permission>
                        className={classNames('&-universe-items-permissions')}
                        hideTags={true}
                        placeholder={messages.select}
                        items={validPermissions}
                        cardinality='zeroMany'
                        value={dropDownSelected}
                        getItemKey='key'
                        getItemLabel='label'
                        renderInput={() => (
                            <DropdownTooltipField
                                className={classNames('&-universe-items-filters-input-text')}
                                value={joinNodes(permissions?.permissions.map((p) => p?.label))}
                            />
                        )}
                        size='small'
                        popoverClassName='arg-input-popover-no-max-width'
                        onChange={(value) => {
                            setDropdownSelected(value);
                        }}
                        onPopoverClose={(value) => {
                            if (!Array.isArray(value)) {
                                return setRule((currentRule) => {
                                    return set(cloneDeep(currentRule), `Effects[${index}]`, {
                                        [targetType]: {
                                            ...effectValue,
                                            Permissions: [permissions.operator.key],
                                        },
                                    });
                                });
                            }
                            const newPermissions = value;

                            setRule((currentRule) => {
                                if (permissions.operator.key === '=' && newPermissions.length !== PERMISSIONS.length) {
                                    return set(cloneDeep(currentRule), `Effects[${index}]`, {
                                        [targetType]: {
                                            ...effectValue,
                                            Permissions: newPermissions.map(
                                                (permission, idx) =>
                                                    `${idx === 0 ? '=' : '+'}${permission.key}`,
                                            ),
                                        },
                                    });
                                }

                                if (newPermissions.length === PERMISSIONS.length) {
                                    return set(cloneDeep(currentRule), `Effects[${index}]`, {
                                        [targetType]: {
                                            ...effectValue,
                                            Permissions: `${permissions.operator.key}all`,
                                        },
                                    });
                                }

                                return set(cloneDeep(currentRule), `Effects[${index}]`, {
                                    [targetType]: {
                                        ...effectValue,
                                        Permissions: newPermissions.map(
                                            (permission) =>
                                                `${permissions.operator.key}${permission.key}`,
                                        ),
                                    },
                                });
                            });
                        }}
                    />
                ) : (
                    <ReadOnlyFilterInput
                        className={classNames('&-universe-items-permissions')}
                        value={joinNodes(permissions?.permissions.map((p) => p?.label))}
                    />
                ))}
        </>
    );
}

function joinNodes(nodes: any[]): ReactNode[] {
    const ps = nodes.reduce((acc: ReactNode[], node, index) => {
        if (acc.length) {
            acc.push(', ');
        }

        if (isMessageDescriptor(node)) {
            acc.push(<FormattedMessage key={index} {...node} />);
        } else {
            acc.push(node);
        }

        return acc;
    }, [] as ReactNode[]);

    return ps;
}
