import React, { createContext, useContext, useMemo, useRef, useState } from 'react';
import { MessageDescriptor } from 'react-intl';

import { SettingsConnector } from 'src/settings/connectors/settings-connector';
import { ProgressMonitor, useEffectAsync } from '../basic';
import { isResponse404 } from '../basic/utils/response-error';
import { ArgonosApplicationId, useApplicationBranding } from './argonos-application-branding';
import { useCurrentArgonosModule } from './argonos-current-module';
import { ArgonosModuleId } from './modules';
import { ArgonosModulesRegistry } from './argonos-modules-registry';

const DISABLE_APPLICATION_DESCRIPTOR = localStorage.ARG_NO_BRANDING;

export interface ArgonosModuleBranding {
    brandingName?: string | MessageDescriptor;
    brandingLogoURL?: string;
    brandingIconURL?: string;
    brandingColor?: string;
    brandingDocumentTitle?: string;
}

export type ArgonosModulesBranding = Record<ArgonosModuleId, ArgonosModuleBranding | null>;

interface ArgonosModulesBrandingContext {
    modulesBranding: ArgonosModulesBranding;
    setModulesBranding: React.Dispatch<React.SetStateAction<ArgonosModulesBranding>>;
}

const ArgonosModulesBrandingContext = createContext<ArgonosModulesBrandingContext | undefined>(undefined);

export function ArgonosModulesBrandingProvider({ children }: { children: React.ReactNode }) {
    const [modulesBranding, setModulesBranding] = useState<ArgonosModulesBranding>({});

    return (
        <ArgonosModulesBrandingContext.Provider value={{ modulesBranding, setModulesBranding }}>
            {children}
        </ArgonosModulesBrandingContext.Provider>
    );
}

export function useArgonosModulesBrandingContext() {
    const context = useContext(ArgonosModulesBrandingContext);

    if (!context) {
        throw new Error('Using ArgonosModulesBranding Context outside of its Provider');
    }

    return context;
}

export function useArgonosModuleBranding(argonosModuleId: ArgonosModuleId): [ArgonosModuleBranding, ProgressMonitor | undefined] {
    const applicationBranding = useApplicationBranding();
    const { modulesBranding, setModulesBranding } = useArgonosModulesBrandingContext();
    const moduleRequestedRef = useRef(argonosModuleId in modulesBranding);

    const [progressMonitor] = useEffectAsync(async (progressMonitor: ProgressMonitor) => {
        if (moduleRequestedRef.current === true) {
            return;
        }

        moduleRequestedRef.current = true;
        const newModule = await getArgonosModuleBranding(applicationBranding.applicationId, argonosModuleId, progressMonitor);

        setModulesBranding((prev) => ({ ...prev, [argonosModuleId]: newModule }));
    }, [applicationBranding.applicationId, argonosModuleId, setModulesBranding]);

    const mergedBranding = useMemo<ArgonosModuleBranding>(() => {
        const moduleBranding = modulesBranding[argonosModuleId];
        const argonosModule = ArgonosModulesRegistry.getInstance().getById(argonosModuleId);
        if (!argonosModule) {
            throw new Error(`ArgonosModule ${argonosModuleId} is unknown`);
        }

        const result: ArgonosModuleBranding = {
            brandingName: moduleBranding?.brandingName || argonosModule.name,
            brandingColor: moduleBranding?.brandingColor || argonosModule.color,
            brandingIconURL: moduleBranding?.brandingIconURL || argonosModule.iconURL,
            brandingLogoURL: moduleBranding?.brandingLogoURL || argonosModule.logoURL,
        };

        return result;
    }, [modulesBranding, argonosModuleId]);

    return [mergedBranding, progressMonitor];
}

export async function getArgonosModuleBranding(
    argonosApplicationId: ArgonosApplicationId,
    argonosModuleId: ArgonosModuleId,
    progressMonitor: ProgressMonitor,
): Promise<ArgonosModuleBranding | null> {
    if (!argonosModuleId || DISABLE_APPLICATION_DESCRIPTOR) {
        return null;
    }

    const moduleBrandingKey = `${argonosApplicationId}-${argonosModuleId}`;

    let descriptor;
    try {
        descriptor = await SettingsConnector.getInstance().getAppSettingsJSON(moduleBrandingKey, progressMonitor);
    } catch (x) {
        if (isResponse404(x)) {
            return null;
        }
        console.error('Can not get customized module settings', x);

        throw x;
    }

    const ret: ArgonosModuleBranding = {
        ...descriptor,
        brandingLogoURL: descriptor?.hasLogo && SettingsConnector.getInstance().computeAppSettingsURL(`${moduleBrandingKey}-logo`),
        brandingIconURL: descriptor?.hasIcon && SettingsConnector.getInstance().computeAppSettingsURL(`${moduleBrandingKey}-icon`),
        moduleId: argonosModuleId,
    };

    return ret;
}

export function useCurrentArgonosModuleBranding() {
    const module = useCurrentArgonosModule();
    const branding = useArgonosModuleBranding(module.id);

    return branding;
}
