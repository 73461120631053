import { useCallback, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { cloneDeep, set } from 'lodash';

import { ArgCheckbox, useClassNames } from 'src/components/basic';
import { RuleEffect as RuleEffectType, RuleProcessed } from '../../../../../models/policy';
import { TargetOrPropertyDropdown } from './target-or-property-dropdown';
import { UniverseItemDropdown } from './universe-item-dropdown';
import { OperatorDropdown } from './operator-dropdown';
import { useAccessRuleStateContext } from '../../../../common/providers/policy-rules-provider';
import { DeleteFilterButton } from '../../../../common/effects/delete-filter-button';
import { MetapropertyDropdowns } from './metaproperty-dropdowns';
import { ROW_HEIGHT } from 'src/settings/universes/common/policy-utils';

import './rule-effect.less';

export const messages = defineMessages({
    for: {
        id: 'settings.rule-effect.for',
        defaultMessage: 'For',
    },
    includeMetaproperties: {
        id: 'settings.rule-effect.includeMetaproperties',
        defaultMessage: 'Include the metaproperty in the filter',
    },
});

interface RuleEffectProps {
    effect: RuleEffectType;
    index: number;
}

export function RuleEffect(props: RuleEffectProps) {
    const { effect, index } = props;
    const intl = useIntl();
    const classNames = useClassNames('settings-rule-effect');
    const { editable, setRule } = useAccessRuleStateContext();

    const metapropertiesIncluded = useMemo(() => {
        return !!effect?.property?.targets?.[0]?.meta;
    }, [effect?.property?.targets]);

    const targetType = effect.object ? 'object' : effect.property ? 'property' : undefined;
    const effectValue = effect.object || effect.property || undefined;

    const deleteEffectFilter = useCallback(() => {
        setRule((currentRule: RuleProcessed) => {
            return {
                ...currentRule,
                Effects: currentRule.Effects.filter((effect, idx) => idx !== index),
            };
        });
    }, [index, setRule]);

    const shouldDisplayOperatorFilter =
        targetType === 'object' ||
        (targetType === 'property' && effectValue?.match /*&& effectValue.match.length > 0*/);

    return (
        <>
            <ArgCheckbox
                value={metapropertiesIncluded}
                onChange={(val) => {
                    setRule((currentRule) => {
                        if (val) {
                            return set(
                                cloneDeep(currentRule),
                                `Effects[${index}].property.targets`,
                                [{ meta: {} }],
                            );
                        } else {
                            return set(
                                cloneDeep(currentRule),
                                `Effects[${index}].property.targets`,
                                [],
                            );
                        }
                    });
                }}
                size='node'
                label={intl.formatMessage(messages.includeMetaproperties)}
                disabled={!editable || targetType === 'object'}
                className={classNames('&-checkbox')}
            />
            <div className={classNames('&-row-container')} style={{ height: ROW_HEIGHT }}>
                <div className={classNames('&-for-text')}>{intl.formatMessage(messages.for)}</div>
                <TargetOrPropertyDropdown targetType={targetType} index={index} effect={effect} />
                {targetType === 'property' && effectValue && (
                    <UniverseItemDropdown
                        effectValue={effectValue}
                        targetType={targetType}
                        index={index}
                    />
                )}
                {metapropertiesIncluded && effectValue && targetType === 'property' && (
                    <MetapropertyDropdowns effectValue={effectValue} index={index} />
                )}
                {shouldDisplayOperatorFilter && effectValue && (
                    <OperatorDropdown
                        effectValue={effectValue}
                        targetType={targetType}
                        index={index}
                    />
                )}
                {editable && <DeleteFilterButton onDeleteRule={deleteEffectFilter} />}
            </div>
        </>
    );
}
