import { Dispatch, SetStateAction, useState } from 'react';
import { defineMessages } from 'react-intl';
import { v4 } from 'uuid';

import { ArgButton, ArgInputSearch, useClassNames, useArgNotifications } from 'src/components/basic';
import { ScopeProcessed } from '../../../../models/policy';

import './application-condition-page-toolbar.less';

const messages = defineMessages({
    newApplicationCondition: {
        id: 'settings.application-conditions.toolbar.new-application-condition',
        defaultMessage: 'New',
    },
    savingPolicyError: {
        id: 'settings.application-conditions.toolbar.savingPolicyError',
        defaultMessage: 'An error occurred while saving the policy',
    },
});

interface ApplicationConditionPageToolbarProps {
    setScopes: Dispatch<SetStateAction<ScopeProcessed[]>>;
    setEditableScopeIds: Dispatch<SetStateAction<Set<string>>>;
    setUnsavedScopeIds: Dispatch<SetStateAction<Set<string>>>;
    editableScopeIds: Set<string>;
}

export function ApplicationConditionPageToolbar(props: ApplicationConditionPageToolbarProps) {
    const {
        setScopes,
        setEditableScopeIds,
        setUnsavedScopeIds,
        editableScopeIds,
    } = props;

    const classNames = useClassNames('settings-application-condition-page-toolbar');
    const notifications = useArgNotifications();

    const [loading, setLoading] = useState(false);

    const addScope = async () => {
        try {
            setLoading(true);

            const newScopeId = v4();
            const newScope = { Type: undefined, Data: {}, id: newScopeId };

            setScopes((currentScopes) => [...(currentScopes ?? []), newScope]);
            setEditableScopeIds((currentScopeIds) => {
                return new Set(currentScopeIds).add(newScopeId);
            });
            setUnsavedScopeIds((currentScopeIds) => {
                return new Set(currentScopeIds).add(newScopeId);
            });
            setLoading(false);
        } catch (error) {
            notifications.snackError({ message: messages.savingPolicyError }, error as Error);
            setLoading(false);
        }
    };

    return (
        <div className={classNames('&')}>
            <div className={classNames('&-buttons-group')}>
                <div className={classNames('&-search-bar-container')}>
                    <ArgInputSearch className={classNames('&-search-bar')} />
                </div>
            </div>
            <ArgButton
                size='medium'
                type='primary'
                icon='icon-plus'
                label={messages.newApplicationCondition}
                onClick={() => addScope()}
                disabled={loading || editableScopeIds.size > 0}
                loading={loading}
            />
        </div>
    );
}
