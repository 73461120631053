import React, { useCallback, useEffect, useMemo } from 'react';
import { Col, Row, Typography } from 'antd';
import { defineMessages } from 'react-intl';
import Debug from 'debug';

import {
    ArgButton,
    ArgFormattedMessage,
    ArgImage,
    ArgPageConfig,
    GLOBAL_PM,
    isMessageDescriptor,
    ProgressMonitor,
    renderText,
    ThreeDotsLoading,
    useCallbackAsync,
    useClassNames,
    useSetTimeout,
} from '../../basic';
import { Environment } from '../../../utils/environment';
import { LoginForm } from './login-form';
import { computePageAccess, getLastExecutedModule } from '../../caches/pages-access';
import { computeOAuthLoginURL } from '../../../utils/connector';
import { ArgonosModulesRegistry } from '../../application/argonos-modules-registry';
import { useApplicationBranding } from '../../application/argonos-application-branding';
import { ArgonosModule } from '../../application/modules';

import './login.less';


const debug = Debug('common:login:Login');

const messages = defineMessages({
    continueButton: {
        id: 'common.login.ContinueButton',
        defaultMessage: 'Continue',
    },
    welcomeLabel: {
        id: 'common.login.welcomeLabel',
        defaultMessage: 'Welcome',
    },
    pageWelcomeTitle: {
        id: 'common.login.pageWelcomeTitle',
        defaultMessage: 'Welcome',
    },
});

export function Login() {
    const classNames = useClassNames('common-login-page');

    const handleContinue = useCallback(async (progressMonitor: ProgressMonitor) => {
        const previousArgonosModule = await computePageAccess(progressMonitor);
        if (previousArgonosModule?.routeURL) {
            document.location.replace(previousArgonosModule.routeURL);

            return;
        }

        let redirectTo: string | undefined;
        if (Environment.redirectTo) {
            redirectTo = Environment.redirectTo;

            document.location.replace(redirectTo);

            return;
        }

        let argonosModule = getLastExecutedModule();
        if (argonosModule?.enabled === false || !argonosModule?.routeURL) {
            argonosModule = ArgonosModulesRegistry.getInstance().listEnabled()
                .orderBy((argonosModule) => (argonosModule.loginOrder || 0), 'desc')
                .find((m) => !!m.routeURL)
                .value();
        }

        redirectTo = argonosModule?.routeURL;

        if (!redirectTo) {
            return;
        }

        debug('handleContinue', 'Replace to', redirectTo);

        document.location.replace(redirectTo);
    }, []);

    const [handleContinueOAuth] = useCallbackAsync(async (progressMonitor: ProgressMonitor) => {
        await handleContinue(progressMonitor);
    }, [handleContinue], undefined, undefined, GLOBAL_PM);

    const [handleTimerOAuth] = useCallbackAsync(async (progressMonitor: ProgressMonitor) => {
        const welcomeArgonosModule = await computePageAccess(progressMonitor);
        if (welcomeArgonosModule) {
            debug('handleTimerOAuth:pageAccess', 'Replace to', welcomeArgonosModule.routeURL);

            document.location.replace(welcomeArgonosModule.routeURL);

            return;
        }

        if (Environment.redirectTo) {
            debug('handleTimerOAuth:RedirectTo', 'Replace to', Environment.redirectTo);

            document.location.replace(Environment.redirectTo);

            return;
        }

        const loginUrl = computeOAuthLoginURL(document.location.origin);

        debug('handleTimerOAuth:OAuthLogin', 'Replace to', loginUrl);
        document.location.replace(loginUrl);
    }, [], undefined, undefined, GLOBAL_PM);

    const timerCallback = useSetTimeout(1000);

    const enabledModule = useMemo<ArgonosModule|undefined>(() => {
        let enabledModule = getLastExecutedModule();
        if (enabledModule?.enabled !== false && enabledModule?.loginTitle) {
            return enabledModule;
        }

        enabledModule = ArgonosModulesRegistry.getInstance().listEnabled()
            .filter((argonosModule) => (!!argonosModule.loginTitle))
            .orderBy((argonosModule) => (argonosModule.loginOrder || 0), 'desc')
            .first()
            .value();

        return enabledModule;
    }, []);

    useEffect(() => {
        if (!Environment.apiOAuth) {
            return;
        }

        timerCallback(handleTimerOAuth);
    }, []);

    let body;
    if (Environment.apiOAuth) {
        body = <>
            {/* Api login with OAuth */}
            <div className={classNames('&-oauth')}>
                <ArgFormattedMessage message={messages.welcomeLabel} />

                <ThreeDotsLoading />
            </div>
            <ArgButton
                hidden={true}
                data-testid='login-button'
                size='large'
                htmlType='submit'
                className={classNames('&-form-item', '&-form-submit-button')}
                label={messages.continueButton}
                onClick={handleContinueOAuth}
                autoFocus={true}
            />
        </>;
    } else {
        body = <LoginForm onContinue={handleContinue} />;
    }

    const applicationBranding = useApplicationBranding();
    const applicationName: string = isMessageDescriptor(applicationBranding?.brandingName)
        ? String(applicationBranding?.brandingName.defaultMessage)
        : (applicationBranding?.brandingName as string);


    return <ArgPageConfig pageSubTitle={messages.pageWelcomeTitle}>
        <div className={classNames('&')}>
            <div className={classNames('&-left')}>
                <div className={classNames('&-left-content')}>
                    {!localStorage.ANONYMIZE && <ArgImage
                        src={applicationBranding?.brandingLogoURL}
                        alt={applicationName}
                        placeholder={false}
                        className={classNames('&-left-logo')}
                    />}
                    {body}
                </div>
            </div>

            <div className={classNames('&-right')}>
                <div className={classNames('&-right-background')}>
                    <div className={classNames('&-right-background-light-diamond')} />
                    <div className={classNames('&-right-background-dark-diamond')} />
                    <div className={classNames('&-right-background-cut-diamond')} />
                </div>
                <Row className={classNames('&-right-content')} justify='center' align='middle'>
                    <Col span={13} className={classNames('&-right-content-container')}>
                        <Typography.Title className={classNames('&-right-content-title')}>
                            {renderText(enabledModule?.loginTitle)}
                        </Typography.Title>
                        <Typography.Text className={classNames('&-right-content-text')}>
                            {renderText(enabledModule?.loginMessage)}
                        </Typography.Text>
                    </Col>
                </Row>
            </div>
        </div>
    </ArgPageConfig>;
}
