import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { isFunction } from 'lodash';
import { defineMessages, useIntl } from 'react-intl';

import {
    ApplicationConditionPageToolbar,
} from '../../common/application-conditions/application-condition-page-toolbar/application-condition-page-toolbar';
import {
    ArgButton,
    ProgressMonitor,
    useCallbackAsync,
    useClassNames,
    useEffectAsync,
    useMemoAsync,
    useArgNotifications,
} from 'src/components/basic';
import { getValuationPolicyWithActionsIds } from './utils';
import { LoadingPane } from 'src/components/common/panes/loading-pane';
import {
    ApplicationConditionBlock,
} from '../../common/application-conditions/application-condition-block/application-condition-block';
import { Scope, ScopeProcessed } from '../../../models/policy';
import { ValuationPolicyId, ValuationPolicyProcessed } from 'src/settings/models/valuation-policy';
import { EmptyPane } from 'src/components/common/panes/empty-pane';
import explorationSettingsConnector from 'src/settings/connectors/exploration-settings-connector';

import './valuation-policy-application-conditions-view.less';

const messages = defineMessages({
    fetchData: {
        id: 'settings.valuation-policy-application-conditions.fetching-policy',
        defaultMessage: 'Loading policy',
    },
    fetchDataError: {
        id: 'settings.valuation-policy-application-conditions.fetching-policy.error',
        defaultMessage: 'An error occurred while fetching the policy',
    },
    publish: {
        id: 'settings.valuation-policy-application-conditions.publish-button',
        defaultMessage: 'Publish',
    },
    publishPolicyError: {
        id: 'settings.valuation-policy-application-conditions.publishPolicyError',
        defaultMessage: 'An error occurred while publishing the policy',
    },
    editPolicyError: {
        id: 'settings.valuation-policy-application-conditions.editPolicyError',
        defaultMessage: 'An error occurred while editing the policy',
    },
    publishPolicySuccess: {
        id: 'settings.valuation-policy-application-conditions.publishPolicySuccess',
        defaultMessage: 'Valuation policy published',
    },
    publishPolicySuccessDescription: {
        id: 'settings.valuation-policy-application-conditions.publishPolicySuccessDescription',
        defaultMessage: 'The valuation policy has successfully been published',
    },
    emptyConditions: {
        id: 'settings.valuation-policy-application-conditions.empty',
        defaultMessage: 'No application conditions',
    },
});

export function ValuationPolicyApplicationConditionsView() {
    const classNames = useClassNames('settings-valuation-policy-application-conditions-view');
    const [editableScopeIds, setEditableScopeIds] = useState<Set<string>>(new Set());
    const [unsavedScopeIds, setUnsavedScopeIds] = useState<Set<string>>(new Set());

    const { policyId } = useParams<{ policyId: ValuationPolicyId }>();
    const [policy, setPolicy] = useState<ValuationPolicyProcessed>();

    const intl = useIntl();
    const notifications = useArgNotifications();

    useEffectAsync(async (progressMonitor: ProgressMonitor) => {
        if (!policyId) {
            return;
        }
        try {
            const policy = await explorationSettingsConnector.getValuationPolicy(policyId, progressMonitor);

            setPolicy(getValuationPolicyWithActionsIds(policy));
        } catch (error) {
            if (!progressMonitor.isCancelled) {
                throw error;
            }
            notifications.snackError({ message: messages.fetchDataError }, error as Error);
            throw error;
        }
    }, [policyId]);

    const [contextualVariables] = useMemoAsync(async (progressMonitor: ProgressMonitor) => {
        try {
            const contextualVariables = await explorationSettingsConnector.getContextualVariables();

            return contextualVariables;
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            notifications.snackError({ message: messages.fetchDataError }, error as Error);
            throw error;
        }
    }, []);

    const [userProfileFields] = useMemoAsync(async (progressMonitor: ProgressMonitor) => {
        try {
            const userProfileFields = await explorationSettingsConnector.getUserProfileFields();

            return userProfileFields;
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            notifications.snackError({ message: messages.fetchDataError }, error as Error);
            throw error;
        }
    }, []);


    const editConditionEditableState = (editable: boolean, id: string) => {
        const newSet = new Set(editableScopeIds);
        if (editable) {
            newSet.add(id);
            setEditableScopeIds(newSet);
        } else {
            newSet.delete(id);
            setEditableScopeIds(newSet);
        }
    };

    const [publishPolicy, publishProgressMonitor] = useCallbackAsync(async (progressMonitor: ProgressMonitor, id: ValuationPolicyId) => {
        try {
            await explorationSettingsConnector.publishValuationPolicy(id, 'Publish');
            notifications.snackSuccess({
                message: messages.publishPolicySuccess,
                description: messages.publishPolicySuccessDescription,
            });
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            notifications.snackError({ message: messages.publishPolicyError }, error as Error);
            throw error;
        }
    }, [notifications]);

    const [handleSaveScopes] = useCallbackAsync(async (progressMonitor: ProgressMonitor, scopes: Scope[]) => {
        if (!policy) {
            return;
        }
        try {
            await explorationSettingsConnector.editValuationPolicy(policy.id, {
                ...policy,
                scopes,
            });
        } catch (error) {
            if (progressMonitor.isCancelled) {
                return;
            }
            notifications.snackError({ message: messages.editPolicyError }, error as Error);
        }
    }, [policy]);

    const handleSetScopes = useCallback((setScopes: React.SetStateAction<ScopeProcessed[]>) => {
        setPolicy((prevPolicy) => {
            if (!prevPolicy) {
                return prevPolicy;
            }
            const scopes = isFunction(setScopes) ? setScopes(prevPolicy.scopes) : setScopes;

            return {
                ...prevPolicy,
                scopes,
            };
        });
    }, []);

    if (!contextualVariables || !userProfileFields || !policy) {
        return (
            <div className='arg-layout'>
                <LoadingPane />
            </div>
        );
    }

    return (
        <div className={classNames('&')}>
            <ArgButton
                size='medium'
                type='primary'
                className={classNames('&-publish-button')}
                label={messages.publish}
                onClick={() => publishPolicy(policy.id)}
                disabled={editableScopeIds.size > 0 || publishProgressMonitor?.isRunning}
                loading={publishProgressMonitor?.isRunning}
            />
            <ApplicationConditionPageToolbar
                setScopes={handleSetScopes}
                setEditableScopeIds={setEditableScopeIds}
                setUnsavedScopeIds={setUnsavedScopeIds}
                editableScopeIds={editableScopeIds}
            />
            {!policy.scopes.length && (
                <div className={classNames('&-empty')}>
                    <EmptyPane
                        message={intl.formatMessage(messages.emptyConditions)}
                    />
                </div>
            )}
            {policy.scopes.map((scope, idx) => (
                <ApplicationConditionBlock
                    scope={scope}
                    index={idx}
                    onSaveScopes={handleSaveScopes}
                    setScopes={handleSetScopes}
                    key={scope.id}
                    editable={editableScopeIds.has(scope.id)}
                    scopes={policy.scopes}
                    setEditable={(currentlyEditable) => {
                        editConditionEditableState(currentlyEditable, scope.id);
                    }}
                    contextualVariables={contextualVariables}
                    userProfileFields={userProfileFields}
                    saved={!unsavedScopeIds.has(scope.id)}
                    setUnsavedScopeIds={setUnsavedScopeIds}
                />
            ))}
        </div>
    );
}
