import React from 'react';
import ReactDOM from 'react-dom';
import Dayjs from 'dayjs';
import { chain, compact, forEach, isFunction, map } from 'lodash';
import Debug from 'debug';
import * as ReactRouter from 'react-router';
import * as ReactRouterDom from 'react-router-dom';
import * as ReactIntl from 'react-intl';
import ReactJsxRuntime from 'react/jsx-runtime';

import { EventEmitter, ProgressMonitor, setImmutable, SubProgressMonitor } from '../../components/basic';
import { ArgonosModule } from '../../components/application/modules';
import {
    ExtensionDescriptor,
    ExtensionId,
    ExtensionItem,
    ExtensionPointDescriptor,
    ExtensionRuntime,
    ExtensionsList,
} from './models';
import { ExtensionsConnector } from './extensions-connector';

import productPackageJson from '../../../package.json';

const debug = Debug('framework:extensions:ExtensionsRegistry');

const NO_EXTENSION_HASH = false;

const NO_MESSAGES:Record<string, string> = setImmutable({});

interface InternalExtensionRuntime {
    runtime: ExtensionRuntime;

    module: Record<string, unknown>;
    exports: Record<string, unknown>;
    required: ExtensionId[];

    locales: Record<string, Record<string, string>>;

    dependencyRuntimes: Record<ExtensionId, InternalExtensionRuntime>;

    waitingPromises?: {resolve: (value: InternalExtensionRuntime | PromiseLike<InternalExtensionRuntime>)=>void; reject: (error: Error)=>void; progressMonitor?: ProgressMonitor}[];
}

interface LoadingContext {
    // To check cycles
    loadingExtensions: Record<ExtensionId, true>;
}

export interface ExtensionRegisterEvent<T=any> {
    extensionRuntime: ExtensionRuntime;
    extensionType: string;
    id: string;
    props: T;

    setProcessed: ()=>void;
}

interface ExtensionEventNames {
    onManifestLoaded(extensionDescriptor: ExtensionDescriptor): void;
    onExtensionInstantiated(extensionRuntime: ExtensionRuntime): void;
    onSetupRegister(event: ExtensionRegisterEvent):void;
    onExtensionCompletelyLoaded(extensionRuntime: ExtensionRuntime): void;
}

/**
 * A repository for handling extensions in Argonos.
 */
export class ExtensionsRegistry {
    private static instance: ExtensionsRegistry;

    readonly #extensionEventEmitter:EventEmitter<ExtensionEventNames>;

    readonly #extensionLoadedById:Record<string, ExtensionDescriptor|'loading'> = {};

    readonly #internalExtensionRuntimesById:Record<string, InternalExtensionRuntime> = {};

    #hasDeveloperExtensions = false;

    static getInstance(): ExtensionsRegistry {
        if (!ExtensionsRegistry.instance) {
            ExtensionsRegistry.instance = new ExtensionsRegistry();
        }

        return ExtensionsRegistry.instance;
    }

    constructor() {
        this.#extensionEventEmitter = new EventEmitter<ExtensionEventNames>();
    }

    get hasDeveloperExtensions():boolean {
        return this.#hasDeveloperExtensions;
    }

    async getLocaleMessages(userLocale: string, progressMonitor: ProgressMonitor): Promise<Record<string, string>> {
        debug('getLocaleMessages', 'Load locale messages', userLocale);

        const runtimes = Object.values(this.#internalExtensionRuntimesById);

        const ps = runtimes.map(async (runtime: InternalExtensionRuntime):Promise<Record<string, string>|undefined> => {
            const result = runtime.locales?.[userLocale];
            if (result) {
                return result;
            }

            const sub = new SubProgressMonitor(progressMonitor, 1);

            let messages = await this.#loadLocaleMessage(runtime, userLocale, sub);
            if (!messages || messages === NO_MESSAGES) {
                const ref = /^([A-Z0-9]+)[-_]([A-Z0-9]+)/i.exec(userLocale);
                if (ref) {
                    const reducedUserLocale = ref[1];
                    const result = runtime.locales?.[reducedUserLocale];
                    if (result) {
                        runtime.locales[userLocale] = result;

                        return result;
                    }

                    messages = await this.#loadLocaleMessage(runtime, reducedUserLocale, sub);
                    runtime.locales[userLocale] = messages;
                }
            }

            return messages;
        });

        const messages = await Promise.all(ps);

        const result = Object.assign({}, ...messages);

        return result;
    }

    async #loadLocaleMessage(runtime: InternalExtensionRuntime, userLocale: string, progressMonitor: ProgressMonitor): Promise<Record<string, string>> {
        if (!runtime.locales) {
            runtime.locales = {};
        }

        const localePath = runtime.runtime.descriptor.locales?.[userLocale]
            || runtime.runtime.descriptor.locales?.['*'];

        if (!localePath) {
            runtime.locales[userLocale] = NO_MESSAGES;

            return NO_MESSAGES;
        }

        const localeURL = new URL(localePath, runtime.runtime.descriptor.baseURL).toString();

        const messages = await ExtensionsConnector.getInstance().loadExtensionLocale(
            localeURL,
            runtime.runtime.descriptor.argonosModule,
            progressMonitor,
        );

        runtime.locales[userLocale] = messages || NO_MESSAGES;

        return messages || NO_MESSAGES;
    }

    /**
     * Load the extensions for the given Argonos module.
     * If the extension feature is not enabled or if the '--arg-no-extensions' flag is present in the query string,
     * this method will return without doing anything.
     * Otherwise, it will load the extensions for the Argonos module using the ExtensionsConnector.
     *
     * @param {ArgonosModule} argonosModule - The Argonos module for which to load the extensions.
     * @param {ProgressMonitor} progressMonitor - The progress monitor to use for tracking the loading progress.
     * @return {Promise<void>} - A promise that resolves once the extensions have been loaded.
     */
    async loadArgonosModuleExtensions(argonosModule: ArgonosModule, progressMonitor: ProgressMonitor): Promise<void> {
        if (localStorage.ENABLE_EXTENSION_FEATURE === 'false') {
            return;
        }

        const querySearch = new URLSearchParams(document.location.search);
        if (querySearch.has('--arg-no-extensions')) {
            return;
        }

        // Load Developer extensions first
        let developerExtensionsBaseURL = localStorage.getItem(`ARG_DEVELOPER_EXTENSIONS:${argonosModule.id}`);
        if (developerExtensionsBaseURL && developerExtensionsBaseURL !== 'false') {
            console.log('loadArgonosModuleExtensions', 'Load developer extensions for module', argonosModule.name, 'from URL=', developerExtensionsBaseURL);

            if (!developerExtensionsBaseURL.endsWith('/')) {
                developerExtensionsBaseURL = `${developerExtensionsBaseURL}/`;
            }
            const extensionListURL = `${developerExtensionsBaseURL}list.json`;
            const extensionsList = await ExtensionsConnector.getInstance().loadExtensionsFromList(extensionListURL, developerExtensionsBaseURL, argonosModule, progressMonitor);

            await this._loadExtensionManifests(extensionsList, argonosModule, progressMonitor);
        }

        debug('loadArgonosModuleExtensions', 'module=', argonosModule.name);

        try {
            const extensionsList = await ExtensionsConnector.getInstance().loadExtensions(argonosModule, progressMonitor);

            await this._loadExtensionManifests(extensionsList, argonosModule, progressMonitor);

            this.#hasDeveloperExtensions = true;
        } catch (error) {
            console.error('Can not load extensions', error);
        }
    }

    /**
     * @private
     */
    async _loadExtensionManifests(extensionsList: ExtensionsList, argonosModule: ArgonosModule, progressMonitor: ProgressMonitor): Promise<void> {
        const extensionPromises = chain(extensionsList.extensions)
            .filter((extensionItem) => (!this.#extensionLoadedById[extensionItem.name]))
            .map(async (extensionItem: ExtensionItem) => {
                this.#extensionLoadedById[extensionItem.name] = 'loading';

                debug('_loadExtensions', 'loading extension', extensionItem);

                const sub = new SubProgressMonitor(progressMonitor, 1);

                const extensionURL = new URL(`${encodeURIComponent(extensionItem.name)}/`, extensionsList.extensionsBaseURL);

                const manifestPath = (extensionItem.contentHash && !NO_EXTENSION_HASH)
                    ? `static/${encodeURIComponent(extensionItem.contentHash)}/manifest.json`
                    : 'public/manifest.json';
                const manifestURL = new URL(manifestPath, extensionURL).toString();

                debug('_loadExtensions', 'loading extension', extensionItem, 'manifestPath=', manifestURL, 'extensionURL=', extensionURL, 'argonosModule=', argonosModule.name);

                try {
                    const extensionDescriptor = await ExtensionsConnector.getInstance().loadExtensionManifest(
                        manifestURL,
                        argonosModule,
                        extensionItem.name,
                        sub,
                    );

                    debug('_loadExtensions', 'loading extension', extensionItem, 'loaded', 'descriptor=', extensionDescriptor);

                    this.#extensionLoadedById[extensionItem.name] = extensionDescriptor;

                    return extensionDescriptor;
                } catch (error) {
                    console.error(error);

                    return undefined;
                }
            })
            .compact()
            .value();

        const extensionDescriptors = compact(await Promise.all(extensionPromises));

        debug('_loadExtensions', extensionDescriptors.length, 'manifest loaded');

        const result = extensionDescriptors.map(async (extensionDescriptor: ExtensionDescriptor): Promise<void> => {
            if (extensionDescriptor.loading === 'startup') {
                debug('_loadExtensions', 'loading at startup for extension', extensionDescriptor.name);

                try {
                    await this.loadExtension(extensionDescriptor);
                } catch (error) {
                    console.error('Can not load extension at startup', 'error=', error);
                }
            }

            debug('_loadExtensions', 'Send onLoaded event for extension', extensionDescriptor.name);

            this.#extensionEventEmitter.emit('onManifestLoaded', extensionDescriptor);
        });

        await Promise.allSettled(result);

        debug('_loadExtensions', extensionDescriptors.length, 'initialized');
    }

    /**
     * Asynchronously loads an extension. (Execute JS code of extension and install extension points)
     *
     * @param {ExtensionDescriptor} extensionDescriptor - The descriptor of the extension to load.
     * @param {ProgressMonitor} [progressMonitor=ProgressMonitor.empty()] - The progress monitor to track the loading progress. Defaults to an empty progress monitor.
     * @return {Promise<ExtensionRuntime>} - A promise that resolves to the runtime of the loaded extension.
     */
    async loadExtension(extensionDescriptor: ExtensionDescriptor, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<ExtensionRuntime> {
        debug('loadExtension', 'extension=', extensionDescriptor.name);

        const result = await this._loadExtension(extensionDescriptor, undefined, progressMonitor);

        return result.runtime;
    }

    /**
     * @private
     */
    async _loadExtension(
        extensionDescriptor: ExtensionDescriptor,
        context: LoadingContext = { loadingExtensions: {} },
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<InternalExtensionRuntime> {
        const extensionKey = extensionDescriptor.name + (extensionDescriptor.version ? (`;${extensionDescriptor.version}`) : '');

        let internalExtensionRuntime: InternalExtensionRuntime = this.#internalExtensionRuntimesById[extensionKey];

        debug('_loadExtension', 'extensionKey=', extensionKey, 'internalExtensionRuntime=', internalExtensionRuntime);
        if (internalExtensionRuntime) {
            if (internalExtensionRuntime.waitingPromises) {
                const promise = new Promise<InternalExtensionRuntime>((resolve, reject) => {
                    internalExtensionRuntime.waitingPromises!.push({ resolve, reject, progressMonitor });
                });

                return promise;
            }

            // Already loaded
            return internalExtensionRuntime;
        }

        internalExtensionRuntime = {
            runtime: {
                descriptor: extensionDescriptor,
                status: 'Initializing',
            },
            module: {},
            exports: {},
            required: [],
            waitingPromises: [],
            dependencyRuntimes: {},
            locales: {},
        };

        this.#internalExtensionRuntimesById[extensionKey] = internalExtensionRuntime;

        if (extensionDescriptor.dependencies) {
            const dependencyPromises: Promise<InternalExtensionRuntime | null>[] = map(extensionDescriptor.dependencies, async (dependencyVersion: string, dependencyExtensionId: string) => {
                if (dependencyExtensionId === productPackageJson.name) {
                    return null;
                }

                const dependencyExtensionDescriptor = this.#extensionLoadedById[dependencyExtensionId];
                if (!dependencyExtensionDescriptor) {
                    console.error('Unknown dependency', dependencyExtensionId);

                    return null;
                }
                if (dependencyExtensionDescriptor === 'loading') {
                    console.error('INTERNAL ERROR');

                    return null;
                }

                if (context.loadingExtensions[extensionKey]) {
                    throw new Error('Dependency cycle detected');
                }
                context.loadingExtensions[extensionKey] = true;

                const sub = new SubProgressMonitor(progressMonitor, 1);

                const dependencyInternalRuntime = await this._loadExtension(
                    dependencyExtensionDescriptor,
                    context,
                    sub,
                );

                return dependencyInternalRuntime;
            });

            const dr = await Promise.all(dependencyPromises);
            dr.forEach((dependencyRuntime: InternalExtensionRuntime | null) => {
                if (!dependencyRuntime) {
                    return;
                }
                internalExtensionRuntime.dependencyRuntimes[dependencyRuntime.runtime.descriptor.name] = dependencyRuntime;
            });
        }

        try {
            await this._loadExtensionRuntime(internalExtensionRuntime);

            debug('Load of', extensionDescriptor.name, 'succeed');
        } catch (error) {
            console.error(error);

            const waitingPromises = internalExtensionRuntime.waitingPromises;
            internalExtensionRuntime.waitingPromises = undefined;

            waitingPromises?.forEach(({ reject }) => {
                reject(error as Error);
            });

            throw error;
        }

        const waitingPromises = internalExtensionRuntime.waitingPromises;
        internalExtensionRuntime.waitingPromises = undefined;

        waitingPromises?.forEach(({ resolve }) => {
            resolve(internalExtensionRuntime);
        });

        return internalExtensionRuntime;
    }

    /**
     * @private
     */
    async _loadExtensionRuntime(internalExtensionRuntime: InternalExtensionRuntime): Promise<void> {
        const extensionRuntime = internalExtensionRuntime.runtime;
        const { descriptor } = extensionRuntime;
        const mainFilename = descriptor.main || './index.js';

        const mainURL = new URL(mainFilename, descriptor.baseURL).toString();

        debug('_loadExtensionRuntime', 'Loading extension runtime of', descriptor.name, 'url=', mainURL);
        extensionRuntime.loadingDate = new Date();

        const mainSource = await ExtensionsConnector.getInstance().loadExtensionMain(mainURL, descriptor.argonosModule);

        extensionRuntime.loadedDate = new Date();

        const globals: Record<string, any> = {
            react: React,
            'react/jsx-runtime': ReactJsxRuntime,
            'react-dom': ReactDOM,
            'react-router': ReactRouter,
            'react-router-dom': ReactRouterDom,
            'react-intl': ReactIntl,
            dayjs: Dayjs,
        };

        const extensionExports = {};
        const extensionModule: { 'exports'?: any } = {};

        internalExtensionRuntime.exports = extensionExports;
        internalExtensionRuntime.module = extensionModule;
        internalExtensionRuntime.required = [];

        const extensionRequire = (name: ExtensionId) => {
            const extensionDependency = internalExtensionRuntime.dependencyRuntimes?.[name];
            if (extensionDependency) {
                internalExtensionRuntime.required.push(name);

                return extensionDependency.exports;
            }

            const globalDependency = globals[name];
            if (!globalDependency) {
                throw new Error(`Extension ${descriptor.name} requires an unknown dependency "${name}".`);
            }

            internalExtensionRuntime.required.push(name);

            return globalDependency;
        };

        debug('_loadExtensionRuntime', 'Load source of extension', descriptor.name);
        let umdFunction;
        try {
            umdFunction = new Function('exports', 'module', 'require', mainSource);
        } catch (x) {
            console.error(`Parse error for extension=${descriptor.name}`, x);

            const error = new Error(`Can not parse source of extension: ${descriptor.name}`);
            (error as any).reason = x;

            extensionRuntime.error = error;
            extensionRuntime.status = 'Error';
            extensionRuntime.errorDate = new Date();

            throw error;
        }

        debug('_loadExtensionRuntime', 'Source loaded, execute source to get entry point', descriptor.name);

        let extensionEntryPoint;
        try {
            extensionEntryPoint = umdFunction(extensionExports, extensionModule, extensionRequire);
        } catch (x) {
            console.error(`Setup error for extension=${descriptor.name}`, x);

            const error = new Error(`Can not evaluate source of plugin: ${descriptor.name}`);
            (error as any).reason = x;

            extensionRuntime.error = error;
            extensionRuntime.status = 'Error';
            extensionRuntime.errorDate = new Date();

            throw error;
        }

        debug('_loadExtensionRuntime', 'Plugin executed', 'extensionModule', extensionModule, 'extensionExports=', extensionExports, 'return=', extensionEntryPoint);

        if (!isFunction(extensionEntryPoint)) {
            extensionEntryPoint = extensionModule['exports'];
        }

        if (!isFunction(extensionEntryPoint)) {
            const error = new Error(`Invalid plugin ${descriptor.name} package`);

            extensionRuntime.error = error;
            extensionRuntime.status = 'Error';
            extensionRuntime.errorDate = new Date();

            throw error;
        }

        const register = (type: string, id: string, props: any) => {
            let processed = false;

            debug('_loadExtensionRuntime', 'Register', 'type=', type, 'id=', id, 'props=', props, 'extension=', descriptor.name);

            const event: ExtensionRegisterEvent = {
                extensionRuntime,
                extensionType: type,
                id,
                props,
                setProcessed() {
                    processed = true;
                },
            };
            this.#extensionEventEmitter.emit('onSetupRegister', event);

            if (!processed) {
                console.error(`Unsupported register of type '${type}' for extension ${descriptor.name}`);
            }
        };

        try {
            extensionEntryPoint(register, descriptor);

            extensionRuntime.status = 'Ready';
            extensionRuntime.readyDate = new Date();
        } catch (x) {
            const error = new Error(`Evaluation error for plugin: ${descriptor.name}: ${(x as Error).message}`);
            (error as any).reason = x;

            extensionRuntime.error = error;
            extensionRuntime.status = 'Error';
            extensionRuntime.errorDate = new Date();

            throw error;
        }

        this.#extensionEventEmitter.emit('onExtensionCompletelyLoaded', extensionRuntime);

        debug('_loadExtensionRuntime', 'Extension runtime loaded', 'extension=', descriptor.name);
    }

    /**
     * Called for every extension point defined in the extension's manifest
     *
     * @param extensionPointType
     * @param handler
     */
    onManifestExtensionPointDeclaration(
        extensionPointType: string,
        handler: (extensionDescriptor: ExtensionDescriptor, extensionPointDescriptor: ExtensionPointDescriptor)=>void,
    ): ()=>void {
        const h = (extensionDescriptor: ExtensionDescriptor) => {
            extensionDescriptor.extensionPoints?.forEach((extensionPoint: ExtensionPointDescriptor) => {
                if (extensionPoint.type !== extensionPointType) {
                    return;
                }

                handler(extensionDescriptor, extensionPoint);
            });
        };

        this.#extensionEventEmitter.on('onManifestLoaded', h);

        forEach(this.#extensionLoadedById, (extensionDescriptor: ExtensionDescriptor|'loading') => {
            if (extensionDescriptor === 'loading') {
                return;
            }

            h(extensionDescriptor);
        });


        return () => {
            this.#extensionEventEmitter.off('onManifestLoaded', h);
        };
    }

    /**
     * Called each time a setup function of an extension register an extension point
     *
     * @param extensionPointType
     * @param handler
     */
    onExtensionPointSetupRegister(
        extensionPointType: string,
        handler: (event: ExtensionRegisterEvent)=>void,
    ): ()=>void {
        const h = (event: ExtensionRegisterEvent) => {
            if (event.extensionType !== extensionPointType) {
                return;
            }
            handler(event);
            event.setProcessed();
        };

        this.#extensionEventEmitter.on('onSetupRegister', h);

        return () => {
            this.#extensionEventEmitter.off('onSetupRegister', h);
        };
    }

    onExtensionCompletelyLoaded(handler: (extensionRuntime: ExtensionRuntime) => void): (()=>void) {
        this.#extensionEventEmitter.on('onManifestLoaded', handler);

        return () => {
            this.#extensionEventEmitter.off('onManifestLoaded', handler);
        };
    }
}
