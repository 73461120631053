import Debug from 'debug';
import { compact, flatMap, isEmpty, mapValues, reduce } from 'lodash';
import { compare } from 'fast-json-patch';

import { Configuration, EMPTY_CONFIGURATION, ProgressMonitor, SubProgressMonitor } from '../../components/basic';
import { BaseConnector } from './base-connector';
import { CasePieceType } from '../../model/case-piece-type';
import { createPatchRequest, JsonChange } from '../connector';
import { convertCasePieceTypeToBack, mapArgonosPiece, registerCasePieceType } from './mappers';
import { FolderId } from 'src/model/folder';
import { isResponse404 } from '../../components/basic/utils/response-error';
import { BasicCasePiece } from '../../model/basic-case-piece';
import { EntityId, SettingsEntryId } from '../../model/argonos-piece';

const debug = Debug('argonode:utils:ArgonosPieceConnector');

const VISIT_NOT_IMPLEMENTED = false;

export class ArgonosPieceConnector extends BaseConnector {
    async addPiece(
        folderId: FolderId,
        entityId: EntityId,
        casePieceType: CasePieceType,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const url = `/argonos-folders/${encodeURIComponent(folderId)}/pieces/${encodeURIComponent(entityId)}`;
        await this.request(url, {
            method: 'PUT',
            json: {
                entityId,
                pieceType: convertCasePieceTypeToBack(casePieceType),
            },
        }, progressMonitor);
    }

    async getAllPieces(
        pieceTypes: CasePieceType[],
        withUserSettings?: boolean,
        withSettings?: boolean,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<Record<FolderId, BasicCasePiece[]>> {
        const url = '/argonos-folders/pieces';

        const response = await this.request(url, {
            verifyJSONResponse: true,
            params: {
                'with-user-settings': withUserSettings,
                'with-settings': withSettings,
                'piece-type': pieceTypes.map(convertCasePieceTypeToBack),
            },
        }, progressMonitor);

        const foldersPieces: Record<FolderId, BasicCasePiece[]> = response.foldersPieces;

        const pieces: Record<FolderId, BasicCasePiece[]> = mapValues(foldersPieces, (casePieces: BasicCasePiece[], caseId: FolderId) => {
            const folderPieces: BasicCasePiece[] = compact(casePieces.map((piece) => mapArgonosPiece(piece, withSettings || withUserSettings)));

            return folderPieces;
        });

        return pieces;
    }

    async getPieces(
        folderId: FolderId,
        pieceTypes?: CasePieceType[],
        userSettings?: boolean,
        settings?: boolean,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<BasicCasePiece[]> {
        const url = `/argonos-folders/${encodeURIComponent(folderId)}/pieces`;

        const response = await this.request(url, {
            verifyJSONResponse: true,
            params: {
                'with-user-settings': userSettings,
                'with-settings': settings,
                'piece-type': pieceTypes?.map(convertCasePieceTypeToBack),
            },
        }, progressMonitor);

        const folderPieces: BasicCasePiece[] = response.pieces.map(mapArgonosPiece);

        return folderPieces;
    }

    async getPiece(
        folderId: FolderId,
        entityId: EntityId,
        withUserSettings?: boolean,
        withSettings?: boolean,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<BasicCasePiece> {
        const url = `/argonos-folders/${encodeURIComponent(folderId)}/pieces/${encodeURIComponent(entityId)}`;

        const data = await this.request(url, {
            verifyJSONResponse: true,
            params: {
                'with-user-settings': withUserSettings,
                'with-settings': withSettings,
            },
        }, progressMonitor);

        const ret = mapArgonosPiece(data);

        return ret!;
    }

    async getArgonosPiecesById(
        piecesId: EntityId[],
        withUserSettings: true,
        withSettings: true,
        progressMonitor?: ProgressMonitor,
    ): Promise<BasicCasePiece[]>;

    async getArgonosPiecesById(
        piecesId: EntityId[],
        withUserSettings?: boolean,
        withSettings?: boolean,
        progressMonitor?: ProgressMonitor,
    ): Promise<BasicCasePiece[]>;

    async getArgonosPiecesById(
        piecesId: EntityId[],
        withUserSettings?: boolean,
        withSettings?: boolean,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<BasicCasePiece[]> {
        const url = '/argonos-folders/pieces';

        const json = {
            piecesId: piecesId.map((p) => ({ entityId: p })),
            withSettings,
            withUserSettings,
        };

        const response = await this.request(url, {
            verifyJSONResponse: true,
            json,
        }, progressMonitor);

        const foldersPieces: Record<FolderId, BasicCasePiece[]> = response.foldersPieces;

        const pieces: Record<FolderId, BasicCasePiece[]> = mapValues(foldersPieces, (casePieces: BasicCasePiece[], caseId: FolderId) => {
            const folderPieces: BasicCasePiece[] = compact(casePieces.map((piece) => mapArgonosPiece(piece, withSettings || withUserSettings)));

            return folderPieces;
        });

        const ret = flatMap(pieces);

        return ret;
    }

    /* - USER SETTINGS - */

    async getPieceUserSettings<T extends Configuration>(
        folderId: FolderId,
        entityId: EntityId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<T> {
        const url = `/argonos-folders/${encodeURIComponent(folderId)}/pieces/${encodeURIComponent(entityId)}/user-settings`;
        const options = {
            verifyJSONResponse: true,
        };

        try {
            const settings = await this.request(url, options, progressMonitor);

            return (settings ? settings : EMPTY_CONFIGURATION) as T;
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            if (isResponse404(error)) {
                return {} as T;
            }

            throw error;
        }
    }

    async savePieceUserSettings(
        folderId: FolderId,
        entityId: EntityId,
        settings: Configuration,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const url = `/argonos-folders/${encodeURIComponent(folderId)}/pieces/${encodeURIComponent(entityId)}/user-settings`;
        const options = {
            method: 'PUT',
            verifyJSONResponse: true,
            json: settings,
        };
        await this.request(url, options, progressMonitor);
    }

    async getPieceUserSettingsEntry(
        folderId: FolderId,
        entityId: EntityId,
        settingsEntryId: SettingsEntryId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<Configuration | string | number | boolean | undefined> {
        const url = `/argonos-folders/${encodeURIComponent(folderId)}/pieces/${encodeURIComponent(entityId)}/user-settings`;
        const options = {
            verifyJSONResponse: true,
            params: {
                settingKey: (settingsEntryId) ? [settingsEntryId] : undefined,
            },
        };

        try {
            const settings = await this.request(url, options, progressMonitor);

            return settings;
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            if (isResponse404(error)) {
                return undefined;
            }

            throw error;
        }
    }

    async patchPieceUserSettings<T extends object>(
        folderId: FolderId,
        entityId: EntityId,
        settings: T,
        previousSettings: T,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const operations = compare(previousSettings, settings);
        if (isEmpty(operations)) {
            return;
        }

        const url = `/argonos-folders/${encodeURIComponent(folderId)}/pieces/${encodeURIComponent(entityId)}/user-settings`;
        const options = {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json-patch+json' },
            json: {
                comment: 'Modify settings',
                changes: operations,
            },
        };
        await this.request(url, options, progressMonitor);
    }

    /*
    Impossible de coder ça sans l'ancienne valeur  (pour le patch)

    async setPieceUserSettingsEntry(
        folderId: FolderId,
        entityId: EntityId,
        settingsEntryId: SettingsEntryId,
        entrySettings: Configuration | string | number | boolean,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty()
    ): Promise<void> {
        const change: JsonChange = {
            path: settingsEntryId,
            value: entrySettings,
        };

        const url = `/argonos-folders/${encodeURIComponent(folderId)}/pieces/${encodeURIComponent(entityId)}/user-settings`;
        const options = createPatchRequest('Change settings', change);

        await this.request(url, options, progressMonitor);
    }
     */

    /* - SETTINGS - */

    async getPieceSettings<T extends Configuration>(
        folderId: FolderId,
        entityId: EntityId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<T> {
        const url = `/argonos-folders/${encodeURIComponent(folderId)}/pieces/${encodeURIComponent(entityId)}/settings`;
        const options = {
            verifyJSONResponse: true,
        };

        try {
            const settings = await this.request(url, options, progressMonitor);

            return (settings ? settings : EMPTY_CONFIGURATION) as T;
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            if (isResponse404(error)) {
                return {} as T;
            }

            throw error;
        }
    }

    async savePieceSettings(
        folderId: FolderId,
        entityId: EntityId,
        settings: Configuration,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const url = `/argonos-folders/${encodeURIComponent(folderId)}/pieces/${encodeURIComponent(entityId)}/settings`;
        const options = {
            method: 'PUT',
            verifyJSONResponse: true,
            json: settings,
        };
        await this.request(url, options, progressMonitor);
    }

    async patchPieceSettings(
        folderId: FolderId,
        entityId: EntityId,
        settings: Configuration,
        previousSettings: Configuration,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const operations = compare(previousSettings, settings);
        if (isEmpty(operations)) {
            return;
        }

        const url = `/argonos-folders/${encodeURIComponent(folderId)}/pieces/${encodeURIComponent(entityId)}/settings`;
        const options = {
            method: 'PATCH',
            json: {
                comment: 'Modify settings',
                changes: operations,
            },
            headers: { 'Content-Type': 'application/json-patch+json' },
        };
        await this.request(url, options, progressMonitor);
    }

    async getPieceSettingsEntry(
        folderId: FolderId,
        entityId: EntityId,
        settingsEntryId: SettingsEntryId | undefined,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<Configuration | string | number | boolean | undefined> {
        const url = `/argonos-folders/${encodeURIComponent(folderId)}/pieces/${encodeURIComponent(entityId)}/settings`;
        const options = {
            verifyJSONResponse: true,
            params: {
                settingKey: (settingsEntryId) ? [settingsEntryId] : undefined,
            },
        };

        try {
            const settings = await this.request(url, options, progressMonitor);

            return settings;
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            if (isResponse404(error)) {
                return undefined;
            }

            throw error;
        }
    }

    async setPieceSettingsEntry(
        folderId: FolderId,
        entityId: EntityId,
        settingsEntryId: SettingsEntryId,
        entrySettings: Configuration | string | number | boolean | undefined,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const change: JsonChange = {
            path: settingsEntryId,
            value: entrySettings,
        };

        const url = `/argonos-folders/${encodeURIComponent(folderId)}/pieces/${encodeURIComponent(entityId)}/settings`;
        const options = createPatchRequest('Change settings', change);

        try {
            await this.request(url, options, progressMonitor);
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            throw error;
        }
    }


    async visitPiece(
        folderId: FolderId,
        entityId: EntityId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        if (VISIT_NOT_IMPLEMENTED) {
            return;
        }

        const url = `/argonos-folders/${encodeURIComponent(folderId)}/pieces/${encodeURIComponent(entityId)}/visit`;

        await this.request(url, {
            method: 'POST',
        }, progressMonitor);
    }

    async deletePiece(
        folderId: FolderId,
        entityId: EntityId,
        deleteEntityIfDangling = true,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const url = `/argonos-folders/${encodeURIComponent(folderId)}/pieces/${encodeURIComponent(entityId)}`;

        await this.request(url, {
            method: 'DELETE',
            params: {
                deleteEntityIfDangling: deleteEntityIfDangling,
            },
        }, progressMonitor);
    }

    async deletePieces(
        folderId: FolderId,
        entityIds: EntityId[],
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        progressMonitor.beginTask('delete entities', entityIds.length);

        const ps: Promise<void>[] = entityIds.map((entityId: EntityId) => {
            const sub = new SubProgressMonitor(progressMonitor, 1);
            const p = this.deletePiece(folderId, entityId, true, sub);

            return p;
        });

        await Promise.all(ps);

        debug('deletePieces', 'Entities deleted:', entityIds);
    }

    async importPiece(
        folderId: FolderId,
        blob: Blob,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const url = `/argonos-folders/${encodeURIComponent(folderId)}/pieces/import`;

        await this.request(
            url,
            {
                headers: {
                    'Content-Type': blob.type,
                },
                body: blob,
                method: 'POST',
                verifyJSONResponse: false,
            },
            progressMonitor,
        );
    }

    async exportPiece(
        fileName: string,
        entityId: EntityId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const url = '/argonos-folders/pieces/export';

        await this.downloadRequest(fileName, url, {
            params: {
                ids: [entityId],
            },
        }, progressMonitor);
    }

    async exportPieces(
        fileName: string,
        entityIds: EntityId[],
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const url = '/argonos-folders/pieces/export';

        await this.downloadRequest(fileName, url, {
            params: {
                ids: entityIds,
            },
        }, progressMonitor);
    }


    async getFolderSettingsEntry(
        folderId: FolderId,
        settingsEntryId: SettingsEntryId | undefined,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<Configuration | string | number | boolean | undefined> {
        const url = `/argonos-folders/${encodeURIComponent(folderId)}/settings`;
        const options = {
            verifyJSONResponse: true,
            params: {
                settingKey: (settingsEntryId) ? [settingsEntryId] : undefined,
            },
        };

        try {
            const settings = await this.request(url, options, progressMonitor);

            return settings;
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            if (isResponse404(error)) {
                return undefined;
            }

            throw error;
        }
    }

    async setFolderSettingsEntry(
        folderId: FolderId,
        settingsEntryId: SettingsEntryId,
        entrySettings: Configuration | string | number | boolean | undefined,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const change: JsonChange = {
            path: settingsEntryId,
            value: entrySettings,
        };

        const url = `/argonos-folders/${encodeURIComponent(folderId)}/settings`;
        const options = createPatchRequest('Change settings', change);

        try {
            await this.request(url, options, progressMonitor);
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            throw error;
        }
    }

    async getFolderUserSettingsEntry(
        folderId: FolderId,
        settingsEntryId: SettingsEntryId | undefined,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<Configuration | string | number | boolean | undefined> {
        const url = `/argonos-folders/${encodeURIComponent(folderId)}/user-settings`;
        const options = {
            verifyJSONResponse: true,
            params: {
                settingKey: (settingsEntryId) ? [settingsEntryId] : undefined,
            },
        };

        try {
            const settings = await this.request(url, options, progressMonitor);

            return settings;
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            if (isResponse404(error)) {
                return undefined;
            }

            throw error;
        }
    }

    async setFolderUserSettingsEntry(
        folderId: FolderId,
        settingsEntryId: SettingsEntryId,
        entrySettings: Configuration | string | number | boolean | undefined,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const change: JsonChange = {
            path: settingsEntryId,
            value: entrySettings,
        };

        const url = `/argonos-folders/${encodeURIComponent(folderId)}/user-settings`;
        const options = createPatchRequest('Change user settings', change);

        try {
            await this.request(url, options, progressMonitor);
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            throw error;
        }
    }

    async getFoldersForPieceId(
        entityId: EntityId,
        entityType: CasePieceType,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<FolderId[]> {
        const pieces = await this.getAllPieces([entityType], false, false, progressMonitor);

        const result = reduce(pieces, (acc: FolderId[], casePieces: BasicCasePiece[], folderId: FolderId) => {
            if (casePieces.find((c) => c.id === entityId)) {
                acc.push(folderId);
            }

            return acc;
        }, [] as FolderId[]);

        return result;
    }
}


registerCasePieceType('argonos-folder', CasePieceType.Folder);

