import { useCallback, useEffect, useState } from 'react';
import { isFunction } from 'lodash';
import { defineMessages, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import {
    ApplicationConditionPageToolbar,
} from '../../common/application-conditions/application-condition-page-toolbar/application-condition-page-toolbar';
import {
    ArgButton,
    ProgressMonitor,
    useCallbackAsync,
    useClassNames,
    useArgNotifications,
    useProgressMonitor,
} from 'src/components/basic';
import { PolicyProcessed } from 'src/settings/models/dtoApi';
import { getPolicyWithActionsIds } from './utils';
import { LoadingPane } from 'src/components/common/panes/loading-pane';
import {
    ApplicationConditionBlock,
} from '../../common/application-conditions/application-condition-block/application-condition-block';
import { ContextualVariable } from 'src/exploration/model/contextual-variable';
import { Scope, ScopeProcessed } from '../../../models/policy';
import { UserProfileField } from 'src/model/user-metadata';
import explorationSettingsConnector from 'src/settings/connectors/exploration-settings-connector';

import './policy-application-conditions-view.less';

const messages = defineMessages({
    fetchData: {
        id: 'settings.policy-application-conditions.fetching-policy',
        defaultMessage: 'Loading policy',
    },
    fetchDataError: {
        id: 'settings.policy-application-conditions.fetching-policy.error',
        defaultMessage: 'An error occurred while fetching the policy',
    },
    publish: {
        id: 'settings.policy-application-conditions.publish-button',
        defaultMessage: 'Publish',
    },
    publishPolicyError: {
        id: 'settings.policy-application-conditions.publishPolicyError',
        defaultMessage: 'An error occurred while publishing the policy',
    },
    editPolicyError: {
        id: 'settings.policy-application-conditions.editPolicyError',
        defaultMessage: 'An error occurred while editing the policy',
    },
    publishPolicySuccess: {
        id: 'settings.policy-application-conditions.publishPolicySuccess',
        defaultMessage: 'Policy published',
    },
    publishPolicySuccessDescription: {
        id: 'settings.policy-application-conditions.publishPolicySuccessDescription',
        defaultMessage: 'The policy has successfully been published',
    },
});

export function PolicyApplicationConditionsView() {
    const intl = useIntl();

    const classNames = useClassNames('settings-policy-application-conditions-view');
    const notifications = useArgNotifications();

    const [policy, setPolicy] = useState<PolicyProcessed>();
    const [contextualVariables, setContextualVariables] = useState<ContextualVariable[]>([]);
    const [userProfileFields, setUserProfileFields] = useState<UserProfileField[]>([]);
    const [editableScopeIds, setEditableScopeIds] = useState<Set<string>>(new Set());
    const [unsavedScopeIds, setUnsavedScopeIds] = useState<Set<string>>(new Set());
    const [loading, setLoading] = useState(false);
    const { policyId } = useParams<{ ontologyId: string; policyId: string }>();

    const [fetchPolicyAndUniverseMonitor, createFetchPolicyAndUniverseMonitor] =
        useProgressMonitor();

    useEffect(() => {
        const progressMonitor = createFetchPolicyAndUniverseMonitor(
            intl.formatMessage(messages.fetchData),
            1,
            { global: true },
        );

        if (!policyId) {
            return;
        }
        Promise.all([
            explorationSettingsConnector.getPolicy(policyId),
            explorationSettingsConnector.getContextualVariables(),
            explorationSettingsConnector.getUserProfileFields(),
        ])
            .then(([policy, contextualVariables, profileFields]) => {
                setPolicy(getPolicyWithActionsIds(policy));
                setContextualVariables(contextualVariables);
                setUserProfileFields(profileFields);
            })
            .catch((error) => {
                if (progressMonitor.isCancelled) return;
                notifications.snackError({ message: messages.fetchDataError }, error as Error);
            })
            .finally(() => {
                progressMonitor.done();
            });
    }, []);

    const editConditionEditableState = (editable: boolean, id: string) => {
        const newSet = new Set(editableScopeIds);
        if (editable) {
            newSet.add(id);
            setEditableScopeIds(newSet);
        } else {
            newSet.delete(id);
            setEditableScopeIds(newSet);
        }
    };

    const publishPolicy = async (id: string) => {
        // TODO PROGRESSMONITOR !!!!!
        try {
            setLoading(true);
            await explorationSettingsConnector.publishPolicy(id, 'Publish');
            notifications.snackSuccess({
                message: messages.publishPolicySuccess,
                description: messages.publishPolicySuccessDescription,
            });
            setLoading(false);
        } catch (error) {
            notifications.snackError({ message: messages.publishPolicyError }, error as Error);
            setLoading(false);
        }
    };

    const [handleSaveScopes] = useCallbackAsync(async (progressMonitor: ProgressMonitor, scopes: Scope[]) => {
        if (!policy) {
            return;
        }
        try {
            await explorationSettingsConnector.editPolicy(policy.id, {
                ...policy,
                scopes,
            });
        } catch (error) {
            if (progressMonitor.isCancelled) {
                return;
            }
            notifications.snackError({ message: messages.editPolicyError }, error as Error);
        }
    }, [policy]);

    const handleSetScopes = useCallback((setScopes: React.SetStateAction<ScopeProcessed[]>) => {
        setPolicy((prevPolicy) => {
            if (!prevPolicy) {
                return prevPolicy;
            }
            const scopes = isFunction(setScopes) ? setScopes(prevPolicy.scopes) : setScopes;

            return {
                ...prevPolicy,
                scopes,
            };
        });
    }, []);

    if (fetchPolicyAndUniverseMonitor?.isRunning || !policy) {
        return (
            <div className='arg-layout'>
                <LoadingPane progressMonitor={fetchPolicyAndUniverseMonitor} />
            </div>
        );
    }

    return (
        <div className={classNames('&')}>
            <ArgButton
                size='medium'
                type='primary'
                className={classNames('&-publish-button')}
                label={messages.publish}
                onClick={() => publishPolicy(policy.id)}
                disabled={editableScopeIds.size > 0 || loading}
                loading={loading}
            />
            <ApplicationConditionPageToolbar
                setScopes={handleSetScopes}
                setEditableScopeIds={setEditableScopeIds}
                setUnsavedScopeIds={setUnsavedScopeIds}
                editableScopeIds={editableScopeIds}
            />
            {policy.scopes.map((scope, idx) => (
                <ApplicationConditionBlock
                    scope={scope}
                    index={idx}
                    onSaveScopes={handleSaveScopes}
                    setScopes={handleSetScopes}
                    key={scope.id}
                    editable={editableScopeIds.has(scope.id)}
                    scopes={policy.scopes}
                    setEditable={(currentlyEditable) => {
                        editConditionEditableState(currentlyEditable, scope.id);
                    }}
                    contextualVariables={contextualVariables}
                    userProfileFields={userProfileFields}
                    saved={!unsavedScopeIds.has(scope.id)}
                    setUnsavedScopeIds={setUnsavedScopeIds}
                />
            ))}
        </div>
    );
}
