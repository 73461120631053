import React, {
    ChangeEvent,
    FocusEvent as ReactFocusEvent,
    forwardRef,
    InputHTMLAttributes,
    KeyboardEvent,
    MouseEvent as ReactMouseEvent,
    useCallback,
    useLayoutEffect,
    useMemo,
    useState,
} from 'react';
import { isFunction } from 'lodash';

import { useInputElement, useInputState, usePrevious } from './hooks';
import { validateMaskPlaceholder } from './validate-props';
import { defer } from './defer';
import { isInputFocused } from './input';
import MaskUtils from './mask';
import { ClassValue, useClassNames } from '../../arg-hooks/use-classNames';
import { NO_SELECTION, TextState } from './types';
import { $yield } from '../../utils/yield';

import './arg-input-mask.less';


export type ArgInputMasks =string|(string|RegExp)[];

export interface InputMaskProps{
    alwaysShowMask?: boolean;
    beforeMaskedStateChange?: (param: {currentState?: TextState; previousState?: TextState; nextState: TextState})=>TextState;
    mask: ArgInputMasks;
    maskPlaceholder?: string;
    onChange: (value: string, inputState: TextState)=>void;
    disabled?: boolean;
    readOnly?: boolean;
    value?: string;
    defaultValue?: string;
    'data-testid'?: string;

    onFocus?:(event: ReactFocusEvent)=>void;
    onBlur?:(event: ReactFocusEvent)=>void;
    onMouseDown?:(event: ReactMouseEvent)=>void;
    className: ClassValue;
    onKeyDown?:(event: KeyboardEvent)=>void;
}

export const ArgInputMask = forwardRef(function _ArgInputMask(
    props: InputMaskProps,
    forwardedRef,
) {
    const {
        alwaysShowMask = false,
        mask,
        maskPlaceholder = '_',
        beforeMaskedStateChange,
        disabled,
        readOnly,
        className,
    } = props;

    const classNames = useClassNames('arg-input-mask');

    validateMaskPlaceholder(props);

    const maskUtils = useMemo(() => {
        return new MaskUtils(mask, maskPlaceholder);
    }, [mask, maskPlaceholder]);

    const [isFocused, setFocused] = useState(false);

    const isMasked = !!mask;
    const isEditable = !disabled && !readOnly;
    const isControlled = 'value' in props;
    const previousIsMasked = usePrevious(isMasked);
    const initialValue = (isControlled ? props.value : props.defaultValue) || '';

    const { inputRef, getInputState, setInputState, getLastInputState } =
        useInputState(initialValue, isMasked);
    const getInputElement = useInputElement(inputRef);

    const onChange = useCallback((event: ChangeEvent) => {
        const currentState = getInputState();
        const previousState = getLastInputState();

        let newInputState = maskUtils.processChange(currentState, previousState);

        if (beforeMaskedStateChange) {
            newInputState = beforeMaskedStateChange({
                currentState,
                previousState,
                nextState: newInputState,
            });
        }

        setInputState(newInputState);

        props.onChange?.((event.target as HTMLInputElement).value, newInputState);
    }, [beforeMaskedStateChange, getInputState, getLastInputState, maskUtils, props, setInputState]);

    const handleFocus = useCallback((event: ReactFocusEvent) => {
        $yield(() => {
            setFocused(true);
        });

        // If autoFocus property is set, focus event fires before the ref handler gets called
        inputRef.current = event.target as HTMLInputElement;

        const currentValue = getInputState().value || '';

        if (isMasked && !maskUtils.isValueFilled(currentValue)) {
            let newValue = maskUtils.formatValue(currentValue);
            let newSelection = maskUtils.getDefaultSelectionForValue(newValue);
            let newInputState:TextState = {
                value: newValue,
                selection: newSelection,
            };

            if (beforeMaskedStateChange) {
                newInputState = beforeMaskedStateChange({
                    currentState: getInputState(),
                    nextState: newInputState,
                });
                newValue = newInputState.value || '';
                newSelection = newInputState.selection;
            }

            setInputState(newInputState);

            if (newValue !== currentValue && props.onChange) {
                props.onChange?.(newValue, newInputState);
            }

            // Chrome resets selection after focus event,
            // so we want to restore it later
            defer(() => {
                setInputState(getLastInputState());
            });
        }

        props.onFocus?.(event);
    }, [beforeMaskedStateChange, getInputState, getLastInputState, inputRef, isMasked, maskUtils, props, setInputState]);

    const handleBlur = useCallback((event: ReactFocusEvent) => {
        $yield(() => {
            setFocused(false);
        });

        const currentState = getInputState();
        const previousState = getLastInputState();

        //        const currentValue = getInputState().value;
        //       const lastValue = getLastInputState().value || '';

        console.log('CurrentState=', currentState, 'previousState=', previousState);

        if (isMasked && !alwaysShowMask && maskUtils.isValueEmpty(previousState.value)) {
            let newValue = '';
            let newInputState:TextState = {
                value: newValue,
                selection: NO_SELECTION, //: { start: null, end: null },
            };

            if (beforeMaskedStateChange) {
                newInputState = beforeMaskedStateChange({
                    currentState: getInputState(),
                    nextState: newInputState,
                });
                newValue = newInputState.value || '';
            }

            setInputState(newInputState);

            if (newValue !== currentState.value && props.onChange) {
                props.onChange?.(newValue, newInputState);
            }
        }

        props.onBlur?.(event);
    }, [alwaysShowMask, beforeMaskedStateChange, getInputState, getLastInputState, isMasked, maskUtils, props, setInputState]);

    // Tiny unintentional mouse movements can break cursor
    // position on focus, so we have to restore it in that case
    //
    // https://github.com/sanniassin/react-input-mask/issues/108
    const onMouseDown = useCallback((event: ReactMouseEvent) => {
        const input = getInputElement();
        if (!input) {
            return;
        }
        const { value } = getInputState();
        const inputDocument = input.ownerDocument;

        if (!isInputFocused(input) && !maskUtils.isValueFilled(value || '')) {
            const mouseDownX = event.clientX;
            const mouseDownY = event.clientY;
            const mouseDownTime = new Date().getTime();

            const mouseUpHandler = (mouseUpEvent: MouseEvent) => {
                inputDocument.removeEventListener('mouseup', mouseUpHandler);

                if (!isInputFocused(input)) {
                    return;
                }

                const deltaX = Math.abs(mouseUpEvent.clientX - mouseDownX);
                const deltaY = Math.abs(mouseUpEvent.clientY - mouseDownY);
                const axisDelta = Math.max(deltaX, deltaY);
                const timeDelta = new Date().getTime() - mouseDownTime;

                if (
                    (axisDelta <= 10 && timeDelta <= 200) ||
                    (axisDelta <= 5 && timeDelta <= 300)
                ) {
                    const lastState = getLastInputState();
                    const newSelection = maskUtils.getDefaultSelectionForValue(
                        lastState.value || '',
                    );
                    const newState:TextState = {
                        ...lastState,
                        selection: newSelection,
                    };
                    setInputState(newState);
                }
            };

            inputDocument.addEventListener('mouseup', mouseUpHandler);
        }

        props.onMouseDown?.(event);
    }, [getInputElement, getInputState, getLastInputState, maskUtils, props, setInputState]);

    // For controlled inputs we want to provide properly formatted
    // value prop
    if (isMasked && isControlled) {
        const input = getInputElement();
        const isFocused = input && isInputFocused(input);
        let newValue =
            isFocused || alwaysShowMask || props.value
                ? maskUtils.formatValue(props.value || '')
                : props.value || '';

        if (beforeMaskedStateChange) {
            newValue = beforeMaskedStateChange({
                nextState: {
                    value: newValue,
                    selection: NO_SELECTION,
                },
            }).value;
        }

        setInputState({
            ...getLastInputState(),
            value: newValue,
        });
    }

    const lastState = getLastInputState();
    const lastSelection = lastState.selection;
    const lastValue = lastState.value;

    useLayoutEffect(() => {
        if (!isMasked) {
            return;
        }

        const input = getInputElement();
        if (!input) {
            return;
        }
        const isFocused = isInputFocused(input);
        const previousSelection = lastSelection;
        const currentState = getInputState();
        let newInputState = { ...currentState };

        // Update value for uncontrolled inputs to make sure
        // it's always in sync with mask props
        if (!isControlled) {
            const currentValue = currentState.value || '';
            const formattedValue = maskUtils.formatValue(currentValue);
            const isValueEmpty = maskUtils.isValueEmpty(formattedValue);
            const shouldFormatValue = !isValueEmpty || isFocused || alwaysShowMask;
            if (shouldFormatValue) {
                newInputState.value = formattedValue;
            } else if (isValueEmpty && !isFocused) {
                newInputState.value = '';
            }
        }

        if (isFocused && !previousIsMasked) {
            // Adjust selection if input got masked while being focused
            newInputState.selection = maskUtils.getDefaultSelectionForValue(
                newInputState.value,
            );
        } else if (isControlled && isFocused && previousSelection) {
            // Restore cursor position if value has changed outside change event
            newInputState.selection = previousSelection;
        }

        if (beforeMaskedStateChange) {
            newInputState = beforeMaskedStateChange({
                currentState,
                nextState: newInputState,
            });
        }

        setInputState(newInputState);
    });

    const refCallback = (node: HTMLInputElement|null) => {
        inputRef.current = node;

        // if a ref callback is passed to InputMask
        if (isFunction(forwardedRef)) {
            forwardedRef(node);

            return;
        }
        if (forwardedRef !== null && typeof forwardedRef === 'object') {
            forwardedRef.current = node;
        }
    };

    const isEmpty = useMemo(() => {
        const result = maskUtils.isValueEmpty(lastState.value);

        return result;
    }, [lastState]);

    const cls:ClassValue = {
        'has-focus': isFocused,
        'is-empty': isEmpty,
    };

    const inputProps:InputHTMLAttributes<HTMLInputElement> = {
        onFocus: handleFocus,
        onBlur: handleBlur,
        onKeyDown: props.onKeyDown,
        onChange: isMasked && isEditable ? onChange : undefined,
        onMouseDown: isMasked && isEditable ? onMouseDown : props.onMouseDown,
        value: (isMasked && isControlled ? lastValue : props.value) || '',
        className: classNames('&', className, cls),
    };

    return <input
        data-testId={props['data-testid']}
        {...inputProps}
        ref={refCallback}
    />;
});
